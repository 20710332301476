import React, { useRef, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  achivedAccountPan,
  unachivedAccountPan,
  beneLookUp,
} from "../../../redux/features/verifyPAN/verifyPAN";
import { toast } from "react-toastify";
import { checkUsertoken } from "../../../redux/features/login/login";
import { beneTxnViewAllAcc } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import RefreshToast from "../../../components/_utils/RefreshToast";
const BeneficiaryPanModal = ({
  close,
  id,
  name,
  type,
  setAccountType,
  message,
  note,
  panNumber,
  setIsArchived,
  setShutViewAll,
  setShowAction,
  accountType,
  setRejectTxnPopup,
  message2,
  accountId,
}) => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [isMobileView1, setIsMobileView1] = useState(window.innerWidth <= 400);
  const {
    unachivedLoadingPan,
    achivedLoadingPan,
    unachivedDataPan,
    achivedDataPan,
  } = useSelector((state) => state.verify);
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 800);
      setIsMobileView1(window.innerWidth <= 400);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        close(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [close]);

  const handlePan = async () => {
    if (type === "unarchived") {
      RefreshToast("unarchive");
      dispatch(checkUsertoken());
      dispatch(unachivedAccountPan({ id }));
      setIsArchived(false);
    } else if (type === "archived") {
      RefreshToast("archive");
      dispatch(checkUsertoken());
      dispatch(achivedAccountPan({ id }));
    }
    setAccountType({ type: "", id: "", name: "" });
    setShutViewAll(false);
  };

  useEffect(() => {
    if (!achivedLoadingPan) {
      if (achivedDataPan?.status === true) {
        dispatch(beneLookUp({ pan_number: panNumber }));
        toast.success("Account archived successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (achivedDataPan?.status === false) {
        toast.error(achivedDataPan?.errors?.base[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }, [achivedDataPan]);

  useEffect(() => {
    if (!unachivedLoadingPan) {
      if (unachivedDataPan?.status === true) {
        dispatch(beneLookUp({ pan_number: panNumber }));
        toast.success("Account unarchived successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (unachivedDataPan?.status === false) {
        toast.error(unachivedDataPan?.errors?.base[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }, [unachivedDataPan]);

  const handleConfirm = async () => {
    setIsButtonDisabled(true);
    await handlePan();
    close(false);
    setShowAction(false);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 2000);
  };

  const handleRejectTransactionChecker = async () => {
    setShowLoader(true);
    let queryParams = {
      page_no: 1,
      page_item_count: 50,
      lifecycle_status: "pending",
      beneficiary_account_id: id,
    };
    dispatch(checkUsertoken());
    const response = await dispatch(beneTxnViewAllAcc(queryParams));
    const data = response?.payload?.transactions;
    if (data?.length > 0 && response?.payload?.status && type === "archived") {
      setRejectTxnPopup(true);
      close(false);
    } else {
      handleConfirm();
    }
  };

  return (
    <>
      <div
        className="fixed w-screen top-0 left-0 h-screen z-[9998] "
        style={{ backgroundColor: "#000000", opacity: "0.8" }}
      ></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          ref={modalRef}
          className="h-fit w-[70%] flex flex-col gap-2 px-[20px] pb-[20px] pt-[12px] bg-custom-appBackLight rounded-2xl"
          style={{
            width: isMobileView1 ? "350px" : "485px",
            maxWidth: "600px",
          }}
        >
          <div className="">
            <h6 className="text-custom-orange font-semibold font-sans text-Font_20">
              {message2}
            </h6>
            <div className="flex items-center pb-[4px]"></div>
            <div className="">
              <p
                className="mb-[20px] text-Primary_white text-Font_16 font-normal font-sans"
                style={{ wordWrap: "break-word" }}
              >
                {message}
              </p>
              <p className="text-Font_16 font-normal font-sans text-Primary_grey mb-[20px]">
                Note - {note}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "6%",
                // margin: "0px 50px",
                width: "100%",
              }}
              className="mt-[20px]"
            >
              {!showLoader && (
                <Button
                  onClick={() => {
                    close(false);
                  }}
                  sx={{
                    borderColor: "none",
                    color: "var(--Primary_white)",
                    fontSize: "var(--Font_12)",
                    fontWeight: "var(--Font_normal)",
                    fontFamily: "Open Sans",
                    textTransform: "capitalize",
                    textDecoration: "underline",
                  }}
                >
                  Cancel
                </Button>
              )}
              {showLoader ? (
                <>
                  <button className="expentra-Loader-class">
                    <img src={xpentraloader} alt="Loading..." />
                  </button>
                </>
              ) : (
                <>
                  <Button
                    disabled={isButtonDisabled}
                    sx={{
                      borderRadius: "20px",
                      color: "var(--Primary_white) !important",
                      fontSize: "var(--Font_12)",
                      fontWeight: "var(--Font_semibold)",
                      fontFamily: "Open Sans",
                      textTransform: "capitalize",
                      backgroundColor: "var(--Button_green)",

                      "&:hover": {
                        backgroundColor: "var(--Button_green_hover)",
                      },
                      // marginRight: "30px",
                      width: "79px",
                      height: "32px",
                    }}
                    onClick={() => handleRejectTransactionChecker()}
                  >
                    {type === "unarchived" ? "Unarchive" : "Archive"}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BeneficiaryPanModal;
