import React, { useEffect, useState } from "react";
import "./index.css";
import "../../assets/Landing/css/landingPage.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toast_position } from "../../redux/features/Settings/Authorization";
import { Skeleton, Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setHideBreadCrumb } from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { checkUsertoken } from "../../redux/features/login/login";
import { getHolidays } from "../../redux/features/date/date";
import { beneTxnViewAllAcc } from "../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { beneViewAllAcc } from "../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import {
  ownerListViewAllAcc,
  resetpage,
  resetToggle,
  setshowsuccestoast,
} from "../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { internalTxnViewAll } from "../../redux/features/internalTransaction/internalTransactionViewAll";
import { ViewAllAccountInternal } from "../../redux/features/InternalAccount/ViewAllAccountInternal.js";
import { getOwnerAccBalance } from "../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import formatTotalBalance from "../../utils/formatTotalBalance";
import RefreshBalanceToast from "../../components/_utils/RefreshBalanceToast";
import MessagePanel from "./MessagePanel/index.jsx";
import AccountHeader from "../../assets/GeneralIcons/account_white.svg";
import Addbutton from "../../assets/AddIcons/add_button_white.svg";
import Approval from "../../assets/HomeDashboardIcons/ApprovalWithBlueCircle.svg";
import ArrowDown from "../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowRight from "../../assets/ArrowsIcons/arrow_right_white.svg";
import AlertIcon from "../../assets/GeneralIcons/alerts_white.svg";
import AnnouncementIcon from "../../assets/GeneralIcons/announcements_white.svg";
import BeneficiaryAccount from "../../assets/HomeDashboardIcons/BeneficiaryAccountWithBlueCircle.svg";
import BeneficiaryAccountCreate from "../../assets/HomeDashboardIcons/CreateAccountWithBlueCircle.svg";
import BeneficiaryTransaction from "../../assets/HomeDashboardIcons/BeneficiaryTransactionWithBlueCircle.svg";
import BeneficiaryTransactionCreate from "../../assets/HomeDashboardIcons/PayWithBlueCircle.svg";
import BalanceLoader from "../../components/_utils/BalanceLoader/index.jsx";
import Calendar from "../../assets/HomeDashboardIcons/Calender.svg";
import ChevronLeftWhite from "../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import Entity from "../../assets/EntityIcons/Entities.svg";
import Favourites from "../../assets/GeneralIcons/Favourite_filled.svg";
import Loader from "../../assets/Beneficiary Icons/inputloder.gif";
import HolidayCard from "../../components/HomeDashboard/HolidayCard/index.jsx";
import InternalTransaction from "../../assets/HomeDashboardIcons/InternalTransactionWithBlueCircle.svg";
import NextIcon from "../../assets/ArrowsIcons/arrow_right_white_circle.svg";
import NoStatistics from "../../assets/Graphics/OBJECTS.svg";
import PrevIcon from "../../assets/ArrowsIcons/arrow_left_white_circle.svg";
import RemindersIcon from "../../assets/GeneralIcons/reminders_white.svg";
import Reload from "../../assets/RefreshIcons/reload-refresh_white.svg";
import Receive from "../../assets/HomeDashboardIcons/RecieveWithBlueCircle.svg";
import StatisticsIcon from "../../assets/GeneralIcons/statistics.svg";
import TransactionHeader from "../../assets/GeneralIcons/transaction_white.svg";
import XpentraIcon from "../../assets/LogoIcons/xpentra_white_340px65px.svg";

const HomeDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refreshData = useSelector((state) => state.refreshToken.data);
  const loading = useSelector((state) => state.homeDashboard.loading);
  const services = useSelector((state) => state.refreshToken.services);
  const [accId, setAccId] = useState("");
  const [isMore, setIsMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeLabel, setActiveLabel] = useState(null);
  const [ownerAccounts, setOwnerAccounts] = useState([]);
  const [openTxnSlider, setOpenTxnSlider] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(null);
  const [prevConnectedBal, setPrevConnectedBal] = useState("");
  const [skeletonloading, setskeletonLoading] = useState(true);
  const [txnCountLoading, setTxnCountLoading] = useState(false);
  const [openMessagePanel, setOpenMessagePanel] = useState(null);
  const [ownerDataLoading, setOwnerDataLoading] = useState(false);
  const [openAccountSlider, setOpenAccountSlider] = useState(null);
  const [dashboardOwnersData, setDashboardOwnersData] = useState([]);
  const [currentConnectedBal, setCurrentConnectedBal] = useState("");
  const [refreshedConnectedId, setRefreshedConnectedId] = useState("");
  const [accountCountLoading, setAccountCountLoading] = useState(false);
  const [connectedBankingTotal, setConnectedBankingTotal] = useState("");
  const [connectedBankingOwnerAccounts, setConnectedBankingOwnerAccounts] =
    useState([]);
  const { ownerAccBalanceLoading, showSuccessToast, ownerAccBalanceError } =
    useSelector((state) => state.ownerAllVerification);

  const { beneViewAllAccData, beneViewAllAccLoading } = useSelector(
    (state) => state.beneAllVerification
  );
  const { beneTxnViewAllAccData, beneTxnViewAllAccLoading } = useSelector(
    (state) => state.beneTxnViewAccData
  );

  const { internalAccViewAllData, internalViewAllAccLoading } = useSelector(
    (state) => state?.ViewAllAccountInternal
  );

  const { internalTxnViewAllAccData, internalTxnViewAllAccLoading } =
    useSelector((state) => state?.internalTxnViewall);

  const holiday_list = useSelector((state) => state.date?.holiday_list);

  const beneficiaryAccountCount =
    beneViewAllAccData?.summary?.pending_accounts_count;

  const beneficiaryTxnCount =
    beneTxnViewAllAccData?.summary?.actionable_transaction_count;

  const internalAccountCount =
    internalAccViewAllData?.summary?.pending_accounts_count;

  const internalTxnCount =
    internalTxnViewAllAccData?.summary?.actionable_transaction_count;

  const holidays = holiday_list?.holidays || [];

  //  don't remove code this is used for alert reminder and Announcements

  const handleInfoPanel = (label) => {
    if (activeLabel === label) {
      closePanel();
    } else {
      setActiveLabel(label);
      setOpenMessagePanel(true);
    }
  };

  const closePanel = () => {
    setOpenMessagePanel(false);
    setTimeout(() => setActiveLabel(null), 100);
  };

  useEffect(() => {
    setskeletonLoading(true);

    const timer = setTimeout(() => {
      setskeletonLoading(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [connectedBankingOwnerAccounts]);

  useEffect(() => {
    async function fetchData() {
      try {
        let queryParams = {
          page_no: 1,
          page_item_count: 50,
        };

        dispatch(checkUsertoken());
        dispatch(beneTxnViewAllAcc(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {}, [beneTxnViewAllAccData]);

  useEffect(() => {
    async function fetchPendingAccountData() {
      try {
        let queryParams = {
          page_no: 1,
          page_size: 50,
          approval_status: ["pending", "approved"],
        };
        dispatch(checkUsertoken());
        dispatch(beneViewAllAcc(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchPendingAccountData();
  }, []);

  useEffect(() => {
    async function fetchPendingInternalTxnData() {
      try {
        let queryParams = {
          page_no: 1,
          page_item_count: 50,
        };
        dispatch(checkUsertoken());
        dispatch(internalTxnViewAll(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchPendingInternalTxnData();
  }, []);

  useEffect(() => {}, [internalTxnViewAllAccData]);

  useEffect(() => {
    async function fetchPendingInternalAccData() {
      try {
        let queryParams = {
          page_no: 1,
          page_size: 50,
          type: "internalAccount",
        };
        dispatch(checkUsertoken());
        dispatch(ViewAllAccountInternal(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchPendingInternalAccData();
  }, []);

  useEffect(() => {}, [internalAccViewAllData]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getHolidays());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAccountCountLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setTxnCountLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const getConnectedBankingData = async () => {
      if (currentPage === 1) {
        setOwnerDataLoading(true);
      }
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(
          ownerListViewAllAcc({
            page_no: 1,
            page_size: 50,
            connected_banking: "Y",
          })
        );
        const newData = response?.payload?.owner_accounts || [];
        setConnectedBankingOwnerAccounts((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setConnectedBankingTotal(response?.payload?.total_balance);
        setOwnerAccounts(response?.payload?.owner_accounts);
        setOwnerDataLoading(false);
        setDashboardOwnersData(response?.payload);
      } catch (error) {
        console.error(error);
        setOwnerDataLoading(false);
      }
    };
    getConnectedBankingData();
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (!ownerAccBalanceLoading) {
      setTimeout(() => {
        toast.dismiss();
        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", toast_position);
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }
  }, [ownerAccBalanceLoading]);

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  const slides = Array.from({ length: 0 }, (_, i) => `Slide ${i + 1}`);

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const AddStatistics = () => {
    toast.info("This feature is not available at the moment.", toast_position);
  };

  const handleRefresh = async (id) => {
    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getOwnerAccBalance(id));

      if (response?.payload?.status === true) {
        setRefreshedConnectedId(id);
        let updatedOwnersData = ownerAccounts?.map((item) => {
          if (
            response?.payload?.owner_accounts[0]?.owner_account_id === item?.id
          ) {
            return {
              ...item,
              owner_account_balance_last_fetched_at_in_words:
                response?.payload?.owner_accounts[0]
                  ?.owner_account_balance_last_fetched_at_in_words,
              owner_account_balance:
                response?.payload?.owner_accounts[0]?.owner_account_balance,
              owner_account_balance_last_fetched_at:
                response.payload?.owner_accounts[0]
                  ?.owner_account_balance_last_fetched_at,
            };
          }
          return item;
        });

        const updatedConnectedBankingData = connectedBankingOwnerAccounts?.map(
          (item) => {
            if (
              response?.payload?.owner_accounts[0]?.owner_account_id ===
              item?.id
            ) {
              return {
                ...item,
                owner_account_balance_last_fetched_at_in_words:
                  response?.payload?.owner_accounts[0]
                    ?.owner_account_balance_last_fetched_at_in_words,
                owner_account_balance:
                  response?.payload?.owner_accounts[0]?.owner_account_balance,
                owner_account_balance_last_fetched_at:
                  response.payload?.owner_accounts[0]
                    ?.owner_account_balance_last_fetched_at,
              };
            }
            return item;
          }
        );

        setOwnerAccounts(updatedOwnersData);
        setConnectedBankingOwnerAccounts(updatedConnectedBankingData);

        const prevBalance = connectedBankingOwnerAccounts?.find(
          (owner) => owner.id === id
        )?.owner_account_balance;

        setPrevConnectedBal(prevBalance);

        const refreshedBalance = response?.payload.owner_accounts.find(
          (owner) => owner.owner_account_id === id
        )?.owner_account_balance;

        setCurrentConnectedBal(refreshedBalance);

        // const updatedConnectedBankingTotal =
        //   Number(total_balance) -
        //   Number(prevBalance) +
        //   Number(refreshedBalance);

        // updateTotalBalance(String(updatedConnectedBankingTotal));

        if (refreshedBalance > prevBalance) {
          setIsMore(true);
        } else {
          setIsMore(false);
        }
      } else {
        setTimeout(() => {
          toast.dismiss();
          toast.error(response?.error.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  const groupByMonth = (holidays) => {
    return holidays.reduce((acc, holiday) => {
      const date = new Date(holiday.date);
      const month = date.toLocaleString("default", { month: "long" });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(holiday);
      return acc;
    }, {});
  };

  const holidaysByMonth = groupByMonth(holidays);

  return (
    <div
      style={{ height: "100dvh" }}
      className={`bg-Blue_1 w-full pt-[0.375rem] px-[0.938rem] lg:px-0 pb-5 ${
        isCalendarOpen ? "ScrollingBehavior lg:pr-1" : "overflow-y-auto"
      }`}
    >
      <div className="flex flex-col lg:mt-[4.938rem] mt-0 lg:mx-auto pb-[5.313rem] lg:pb-0 max-w-5xl w-full lg:flex-row">
        <div className="w-full lg:w-[43.25rem] flex flex-col">
          <div className="w-full h-[3.75rem] lg:h-[4.375rem] fixed lg:static md:static md:mt-[4.938rem] lg:mt-0 top-0 left-0 right-0 bg-Blue_6 flex rounded-none md:rounded-[0.938rem] lg:rounded-[0.938rem] items-center z-[4] pl-4 lg:px-0">
            <div className="lg:hidden md:hidden flex items-center">
              <img
                src={XpentraIcon}
                alt="Xpentra Logo"
                className="w-[6.188rem]"
              />
            </div>

            <div className="flex-1 sm:hidden md:hidden"></div>

            <div className="flex flex-1 sm:justify-between justify-end items-center w-full sm:gap-0">
              {[
                { icon: AlertIcon, label: "Alert", count: 0 },
                { icon: RemindersIcon, label: "Reminder", count: 0 },
                { icon: AnnouncementIcon, label: "Announcement", count: 0 },
              ].map(({ icon, label, count }, index, arr) => (
                <div
                  key={label}
                  className={`flex-1 items-center px-2 lg:px-2.5 relative group hover:bg-none`}
                >
                  <div
                    className={`flex lg:justify-between md:justify-around sm:justify-end items-center lg:hover:h-[50px] lg:hover:rounded-[5px] ${
                      count === 0
                        ? "opacity-50 cursor-not-allowed"
                        : "opacity-100 cursor-pointer hover:bg-Blue_6_hover"
                    }`}
                  >
                    <span className="text-xs text-Primary_white font-semibold flex items-center gap-2 p-2 rounded-md">
                      <div className="w-[2.063rem] flex justify-center items-center ml-auto lg:ml-0">
                        <img
                          src={icon}
                          alt={`${label} Icon`}
                          className="w-4 h-4 lg:w-6 lg:h-6"
                        />
                      </div>
                      <span className="hidden md:flex sm:flex lg:flex">
                        {count === 0 ? label : `${label}s`}
                      </span>
                    </span>
                    <span className="text-base font-semibold text-Primary_white hidden md:flex sm:flex lg:flex p-2 rounded-md">
                      {count > 0 ? count : "00"}
                    </span>

                    {index !== arr.length - 1 && (
                      <div className="border-r-[0.063rem] border-custom-appBack h-[1.875rem] sm:h-[3.125rem] absolute right-0 top-1/2 transform -translate-y-1/2"></div>
                    )}
                  </div>
                </div>
              ))}
              {/* don't remove code this is used for alert reminder and Announcements */}

              {/* <MessagePanel
                open={openMessagePanel}
                activeLabel={activeLabel}
                closePanel={closePanel}
              /> */}
            </div>
          </div>

          <div className="w-full lg:w-[43.25rem] h-[16.5rem] lg:h-[13.375rem] bg-Blue_7 lg:mt-5 md:mt-5 sm:mt-[4.625rem] mt-[4.625rem] rounded-[0.938rem] relative overflow-hidden">
            {openTxnSlider && (
              <div
                className="absolute top-0 left-0 w-10 h-9 cursor-pointer z-10 flex items-center"
                onClick={() => setOpenTxnSlider(null)}
              >
                <img
                  src={ChevronLeftWhite}
                  className="w-4 h-4 ml-3"
                  alt="Close"
                />
              </div>
            )}

            <h2
              className={`h-[2.438rem] bg-custom-appBackLight flex items-center px-5 py-2.5 text-sm font-semibold text-Primary_grey transition-all duration-500 ${
                openTxnSlider
                  ? "ml-10 rounded-tr-[0.938rem]"
                  : "ml-0 rounded-t-[0.938rem]"
              }`}
            >
              <img
                src={TransactionHeader}
                alt="transcation header"
                className="w-4 h-4 mr-2.5"
              />
              Transactions
            </h2>

            <div className="grid lg:grid-cols-3 grid-cols-1 relative gap-3 lg:gap-0 p-2.5 lg:p-0">
              {[
                {
                  img: BeneficiaryTransactionCreate,
                  title: "Pay",
                  desc: "Create an outgoing transaction",
                  type: "pay",
                  txnRequiredServices: ["CBeneficiaryTxn", "CInternalTxn"],
                },
                {
                  img: Receive,
                  title: "Receive",
                  desc: "Create an incoming transaction",
                  type: "receive",
                  txnRequiredServices: ["RCTXN"],
                },
                {
                  img: Approval,
                  title: `Approve - ${
                    txnCountLoading
                      ? "00"
                      : (beneficiaryTxnCount || 0) + (internalTxnCount || 0) ||
                        "00"
                  }`,
                  desc: "Approve the created transactions",
                  type: "approve",
                  txnRequiredServices: ["ABeneficiaryTxn", "AInternalTxn"],
                },
              ].map((item, index) => {
                const isServiceAvailable = item.txnRequiredServices.some(
                  (service) => services?.includes(service)
                );

                return (
                  <React.Fragment key={index}>
                    <div
                      className={`w-full lg:w-[14.5rem] h-[3.75rem] lg:h-[10.938rem] bg-Blue_6 flex lg:flex-col flex-row flex-shrink-0 items-center lg:justify-center text-center lg:px-0 px-4 rounded-[0.938rem] lg:rounded-none ${
                        isServiceAvailable
                          ? "opacity-100 cursor-pointer hover:bg-Blue_6_hover"
                          : "cursor-not-allowed"
                      }`}
                      onClick={() => {
                        if (isServiceAvailable) {
                          setOpenTxnSlider(item.type);
                        } else {
                          navigate("/feature_not_assigned", {
                            state: {
                              unavailableService: item.txnRequiredServices,
                            },
                          });
                        }
                      }}
                    >
                      <img
                        src={item.img}
                        alt={item.title}
                        className={`lg:mb-2 w-8 h-8 lg:w-auto lg:h-auto ${
                          !isServiceAvailable && "opacity-50"
                        }`}
                      />
                      <div
                        className={`lg:ml-0 ml-4 flex flex-col lg:items-center items-start ${
                          !isServiceAvailable && "opacity-50"
                        }`}
                      >
                        <p className="text-sm lg:text-base font-semibold text-Primary_white pb-1 flex items-center">
                          {item.title}
                        </p>
                        <div className="text-[0.625rem] lg:text-[0.625rem] text-Primary_grey leading-tight lg:leading-normal">
                          {item.desc.split(" ").slice(0, 3).join(" ")}&nbsp;
                          <br className="hidden lg:block" />
                          {item.desc.split(" ").slice(3).join(" ")}
                        </div>
                      </div>
                    </div>

                    {index < 2 && (
                      <div
                        className={`hidden lg:block absolute top-1/2 transform -translate-y-1/2 h-[7.813rem] border-r-[0.063rem] border-custom-appBack ${
                          index === 0 ? "left-1/3" : "left-2/3"
                        }`}
                      ></div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            <div
              className={`absolute bottom-0 right-0 w-full h-[13.75rem] lg:h-[10.938rem] lg:bg-Blue_6 bg-Blue_7 transition-transform duration-500 flex items-center justify-center ${
                openTxnSlider ? "translate-x-0" : "translate-x-full"
              }`}
            >
              {["pay", "receive", "approve"].includes(openTxnSlider) && (
                <div
                  className={`flex flex-wrap lg:grid lg:grid-cols-2 text-center relative gap-3 lg:gap-0 justify-center p-2.5 lg:p-0 mb-[4.875rem] lg:mb-0 ${
                    openTxnSlider ? "w-full" : ""
                  }`}
                >
                  {[
                    {
                      key: "pay",
                      items: [
                        {
                          img: BeneficiaryTransaction,
                          title: "Beneficiary",
                          desc: <>Select category and pay a beneficiary</>,
                          service: "CBeneficiaryTxn",
                          navigateTo:
                            "/transaction/beneficiary_transaction/create",
                        },
                        {
                          img: InternalTransaction,
                          title: "Internal",
                          desc: (
                            <>
                              Transfer funds from one owner account to another.
                            </>
                          ),
                          service: "CInternalTxn",
                          navigateTo:
                            "/transaction/internal_transaction/create",
                        },
                      ],
                    },
                    {
                      key: "approve",
                      items: [
                        {
                          img: BeneficiaryTransaction,
                          title: `Beneficiary - ${beneficiaryTxnCount ?? "00"}`,
                          desc: (
                            <>
                              Approve the transactions for fund transfer from
                              owner to the beneficiary.
                            </>
                          ),
                          service: "ABeneficiaryTxn",
                          navigateTo:
                            "/transaction/beneficiary_transaction/transaction_single_approval",
                        },
                        {
                          img: InternalTransaction,
                          title: `Internal - ${internalTxnCount ?? "00"}`,

                          desc: (
                            <>
                              Approve the transactions for fund transfer between
                              owner accounts.
                            </>
                          ),
                          service: "AInternalTxn",
                          navigateTo:
                            "/internal_transaction/single_approval_transaction",
                        },
                      ],
                    },
                    {
                      key: "receive",
                      items: [
                        {
                          img: BeneficiaryTransaction,
                          title: "Bene",
                          desc: "Approve the transactions for fund transfer from owner to the beneficiary.",
                          service: "RCTXN",
                          navigateTo: "/transaction/receive",
                        },
                      ],
                    },
                  ]
                    .find((item) => item.key === openTxnSlider)
                    ?.items.map((item, index) => {
                      const isServiceAvailable = services?.includes(
                        item?.service
                      );

                      return (
                        <React.Fragment key={index}>
                          <div
                            className={`w-full lg:w-[21.70rem] h-[3.75rem] lg:h-[10.938rem] bg-custom-card flex lg:flex-col flex-row flex-shrink-0 items-center lg:justify-center text-center px-4 lg:px-0 rounded-[0.938rem] lg:rounded-none ${
                              isServiceAvailable
                                ? "opacity-100 cursor-pointer hover:bg-Blue_6_hover"
                                : "opacity-50 cursor-not-allowed"
                            }`}
                            onClick={() => {
                              if (isServiceAvailable) {
                                navigate(item.navigateTo);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: { unavailableService: item.service },
                                });
                              }
                            }}
                          >
                            {item.img && (
                              <img
                                src={item.img}
                                alt={item.title}
                                className="w-8 h-8 lg:w-auto lg:h-auto"
                              />
                            )}
                            <div className="ml-4 lg:ml-0 flex flex-col lg:items-center items-start">
                              <p className="text-sm lg:text-base font-semibold text-Primary_white pb-1">
                                {item.title}
                              </p>
                              {item.desc && (
                                <p className="text-[0.625rem] lg:px-[70px] lg:text-[0.625rem] text-Primary_grey leading-tight lg:leading-normal lg:text-center text-left">
                                  {item.desc}
                                </p>
                              )}
                            </div>
                          </div>

                          {index === 0 && (
                            <div className="hidden lg:block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[7.375rem] border-r-[0.063rem] border-custom-appBack"></div>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          <div className="w-full mt-5 bg-custom-appBack h-[38.563rem] lg:h-[22rem] rounded-t-[1.125rem] rounded-b-[0.938rem]">
            <h2 className="h-[2.438rem] bg-custom-appBackLight flex items-center justify-between px-5 py-2.5 text-sm font-semibold text-Primary_grey transition-all duration-500 rounded-t-[0.938rem]">
              <span className="flex items-center">
                <img
                  src={StatisticsIcon}
                  alt="Statistics Header"
                  className="w-4 h-4 mr-2.5"
                />
                Statistics
              </span>

              {slides.length > 0 && (
                <div className="w-[2.688rem] flex items-center space-x-3">
                  <div
                    onClick={prevSlide}
                    className={`cursor-pointer w-4 h-4 ${
                      currentSlide === 0 ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    style={{
                      pointerEvents: currentSlide === 0 ? "none" : "auto",
                    }}
                  >
                    <img src={PrevIcon} alt="Previous" />
                  </div>
                  <div
                    onClick={nextSlide}
                    className={`cursor-pointer w-4 h-4 ${
                      currentSlide === slides.length - 1
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    style={{
                      pointerEvents:
                        currentSlide === slides.length - 1 ? "none" : "auto",
                    }}
                  >
                    <img src={NextIcon} alt="Next" />
                  </div>
                </div>
              )}
            </h2>

            <div className="relative w-full lg:h-[calc(22rem-2.438rem)] overflow-hidden contents lg:block">
              {slides.length > 0 ? (
                <div
                  className="flex transition-transform duration-500 ease-in-out"
                  style={{
                    transform: `translateX(-${currentSlide * 100}%)`,
                  }}
                >
                  {slides.map((slide, index) => (
                    <div
                      key={index}
                      className="w-full flex-shrink-0 flex items-center justify-center h-[19.563rem]"
                    >
                      {slide}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center lg:justify-start h-full text-center p-5">
                  <div className="flex flex-col justify-center items-center w-full">
                    <img
                      src={NoStatistics}
                      alt="No Slides"
                      className="w-[20.625rem] h-[11.188rem] mb-5"
                    />
                    <p className="text-xs text-Primary_white">
                      Click below to open the statistics page, then select ‘Add
                      to home screen.’
                      <br />
                      Added stats will appear here.
                    </p>
                    <div
                      onClick={AddStatistics}
                      className="w-[7rem] h-[1.625rem] mt-[1.063rem] flex items-center justify-center px-2.5 py- bg-Blue_8 hover:bg-Blue_8_hover text-Primary_white text-xs rounded-[0.313rem] cursor-pointer"
                    >
                      <img
                        src={Addbutton}
                        alt="Add Icon"
                        className="w-3 h-3 mr-1"
                      />
                      Add statistics
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full lg:w-[20.75rem] mt-5 lg:mt-0 lg:ml-5">
          <div className="w-full h-[7.25rem] bg-Blue_6 rounded-[0.938rem]">
            <h2 className="w-full h-[2.438rem] flex items-center bg-Blue_4 rounded-t-[0.938rem] px-5 py-2.5 text-sm font-semibold text-Primary_grey relative">
              <img src={Calendar} className="w-4 h-4 mr-2.5" alt="Calendar" />
              Upcoming Bank Holidays - {new Date().getFullYear()}
            </h2>

            <div className="relative h-[4.813rem] w-full px-5 py-[1rem] text-Primary_grey">
              <div>
                {holidays.length > 0 ? (
                  <>
                    <p className="text-xl pb-1">
                      <span className="font-semibold">
                        {new Date(holidays[0].date).getDate()}
                      </span>
                      <sup>
                        {["st", "nd", "rd"][
                          ((new Date(holidays[0].date).getDate() + 20) % 10) - 1
                        ] || "th"}
                      </sup>{" "}
                      {new Date(holidays[0].date).toLocaleString("default", {
                        month: "long",
                      })}
                    </p>
                    <p className="text-[0.625rem] font-semibold truncate w-[10.375rem] overflow-hidden">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={`${new Date(holidays[0].date)
                          .toLocaleDateString("en-US", { weekday: "long" })
                          .slice(0, 3)} - ${holidays[0].occasion}`}
                        placement="bottom-start"
                      >
                        {`${new Date(holidays[0].date)
                          .toLocaleDateString("en-US", { weekday: "long" })
                          .slice(0, 3)} - ${holidays[0].occasion}`}
                      </Tooltip>
                    </p>
                  </>
                ) : (
                  <p className="text-[0.625rem] font-semibold">
                    No upcoming holidays
                  </p>
                )}
              </div>

              <div className="absolute bottom-0 right-0">
                <div
                  onClick={() => setIsCalendarOpen(true)}
                  className="w-[7.125rem] h-9 bg-Blue_7 hover:bg-Blue_7_hover text-Primary_grey text-xs px-3 py-2 rounded-tl-[0.938rem] rounded-br-[0.938rem] flex items-center justify-center cursor-pointer"
                >
                  View more
                  <img src={ArrowDown} alt="" className="w-2.5 h-2.5 ml-1" />
                </div>
              </div>

              <div className="calenderMob">
                <div
                  className="fixed top-0 left-0 w-screen h-screen flex z-[9998] bg-custom-backdrop bg-opacity-80"
                  style={{ display: isCalendarOpen ? "block" : "none" }}
                  onClick={() => setIsCalendarOpen(false)}
                ></div>
                <div
                  className={`inset-0 flex justify-end z-[9998] mt-[-2.5rem] absolute ${
                    isCalendarOpen == true
                      ? "opened"
                      : isCalendarOpen == false
                      ? "closed"
                      : "defaultclose"
                  }`}
                >
                  <div
                    className={`bg-Blue_6 pb-5 rounded-[0.938rem] shadow-lg w-full max-w-md ${
                      isCalendarOpen ? "h-[85vh] block" : "none"
                    } relative`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h2 className="w-full flex items-center bg-Blue_4 rounded-t-[0.938rem] px-5 py-2.5 text-sm font-semibold text-Primary_grey relative z-[9999]">
                      <img
                        src={Calendar}
                        className="w-4 h-4 mr-2.5"
                        alt="Calendar"
                      />
                      Upcoming Bank Holidays - {new Date().getFullYear()}
                    </h2>
                    <div
                      className="overflow-y-auto"
                      style={{ height: "calc(83vh - 39px)" }}
                    >
                      {Object.keys(holidaysByMonth).map((month) => (
                        <div key={month}>
                          <div className="calendarHeader">{month}</div>
                          {holidaysByMonth[month].map((holiday, index) => (
                            <React.Fragment key={holiday.id}>
                              <HolidayCard
                                date={holiday.date}
                                occasion={holiday.occasion}
                              />
                              {index < holidaysByMonth[month].length - 1 && (
                                <div className="border-t border-custom-appBack ml-5 mr-4"></div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                    <div className="absolute bottom-0 right-0">
                      <div
                        onClick={() => setIsCalendarOpen(false)}
                        className="w-[7.125rem] h-9 bg-Blue_7 hover:bg-Blue_7_hover text-Primary_grey text-xs px-3 py-2 rounded-tl-[0.938rem] rounded-br-[0.938rem] flex items-center justify-center cursor-pointer"
                      >
                        View less
                        <img
                          src={ArrowDown}
                          alt=""
                          className="w-2.5 h-2.5 ml-1 rotate-180"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isCalendarOpen && (
            <div
              className="calenderWeb"
              onClick={() => setIsCalendarOpen(false)}
            >
              <div className="fixed top-0 left-0 w-screen h-screen flex z-[9998] bg-custom-backdrop bg-opacity-80"></div>
              <div className="inset-0 flex justify-end z-[9999] absolute">
                <div
                  className="bg-Blue_6 pb-5 mx-auto rounded-[0.938rem] shadow-lg w-[calc(-20px+100%)] h-[85vh] my-auto relative"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h2 className="w-full h-[2.438rem] flex items-center bg-Blue_4 rounded-t-[0.938rem] px-5 py-2.5 text-sm font-semibold text-Primary_grey">
                    <img
                      src={Calendar}
                      className="w-4 h-4 mr-2.5"
                      alt="Calendar"
                    />
                    Upcoming Bank Holidays - {new Date().getFullYear()}
                  </h2>
                  <div
                    className="overflow-y-auto"
                    style={{ height: "calc(83vh - 39px)" }}
                  >
                    {Object.keys(holidaysByMonth).map((month) => (
                      <div key={month}>
                        <div className="calendarHeader text-custom-subheading">
                          {month}
                        </div>
                        {holidaysByMonth[month].map((holiday, index) => (
                          <React.Fragment key={holiday.id}>
                            <HolidayCard
                              date={holiday.date}
                              occasion={holiday.occasion}
                            />
                            {index < holidaysByMonth[month].length - 1 && (
                              <div className="border-t border-custom-appBack ml-5 mr-4"></div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="absolute bottom-0 right-0">
                    <div
                      onClick={() => setIsCalendarOpen(false)}
                      className="w-[7.125rem] h-9 bg-Blue_7 hover:bg-Blue_7_hover text-Primary_grey text-xs px-3 py-2 rounded-tl-[0.938rem] rounded-br-[0.938rem] flex items-center justify-center cursor-pointer"
                    >
                      View less
                      <img
                        src={ArrowDown}
                        alt=""
                        className="w-2.5 h-2.5 ml-1 rotate-180"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="w-full lg:h-[10.5rem] h-[11.813rem] lg:bg-Blue_6 bg-Blue_7 mt-5 rounded-[0.938rem] relative overflow-hidden">
            {openAccountSlider && (
              <div
                className="absolute top-0 left-0 w-10 h-9 cursor-pointer z-10 flex items-center"
                onClick={() => setOpenAccountSlider(null)}
              >
                <img
                  src={ChevronLeftWhite}
                  className="w-4 h-4 ml-3"
                  alt="Close"
                />
              </div>
            )}

            <h2
              className={`h-[2.438rem] bg-custom-appBackLight flex items-center px-5 py-2.5 text-sm font-semibold text-Primary_grey transition-all duration-500 ${
                openAccountSlider
                  ? "ml-10 rounded-tr-[0.938rem]"
                  : "ml-0 rounded-t-[0.938rem]"
              }`}
            >
              <img
                src={AccountHeader}
                alt="transcation header"
                className="w-4 h-4 mr-2.5"
              />
              Accounts
            </h2>

            <div className="grid lg:grid-cols-2 grid-cols-1 relative gap-3 lg:gap-0 p-2.5 lg:p-0">
              {[
                {
                  img: BeneficiaryAccountCreate,
                  title: "Create",
                  desc: "Create a beneficiary account",
                  type: "create",
                  accountRequiredServices: ["BUAccount"],
                },
                {
                  img: Approval,
                  title: `Approve - ${
                    accountCountLoading
                      ? "00"
                      : beneficiaryAccountCount || internalAccountCount || "00"
                  }`,
                  desc: "Approve the created accounts",
                  type: "approve",
                  accountRequiredServices: ["BAAccount", "IAAccount"],
                },
              ].map((item, index) => {
                const isServiceAvailable = item.accountRequiredServices.some(
                  (service) => services?.includes(service)
                );

                return (
                  <React.Fragment key={index}>
                    <div
                      className={`w-full lg:w-[9.75rem] lg:h-[8.063rem] h-[3.75rem] bg-Blue_6 flex lg:flex-col flex-row items-center text-center px-4 lg:px-0 pt-0 lg:pt-[0.438rem] rounded-[0.938rem] lg:rounded-none mt-0 ${
                        isServiceAvailable
                          ? "opacity-100 cursor-pointer hover:bg-Blue_6_hover"
                          : "cursor-not-allowed"
                      }`}
                      onClick={() => {
                        if (isServiceAvailable) {
                          setOpenAccountSlider(item.type);
                        } else {
                          navigate("/feature_not_assigned", {
                            state: {
                              unavailableService: item.accountRequiredServices,
                            },
                          });
                        }
                      }}
                    >
                      <img
                        src={item.img}
                        alt={item.title}
                        className={`lg:mb-2 w-8 h-8 lg:w-auto lg:h-auto ${
                          !isServiceAvailable && "opacity-50"
                        }`}
                      />
                      <div
                        className={`lg:ml-0 ml-4 flex flex-col lg:items-center items-start ${
                          !isServiceAvailable && "opacity-50"
                        }`}
                      >
                        <p className="text-sm lg:text-base font-semibold text-Primary_white pb-1 flex items-center">
                          {item.title}
                        </p>
                        <div className="text-[0.625rem] lg:text-[0.625rem] text-Primary_grey leading-tight lg:leading-normal">
                          {item.desc.split(" ").slice(0, 3).join(" ")}&nbsp;
                          <br className="hidden lg:block" />
                          {item.desc.split(" ").slice(3).join(" ")}
                        </div>
                      </div>
                    </div>
                    {index === 0 && (
                      <div className="hidden lg:block absolute left-1/2 top-[50%] -translate-x-1/2 -translate-y-1/2 h-[6.50rem] border-r-[0.063rem] border-custom-appBack"></div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>

            <div
              className={`absolute bottom-0 right-0 w-full lg:h-[8.063rem] h-[9.5rem] lg:bg-Blue_6 bg-Blue_7 transition-transform duration-500 flex items-center justify-center ${
                openAccountSlider ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <div
                className={`flex flex-wrap text-center relative justify-center gap-3 p-2.5 lg:p-0 lg:mb-0 ${
                  openAccountSlider === "approve"
                    ? "lg:grid lg:grid-cols-2"
                    : "w-full"
                }`}
              >
                {[
                  {
                    key: "create",
                    items: [
                      {
                        img: BeneficiaryAccount,
                        title: `Beneficiary`,
                        desc: `Create a beneficiary account.`,
                        requiredService: "BUAccount",
                        path: "/accounts/beneficiary_account/create",
                      },
                    ],
                  },
                  {
                    key: "approve",
                    items: [
                      {
                        img: BeneficiaryAccount,
                        title: `Beneficiary - ${
                          beneficiaryAccountCount ?? "00"
                        }`,
                        desc: "Approve the accounts to initiate transactions.",
                        requiredService: "BAAccount",
                        path: "/accounts/beneficiary_account/approval_required",
                      },
                      {
                        img: InternalTransaction,
                        title: `Internal - ${internalAccountCount ?? "00"}`,
                        desc: "Approve the accounts to initiate internal transactions.",
                        requiredService: "IAAccount",
                        path: "/accounts/internal_account/approval_required",
                      },
                    ],
                  },
                ]
                  .find((item) => item.key === openAccountSlider)
                  ?.items.map((item, index, array) => {
                    const isServiceAvailable = services?.includes(
                      item.requiredService
                    );
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={`bg-Blue_6 flex lg:flex-col flex-row flex-shrink-0 items-center lg:justify-center text-center px-4 lg:px-0 rounded-[0.938rem] lg:rounded-none transition-all duration-300 ${
                            openAccountSlider === "approve"
                              ? "w-full lg:w-[9.75rem] lg:h-[8.063rem] h-[3.75rem]"
                              : "w-full lg:h-[8.063rem] h-[3.75rem]"
                          } ${
                            !isServiceAvailable
                              ? "opacity-50 cursor-not-allowed"
                              : "opacity-100 cursor-pointer hover:bg-Blue_6_hover"
                          }`}
                          onClick={() => {
                            if (isServiceAvailable) {
                              navigate(item.path);
                            } else {
                              navigate("/feature_not_assigned", {
                                state: {
                                  unavailableService: item.requiredService,
                                },
                              });
                            }
                          }}
                        >
                          {item.img && (
                            <img
                              src={item.img}
                              alt={item.title}
                              className="w-8 h-8 lg:w-auto lg:h-auto"
                            />
                          )}
                          <div className="ml-4 lg:ml-0 flex flex-col lg:items-center items-start">
                            <p className="text-sm lg:text-base font-semibold text-Primary_white pb-1">
                              {item.title}
                            </p>
                            {item.desc && (
                              <p className="text-[0.625rem] lg:px-[10px] lg:text-[0.625rem] text-Primary_grey leading-tight lg:leading-normal lg:text-center text-left">
                                {item.desc}
                              </p>
                            )}
                          </div>
                        </div>

                        {openAccountSlider === "approve" &&
                          index === 0 &&
                          array.length > 1 && (
                            <div className="hidden lg:block absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[6.50rem] border-r-[0.063rem] border-custom-appBack"></div>
                          )}
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="w-full mt-5 bg-custom-appBackLight h-[22rem] rounded-t-[1.125rem] rounded-b-[0.938rem] relative">
            <h2 className="h-[2.438rem] bg-custom-appBackLight flex items-center justify-between px-5 py-2.5 text-sm font-semibold text-Primary_grey transition-all duration-500 rounded-t-[0.938rem]">
              <span className="flex items-center capitalize truncate">
                <img
                  src={Entity}
                  alt="Entity Header"
                  className="w-4 h-4 mr-2.5"
                />
                {refreshData?.entity ? (
                  <span className="truncate">
                    <Tooltip
                      title={refreshData.entity.entity_name}
                      arrow
                      placement="bottom"
                      PopperProps={{
                        modifiers: [
                          {
                            name: "preventOverflow",
                            options: {
                              boundary: "viewport",
                            },
                          },
                        ],
                        style: {
                          maxWidth: "none",
                        },
                      }}
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                              color: "var(--Primary_white)",
                              fontSize: "14px",
                              padding: "8px",
                              borderRadius: "4px",
                            },
                            [`& .${tooltipClasses.arrow}`]: {
                              color: (theme) => theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      enterTouchDelay={0}
                    >
                      {refreshData.entity.entity_name.toLowerCase()}
                    </Tooltip>
                  </span>
                ) : (
                  "Not available"
                )}
              </span>
            </h2>

            <div className="h-[3.75rem] w-full px-5 py-[0.625rem] bg-Blue_6 text-Primary_grey">
              <p className="">
                <span className="text-Settled font-semibold text-base">
                  ₹&nbsp;
                  {connectedBankingTotal !== undefined
                    ? formatTotalBalance(connectedBankingTotal).amount
                    : "00.00"}
                  {connectedBankingTotal !== undefined &&
                    formatTotalBalance(connectedBankingTotal).prefix ==
                      "crores"}
                </span>
                <span className="text-Primary_grey">&nbsp;in&nbsp;</span>
                <span className="text-Settled font-semibold text-base">
                  {dashboardOwnersData?.total_count}&nbsp;
                </span>
                <span>
                  {dashboardOwnersData?.total_count === 0 ||
                  dashboardOwnersData?.total_count === 1
                    ? "active account"
                    : "active accounts"}
                </span>
              </p>
              <p className="text-[0.625rem]">
                Total balance&nbsp;
                {formatTotalBalance(connectedBankingTotal).prefix == "crores"
                  ? "in crores"
                  : ""}
              </p>
            </div>
            <div className="h-[2.438rem] w-full pl-[18px] py-[0.625rem] bg-Blue_5 text-Primary_grey flex items-center justify-between">
              <div className="flex items-center flex-1">
                <img
                  src={Favourites}
                  alt="Favourites"
                  className="w-4 h-4 mr-2.5"
                />
                <p className="text-sm font-semibold">Favourites</p>
              </div>

              <div className="flex-1 flex justify-end">
                <div
                  onClick={async () => {
                    dispatch(resetpage());
                    dispatch(resetToggle());
                    navigate("/accounts/owner/view_all");
                  }}
                  className="w-[6.125rem] h-10 sm:h-[2.438rem] xs:h-[2.438rem] bg-Blue_7 hover:bg-Blue_7_hover text-Primary_grey text-xs px-3 py-2 flex items-center justify-center cursor-pointer"
                >
                  View All
                  <img
                    src={ArrowRight}
                    alt="ArrowRight"
                    className="w-2.5 h-2.5 ml-1 rotate-360"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center text-Primary_grey text-xs lg:px-2.5 pl-2.5 pr-2 py-[0.938rem] space-y-3 overflow-x-hidden overflow-y-auto h-[10.5rem]">
              {skeletonloading ? (
                <div style={{ width: "100%" }}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "100%",
                      height: "47px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              ) : connectedBankingOwnerAccounts.some((owner) =>
                  owner?.tags?.includes("favourite")
                ) ? (
                connectedBankingOwnerAccounts
                  .filter((owner) => owner?.tags?.includes("favourite"))
                  .map((owner, index) => (
                    <div
                      key={index}
                      className="bg-Blue_6 rounded-[0.625rem] overflow-hidden flex items-center justify-between h-[2.938rem] lg:w-[18.25rem] w-full"
                    >
                      <div
                        onClick={() =>
                          navigate(
                            `/accounts/owner/owner_account_details/${owner?.id}`
                          )
                        }
                        className="flex flex-col flex-grow cursor-pointer h-[47px] justify-center hover:bg-Blue_6_hover pl-[0.625rem]"
                      >
                        <div className="flex justify-between">
                          <p className="text-Primary_white text-sm font-semibold">
                            <Tooltip
                              title={owner.bank_acc_no}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "preventOverflow",
                                    options: {
                                      boundary: "viewport",
                                    },
                                  },
                                ],
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                      color: "var(--Primary_white)",
                                      fontSize: "14px",
                                      padding: "8px",
                                      borderRadius: "4px",
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              enterTouchDelay={0}
                            >
                              {owner.bank_acc_no?.substring(0, 4) +
                                " •••• " +
                                owner.bank_acc_no?.substring(
                                  owner.bank_acc_no?.length - 4
                                )}
                            </Tooltip>
                          </p>
                          <p
                            className={`text-sm mr-[0.625rem] ${
                              owner?.owner_account_balance
                                ? "text-Settled"
                                : "text-Primary_white text-sm font-normal"
                            }`}
                          >
                            ₹
                            {owner?.owner_account_balance !== undefined
                              ? formatTotalBalance(owner?.owner_account_balance)
                                  .amount
                              : "Not available"}
                          </p>
                        </div>

                        <div className="flex justify-between text-[0.625rem] text-Primary_grey">
                          <p className="">Account number</p>
                          <p className="whitespace-nowrap mr-[0.625rem]">
                            {owner?.owner_account_balance_last_fetched_at_in_words
                              ? `Balance - ${owner.owner_account_balance_last_fetched_at_in_words}`
                              : "Balance"}
                          </p>
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          if (
                            services?.includes("VAccountBalance") ||
                            services?.includes("Admin")
                          ) {
                            setAccId(owner?.id);
                            handleRefresh(owner?.id);
                          } else {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: [
                                  "VAccountBalance",
                                  "Admin",
                                ],
                              },
                            });
                          }
                        }}
                        className={`bg-Blue_7 w-[1.625rem] h-[2.938rem] rounded-tr-[0.625rem] rounded-br-[0.625rem] flex items-center justify-center ${
                          services?.includes("VAccountBalance") ||
                          services?.includes("Admin")
                            ? "cursor-pointer hover:bg-Blue_7_hover"
                            : "cursor-not-allowed opacity-50"
                        }`}
                      >
                        {ownerAccBalanceLoading && accId === owner?.id ? (
                          <BalanceLoader
                            loader={ownerAccBalanceLoading}
                            type="owner-dsb"
                          />
                        ) : !ownerAccBalanceLoading &&
                          !ownerAccBalanceError &&
                          accId === owner?.id ? (
                          <BalanceLoader
                            loader={ownerAccBalanceLoading}
                            setAccId={setAccId}
                            type="owner-dsb"
                          />
                        ) : (
                          <img
                            src={Reload}
                            className="w-4 h-4"
                            alt="refresh icon"
                          />
                        )}
                      </div>
                    </div>
                  ))
              ) : (
                <div className="flex items-center justify-center h-full text-center text-xs sm:text-xs md:text-sm lg:text-xs text-Primary_white">
                  Favourite owner accounts from their detail page. <br />
                  Favourited owner accounts will appear here.
                </div>
              )}
            </div>

            <div
              onClick={() => {
                if (
                  services?.includes("VAccountBalance") ||
                  services?.includes("Admin")
                ) {
                  if (window.innerWidth < 800) {
                    toast.info(
                      "Feature coming soon for mobile!",
                      toast_position
                    );
                  } else {
                    navigate("/accounts/owner/consolidated_statement");
                  }
                } else {
                  navigate("/feature_not_assigned", {
                    state: {
                      unavailableService: ["VAccountBalance", "Admin"],
                    },
                  });
                }
              }}
              className={`h-[2.5rem] bg-Blue_7 hover:bg-Blue_7_hover text-Primary_grey lg:text-xs text-sm px-5 py-2 rounded-bl-[0.938rem] rounded-br-[0.938rem] flex items-center justify-center text-center cursor-pointer lg:mt-0 md:mt-0 sm:mt-[3px] xs:mt-[6px]  ${
                services?.includes("VAccountBalance") ||
                services?.includes("Admin")
                  ? "cursor-pointer hover:bg-Blue_7_hover"
                  : "cursor-not-allowed opacity-50"
              }`}
            >
              View account statement
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeDashboard;
