import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import cancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import ArrowLeft from "../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import search from "../../../assets/FilterSearchIcons/search_white.svg";
import cin from "../../../assets/VerificationIcons/cin_verification_blue.svg";
import singleuser from "../../../assets/UserProfileIcons/edit_users_with_white_circle.svg";
import VerificationArrow from "../../../assets/ArrowsIcons/gst_verification_arrow.svg";
import enter_ifsc from "../../../assets/Graphics/Enter in search bar, to fetch.svg";
import Loader from "../../../assets/LoaderIcons/Loader.gif";
import notFound from "../../../assets/Graphics/No transaction found.svg";
import GeoMapLeaflet from "../../../components/_utils/GeoMapLeaflet";
import UpdateIcon from "../../../assets/RefreshIcons/refresh_balance.svg";
import {
  verifyThisCIN,
  resetGetCinData,
  selectedDin,
  resetSelectedCin,
  verifyThisDIN,
  resetGetCinError,
  verifyCINGet,
} from "../../../redux/features/verifyPAN/verifyPAN";
import { checkUsertoken } from "../../../redux/features/login/login";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import { Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CINVerification = () => {
  const services = useSelector((state) => state.refreshToken.services);

  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(0);
  const AddressFormatter = ({ address }) => {
    const addressParts = address.split(",");

    const groupedParts = [];
    for (let i = 0; i < addressParts.length; i += 2) {
      groupedParts.push(addressParts.slice(i, i + 2).join(","));
    }

    return (
      <div>
        {groupedParts.map((part, index) => (
          <div key={index}>{part.trim()}</div>
        ))}
      </div>
    );
  };

  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const ordinalSuffix = getOrdinalSuffix(day);
    return `${day}${ordinalSuffix} ${month} ${year}`;
  };

  const DateConverter = ({ date }) => {
    const formattedDate = convertDate(date);
    return <div>{formattedDate}</div>;
  };
  const dispatch = useDispatch();
  const [cinNo, setCinNo] = useState("");
  const handleCin = (e) => {
    const input = e.target;
    const value = input.value;
    const newValue = value.toUpperCase();

    if (newValue.length === 0) {
      dispatch(checkUsertoken());
      dispatch(resetGetCinData());
      dispatch(resetSelectedCin());
    }
    if (newValue.length > 21) {
      return;
    }
    if (!specialRegex.test(newValue)) {
      return;
    }
    setCinNo(newValue);
    const cursorPosition = input.selectionStart;
    setTimeout(() => {
      input.focus();
      input.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  useEffect(() => {
    if (services && !services?.includes("VCIN")) {
      navigate("/feature_not_assigned");
    }

    if (selectedCinData) {
      setCinNo(selectedCinData);
    }
    return () => {
      dispatch(resetSelectedCin());
      dispatch(resetGetCinData());
    };
  }, []);

  const inputRef = useRef("");
  const { verifyCIN, verifyCinError, verifyCINisLoading, selectedCinData } =
    useSelector((state) => state.verify);

  const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
  const specialRegex = /^[a-zA-Z0-9]*$/;

  const validateCinNo = (cin) => {
    if (!cinRegex.test(cin) || cin.length !== 21) {
      dispatch(resetGetCinError());
      toast.error("Invalid CIN", toast_position);
      return false;
    }
    return true;
  };

  const handleCinClick = async (showToast = false) => {
    if (validateCinNo(cinNo)) {
      dispatch(resetGetCinData());
      dispatch(checkUsertoken());
      dispatch(resetSelectedCin());
      let response = await dispatch(verifyCINGet({ search_string: cinNo }));
      if (!response?.payload?.status) {
        await dispatch(verifyThisCIN({ search_string: cinNo }));
      }
      if (showToast) {
        toast.success("CIN updated successfully", toast_position);
      }
    }
    setActiveButton(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && cinNo.length === 21) {
      handleCinClick();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [cinNo]);

  return (
    <div className="flex flex-col pb-5">
      <div
        className="lg:hidden fixed w-full top-0 bg-custom-lightBlue h-[56px] sm:h-14 flex items-center"
        style={{ zIndex: 999 }}
      >
        <div onClick={() => navigate(-1)} className=" cursor-pointer">
          <img src={ArrowLeft} alt="Close" className="w-4 h-4 m-5" />
        </div>
        <h1 className="text-base flex-1 text-Primary_white">
          CIN Verification
        </h1>
      </div>

      <div className="w-full lg:h-28 md:h-28 sm:h-28 bg-custom-card opacity-100 flex flex-col justify-center items-center z-50 lg:mt-[70px] sm:mt-[50px] md:mt-[40px] lg:pt-12 lg:pb-2.5 mt-[75px] md:pt-[105px] md:pb-[35px]">
        <div className="w-full max-w-[64rem] sm:w-full searchInputContainer">
          <div className="md:w-[80%] sm:w-[90%] xs:w-full sm:pt-9 sm:pb-0 md:py-0 lg:py-0 flex justify-center items-center ">
            <div className="w-[100%] items-center justify-center  bg-custom-appBackLight rounded-none sm:rounded-none md:rounded-lg lg:rounded-lg pl-[22px] lg:pl-5 text-left text-Primary_white text-sm leading-[1.1875rem] font-sans outline-none lg:max-w-[876px] placeholder-Primary_grey flex relative h-12 sm:h-[49px]  lg:h-[39px]">
              <img
                alt="search"
                src={search}
                onClick={() => inputRef.current.focus()}
                className="w-[16px] h-[16px] flex items-center  justify-center cursor-pointer"
              />
              <input
                ref={inputRef}
                value={cinNo}
                onChange={handleCin}
                placeholder="Enter CIN number"
                className="w-[100%] h-12 sm:h-[49px] flex items-center justify-center  lg:h-[39px] bg-custom-appBackLight rounded-none sm:rounded-none md:rounded-lg lg:rounded-lg pl-[10px] md:pl-3 lg:pl-3 text-left text-Primary_white text-sm leading-[1.1875rem] font-sans outline-none lg:max-w-[876px] placeholder-Primary_grey"
              />
              {cinNo.length === 0 ? (
                <></>
              ) : (
                <img
                  alt="cancel"
                  src={cancel}
                  onClick={() => {
                    setCinNo("");
                    dispatch(resetGetCinData());
                    dispatch(resetSelectedCin());
                  }}
                  className="w-5 h-5 absolute right-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              )}
            </div>
          </div>

          <div className="flex justify-center mt-0 md:mt-0 md:mb-0 lg:mt-0 sm:pt-0 sm:pb-0 md:py-0 lg:py-0 rounded-none">
            <Button
              sx={{ minWidth: "128px", borderRadius: "10px" }}
              className="btnfetch w-full"
              disabled={cinNo.length !== 21}
              onClick={() => handleCinClick(false)}
              style={{
                color: cinNo.length === 21 ? "#1d3a6d" : "#F9F9F9",
                background: cinNo.length === 21 ? "#f9f9f9" : "#707070",
              }}
            >
              Fetch
            </Button>
          </div>
        </div>
      </div>

      {verifyCINisLoading ? (
        <div className="w-full h-[70vh] flex items-center justify-center">
          <img
            src={Loader}
            alt="inputLoader"
            className="w-[60px] h-[60px] max-w-[52px] max-h-[52px]"
          />
        </div>
      ) : !verifyCINisLoading && verifyCIN?.status ? (
        <>
          <div className="w-auto bg-custom-card md:pt-0 sm:pt-2">
            <div className="max-w-[1024px] mx-auto px-4 mt-[-2px] sm:mt-0">
              <div className="grid grid-cols-1 md:grid-cols-4 items-center border-t border-b border-custom-appBack py-4 lg:py-0 md:py-0">
                <div className="flex flex-col">
                  <h4 className="pr-2 truncate lowercase first-line:capitalize text-sm text-Primary_white pb-1">
                    {verifyCIN?.cin_details?.company_name}
                  </h4>
                  <h6 className="text-xs text-Primary_grey">Company name</h6>
                </div>

                <div></div>
                <div></div>

                <div className="hidden md:flex justify-end flex-col md:flex-row items-center">
                  <div
                    className="w-[102px] h-[90px] bg-Blue_7 text-Primary_white flex flex-col items-center justify-center text-center cursor-pointer hover:bg-[#5A7198]"
                    onClick={() => handleCinClick(true)}
                  >
                    <img src={UpdateIcon} alt="" className="w-6 h-6 mb-1" />
                    <span className="text-[10px] leading-tight">
                      Update Fetched
                      <br />
                      Details
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 items-center border-b border-custom-appBack py-4 lg:py-[25px] md:py-[25px]">
                <div className="border-b md:border-b-0 md:border-r border-custom-appBack flex justify-between items-center pb-4 md:pb-0">
                  <div className="flex flex-col">
                    <h4 className="pr-2 truncate lowercase first-line:capitalize text-sm text-Primary_white pb-1">
                      {verifyCIN?.cin_details?.cin_number}
                    </h4>
                    <h6 className="text-xs text-Primary_grey">CIN number</h6>
                  </div>
                </div>

                <div className="border-b md:border-b-0 md:border-r border-transparent lg:px-5 md:px-2 sm:px-0 sm:pb-0 md:pb-0 lg:pb-0 pt-4 lg:pt-0 md:pt-0 sm:pt-4">
                  <h4 className="text-sm text-Primary_white pb-1">
                    <DateConverter
                      date={verifyCIN?.cin_details?.incorporation_date}
                    />
                  </h4>
                  <h6 className="text-xs text-Primary_grey">Estd. on</h6>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 items-center py-4 lg:py-6 md:py-6">
                <div className="border-b md:border-b-0 md:border-r border-custom-appBack pr-4 pb-4 md:pb-0">
                  <h4 className="text-sm text-Primary_white pb-1">
                    {verifyCIN?.cin_details?.company_status}
                  </h4>
                  <h6 className="text-xs text-Primary_grey">Status</h6>
                </div>

                <div className="border-b md:border-b-0 md:border-r border-custom-appBack lg:px-5 md:px-2 sm:px-0 pb-4 sm:pb-4 md:pb-0 lg:pb-0 pt-4 lg:pt-0 md:pt-2 sm:pt-4">
                  <h4 className="text-sm text-Primary_white pb-1">
                    {verifyCIN?.cin_details?.company_class === "PUBC"
                      ? "Public"
                      : "Private"}
                  </h4>
                  <h6 className="text-xs text-Primary_grey">
                    Class of company
                  </h6>
                </div>

                <div className="border-b md:border-b-0 md:border-r border-transparent lg:px-5 md:px-2 sm:px-0 sm:pb-4 md:pb-0 lg:pb-0 pt-4 lg:pt-0 md:pt-2 sm:pt-4">
                  <h4 className="text-sm text-Primary_white pb-1">
                    {verifyCIN?.cin_details?.listed_status}
                  </h4>
                  <h6 className="text-xs text-Primary_grey">Listed status</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-Blue_7 block md:hidden mt-5">
            <p className="bg-custom-lightBlue h-12 text-sm font-semibold text-Primary_white pl-[15px] flex items-center">
              Quick Actions
            </p>
            <div
              className="w-full h-[96px] text-Primary_white rounded flex flex-col items-center justify-center text-center cursor-pointer"
              onClick={() => handleCinClick(true)}
            >
              <img src={UpdateIcon} alt="" className="w-8 h-8 mb-1" />
              <span className="text-xs leading-tight">
                Update Fetched
                <br />
                Details
              </span>
            </div>
          </div>

          <div className="cin_tabs">
            <div className="cin_tabs_1024">
              <div className="cin_tabs_button lg:ml-4 md:ml-3 ml-0">
                <Button
                  className={`cin_tabs_button_1 ${
                    activeButton === 0 ? "active" : ""
                  }`}
                  style={{
                    background: activeButton === 0 ? "#F9F9F9" : "#373F4B",
                    color: activeButton === 0 ? "#373F4B" : "#F9F9F9",
                    font:
                      activeButton === 0
                        ? "normal normal 600 12px/17px Open Sans"
                        : "normal normal normal 12px/17px Open Sans",
                  }}
                  onClick={() => {
                    setActiveButton(0);
                  }}
                >
                  Details
                </Button>

                <Button
                  className={`cin_tabs_button_1 ${
                    activeButton === 1 ? "active" : ""
                  }`}
                  style={{
                    background: activeButton === 1 ? "#F9F9F9" : "#373F4B",
                    color: activeButton === 1 ? "#373F4B" : "#F9F9F9",
                    font:
                      activeButton === 1
                        ? "normal normal 600 12px/17px Open Sans"
                        : "normal normal normal 12px/17px Open Sans",
                  }}
                  onClick={() => {
                    setActiveButton(1);
                  }}
                >
                  Directors
                </Button>

                <Button
                  className={`cin_tabs_button_1 ${
                    activeButton === 2 ? "active" : ""
                  }`}
                  style={{
                    background: activeButton === 2 ? "#F9F9F9" : "#373F4B",
                    color: activeButton === 2 ? "#373F4B" : "#F9F9F9",
                    font:
                      activeButton === 2
                        ? "normal normal 600 12px/17px Open Sans"
                        : "normal normal normal 12px/17px Open Sans",
                  }}
                  onClick={() => {
                    setActiveButton(2);
                  }}
                >
                  Address
                </Button>
              </div>

              {activeButton === 0 ? (
                <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row items-center md:items-start lg:items-start flex-wrap">
                  <div className="w-[90%] sm:w-[620px] md:w-[22.5rem] lg:w-[19.33rem] h-44 rounded-[20px] bg-custom-lightBlue p-5 md:mr-4 flex flex-col justify-between mx-4 mb-5">
                    <h4 className="text-Primary_grey font-semibold text-sm">
                      Contact Details
                    </h4>

                    <div>
                      <h5 className="text-Primary_white text-sm pb-1">
                        {verifyCIN?.cin_details?.phone_number === ""
                          ? "Not provided"
                          : verifyCIN?.cin_details?.phone_number}
                      </h5>
                      <h6 className="text-Primary_grey text-xs">
                        Contact number
                      </h6>
                    </div>

                    <div className="border-t border-custom-appBack"></div>

                    <div>
                      <h5
                        id="email-id-firstLetter"
                        className="text-left text-sm text-Primary_white pb-1"
                      >
                        {verifyCIN?.cin_details?.email}
                      </h5>
                      <h6 className="text-Primary_grey text-xs">Email ID</h6>
                    </div>
                  </div>

                  <div className="w-[90%] sm:w-[620px] md:w-[22.5rem] lg:w-[19.33rem] h-44 rounded-[20px] bg-custom-lightBlue p-5 md:mr-4 flex flex-col justify-between mx-4 mb-5">
                    <h4 className="text-sm text-Primary_grey font-semibold">
                      Capital Details
                    </h4>
                    <div>
                      <h5 className="text-Primary_white text-sm pb-1">
                        ₹{" "}
                        {
                          <FormatAmount
                            price={verifyCIN?.cin_details?.authorized_capital}
                          />
                        }
                      </h5>
                      <h6 className="text-Primary_grey text-xs">
                        Authorized capital
                      </h6>
                    </div>
                    <div className="border-t border-custom-appBack"></div>
                    <div>
                      <h5 className="text-Primary_white text-sm pb-1">
                        ₹{" "}
                        {
                          <FormatAmount
                            price={verifyCIN?.cin_details?.paid_up_capital}
                          />
                        }
                      </h5>
                      <h6 className="text-Primary_grey text-xs">
                        Paid up capital
                      </h6>
                    </div>
                  </div>

                  <div className="w-[90%] sm:w-[620px] md:w-[22.5rem] lg:w-[19.33rem] h-44 rounded-[20px] bg-custom-lightBlue p-5 md:mr-4 flex flex-col justify-between mx-4 mb-5">
                    <h4 className="text-sm text-Primary_grey font-semibold">
                      Annual Compliance Status
                    </h4>
                    <div>
                      <h5 className="text-Primary_white text-sm pb-1">
                        {verifyCIN?.cin_details?.last_agm_date}
                      </h5>
                      <h6 className="text-Primary_grey text-xs">
                        Last AGM date
                      </h6>
                    </div>
                    <div className="border-t border-custom-appBack"></div>
                    <div>
                      <h5 className="text-Primary_white text-sm pb-1">
                        {verifyCIN?.cin_details?.last_bs_date}
                      </h5>
                      <h6 className="text-Primary_grey text-xs">
                        Last BS date
                      </h6>
                    </div>
                  </div>
                </div>
              ) : activeButton === 1 ? (
                <div className="cin_directors_slide lg:ml-4">
                  {verifyCIN?.cin_details?.directors?.map((data, i) => {
                    return (
                      <div
                        className="cin_directors_box"
                        key={i}
                        onClick={() => {
                          dispatch(selectedDin(data?.din_number));
                          dispatch(
                            verifyThisDIN({
                              search_string: data?.din_number,
                            })
                          );
                          dispatch(resetGetCinData());
                          navigate("/settings/DIN_Verification");
                          dispatch(checkUsertoken());
                        }}
                      >
                        <div className="cin_directors_box_img">
                          <img src={singleuser} alt="user" />
                          <h4 className="lowercase first-line:capitalize font-semibold">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      textTransform: "capitalize",
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={data?.director_name}
                            >
                              {" "}
                              {data?.director_name
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </Tooltip>
                          </h4>
                        </div>
                        <div className="cin_directors_box_details">
                          <div className="cin_directors_box_details_left">
                            <h5>{data?.din_number}</h5>
                            <h6 className="text-Primary_grey text-xs">
                              DIN number
                            </h6>
                          </div>
                          <img
                            alt="right"
                            src={VerificationArrow}
                            style={{ transform: "rotate(360deg)" }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="bg-custom-lightBlue flex flex-col md:flex-row items-start justify-between rounded-[20px] p-5 gap-4 mx-4">
                  <div className="w-full md:w-[60%]">
                    <p className="text-Primary_grey text-sm font-semibold pb-2.5">
                      Corporate Address
                    </p>
                    <p className="text-Primary_white text-sm pb-1">
                      {verifyCIN?.cin_details?.company_address}
                    </p>
                    <p className="text-xs text-Primary_grey">
                      Permanent address
                    </p>
                  </div>
                  <div className="w-full md:w-[40%] flex justify-center md:justify-end">
                    <GeoMapLeaflet
                      address={verifyCIN?.cin_details?.company_address}
                      owner="ownerDetails"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : !verifyCINisLoading && verifyCinError === true ? (
        <div className="cin-noData-div flex justify-center items-center flex-col">
          <img
            src={notFound}
            alt="notfound"
            className="w-auto lg:mt-28 md:mt-16 sm:mt-8 sm:w-[25rem] sm:h-[18rem] px-5 lg:w-96 object-contain"
          />

          <p className=" Font_14 text-center font-sans max-w-[330px] sm:max-w-[350px]">
            No data found
          </p>
          <button
            className="cin-clear-params-btn"
            onClick={() => {
              setCinNo("");
              dispatch(resetGetCinData());
              dispatch(resetSelectedCin());
            }}
          >
            Clear parameters
          </button>
        </div>
      ) : (
        <div className="flex justify-center items-center flex-col">
          <img
            src={enter_ifsc}
            alt="bgDefault"
            className="w-auto h-60 mt-20 lg:mt-16 md:mt-16 sm:mt-8 sm:w-[25rem] sm:h-[18rem] px-5 lg:w-96 object-contain"
          />
          <p className="text-center text-Primary_white text-base font-sans max-w-[330px] sm:max-w-[350px]">
            Enter CIN number in the search bar, to fetch the verified corporate
            profile details.
          </p>
        </div>
      )}
    </div>
  );
};

export default CINVerification;
