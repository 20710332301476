import React from "react";
import OwnerAccViewAll from "../../../containers/Accounts/OwnerAccount/OwnerViewAll";

const OwnerAccountPopUp = ({
  setOpenOwner,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  txnPayLoad,
  setTxnPayLoad,
  BenEnableNextButton,
  flow,
  onAccountSelect,
  accountNumber,
  accountnumberstate,
  setaccountnumberstate,
  multiSelect,
  setAccountNumber,
  onClosed,
  setDefaultAccountNumber,
  accountDefaultNumber,
  popType,
  TypeAccount,
  handlePopupClose,
}) => {
  const handleButtonClick = (dataItem) => {
    onAccountSelect(
      dataItem?.name?.trim(),
      dataItem?.bank_acc_no?.trim(),
      dataItem?.ifsc_code?.trim(),
      dataItem?.id?.trim()
    );
  };
  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen z-[9998] "
        style={{
          backgroundColor: "#000000",
          opacity: "0.8",
        }}
      ></div>
      <div className="main_pop_div">
        <OwnerAccViewAll
          typepop={"popup"}
          setOpenOwner={setOpenOwner}
          setIsNeft={setIsNeft}
          setIsImps={setIsImps}
          setIsRtgs={setIsRtgs}
          txnPayLoad={txnPayLoad}
          setTxnPayLoad={setTxnPayLoad}
          BenEnableNextButton={BenEnableNextButton}
          flow={flow}
          handleButtonClick={handleButtonClick}
          owneraccountNumber={accountNumber}
          setownerAccountNumber={setAccountNumber}
          owneraccountnumberstate={accountnumberstate}
          setowneraccountnumberstate={setaccountnumberstate}
          multiSelect={multiSelect}
          onClosed={onClosed}
          setDefaultAccountNumber={setDefaultAccountNumber}
          owneraccountDefaultNumber={accountDefaultNumber}
          popType={popType}
          TypeAccount={TypeAccount}
          handlePopupClose={handlePopupClose}
        />
      </div>
    </>
  );
};

export default OwnerAccountPopUp;
