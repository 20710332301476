import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import cancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import search from "../../../assets/FilterSearchIcons/search_white.svg";
import enter_ifsc from "../../../assets/Graphics/Enter in search bar, to fetch.svg";
import loader from "../../../assets/LoaderIcons/Loader.gif";
import notFound from "../../../assets/Graphics/No transaction found.svg";
import ArrowLeft from "../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import arrowRight from "../../../assets/ArrowsIcons/arrow_right.svg";
import arrowRightWhite from "../../../assets/ArrowsIcons/arrow_left_blue_box.svg";
import pan from "../../../assets/VerificationIcons/pan_verification_blue.svg";
import refresh_balance from "../../../assets/RejectIcons/refresh_balance.svg";
import whiteLoader from "../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import { toast, Bounce } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  verifyThisPANGet,
  resetGetPanData,
  resetSelectedPan,
  resetGetPanError,
  selectedGst,
  verifyThisPANPost,
  verifyThisPAN,
} from "../../../redux/features/verifyPAN/verifyPAN";
import { useSelector, useDispatch } from "react-redux";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";
import { beneVerifyThisGST } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { Tooltip, tooltipClasses } from "@mui/material";
import formatGSTDate from "../../../utils/formatGSTDate";

const PanVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [panNo, setPanNo] = useState("");
  const [updatedPan, setUpdatePan] = useState("");
  const services = useSelector((state) => state.refreshToken.services);

  const toast_position_fectching = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      width: "100%",
      height: "49px",
      // borderRadius: "10px",
      color: "#F9F9F9",
      fontSize: "14px",
    },
  };

  const { state } = useLocation();
  const handlePan = (e) => {
    if (e.target.value.length === 0) {
      dispatch(resetGetPanData());
    }
    if (e.target.value.length > 10) {
      return;
    }
    if (!specialRegex.test(e.target.value)) {
      return;
    }
    setPanNo(e.target.value.toUpperCase());
    const cursorPosition = e.target.selectionStart;
    setTimeout(() => {
      e.target.focus();
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const inputRef = useRef("");
  const {
    getVerifyPanData,
    getVerifyPanLoading,
    getVerifyPanError,
    selectedPanData,
    verifyPANisLoading,
  } = useSelector((state) => state.verify);

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const specialRegex = /^[a-zA-Z0-9]*$/;

  const handleArbitraryPan = async (panNo) => {
    if ((!panRegex.test(panNo) && panNo.length === 10) || panNo.length < 10) {
      dispatch(resetGetPanError());
      toast.error("Invalid PAN", toast_position);
      return;
    }

    if (panNo.length === 10) {
      dispatch(checkUsertoken());
      dispatch(resetGetPanData());

      const response = await dispatch(
        verifyThisPANGet({ search_string: panNo })
      );

      if (response?.payload?.gstin_details === undefined) {
        const response = await dispatch(
          verifyThisPAN({ search_string: panNo })
        );
      }
    }
  };
  const handlePanClick = async () => {
    if ((!panRegex.test(panNo) && panNo.length === 10) || panNo.length < 10) {
      dispatch(resetGetPanError());
      toast.error("Invalid PAN", toast_position);
      return;
    }

    if (panNo.length === 10) {
      setUpdatePan(panNo);
      dispatch(checkUsertoken());
      dispatch(resetGetPanData());

      const response = await dispatch(
        verifyThisPANGet({ search_string: panNo })
      );

      if (response?.payload?.gstin_details === undefined) {
        const response = await dispatch(
          verifyThisPAN({ search_string: panNo })
        );
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && panNo.length === 10) {
      handlePanClick();
    }
  };

  useEffect(() => {
    if (services && !services?.includes("VPAN")) {
      navigate("/feature_not_assigned");
    }
    if (state !== null) {
      setPanNo(state);
      handleArbitraryPan(state);
    }
    if (selectedPanData) {
      setPanNo(selectedPanData);
    }
    return () => {
      dispatch(resetSelectedPan());
    };
  }, []);

  const handleNavigate = (gst) => {
    dispatch(checkUsertoken());
    dispatch(selectedGst(gst));
    dispatch(beneVerifyThisGST({ search_string: gst }));
    dispatch(resetGetPanData());
    navigate("/settings/GST_Verification");
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [panNo]);

  const shouldSearchBarBeHidden = state !== null || selectedPanData !== null;
  const searchDiv = (
    <div className="pan_search_fetch_div Qmd:h-[12.125rem] pb-0 Qmd:pb-6">
      <div className="flex flex-col h-[fit-content] justify-center items-center max-w-[64rem] Qmd:flex-row w-full Qmd:w-[100%]">
        <div className="pan_input_div Qmd:mr-[1.25rem] w-full Qmd:w-[100%]">
          <img
            className="top-[1.025rem] Qmd:top-[0.625rem]"
            alt="search"
            src={search}
            onClick={() => inputRef.current.focus()}
          />
          <input
            className="Qmd:w-[100%] w-full h-[49px] rounded-none Qmd:rounded-[0.625rem] Qmd:h-[2.25rem]"
            ref={inputRef}
            value={panNo}
            onChange={handlePan}
            placeholder="Enter PAN number"
            style={{
              color:
                panNo !== "" ? "var(--Primary_white)" : "var(--Primary_grey)",
            }}
          />
          {panNo.length === 0 ? (
            <></>
          ) : (
            <img
              alt="cancel"
              src={cancel}
              className="pan_cancel top-[1.025rem] Qmd:top-[0.625rem]"
              onClick={() => {
                dispatch(resetGetPanData());
                setPanNo("");
              }}
            />
          )}
        </div>
        {/* FIXME: rounded button because tailwind classes are inserted after MUI classes */}
        <Button
          className="pan_fetch_button w-full Qmd:w-[8rem] h-[50px] Qmd:h-[2.25rem] rounded-none Qmd:rounded-[0.625rem] font-semibold"
          onClick={handlePanClick}
          disabled={panNo.length !== 10}
          style={{
            color:
              panNo.length === 10 ? "var(--Blue_3)" : "var(--Primary_white)",
            background:
              panNo.length === 10
                ? "var(--Primary_white)"
                : "var(--Secondary_grey)",
          }}
        >
          Fetch
        </Button>
      </div>
    </div>
  );

  const initialGSTData = getVerifyPanData?.gstin_details?.length;

  const handleUpdateDetails = async () => {
    const up = state ?? updatedPan;

    const response = await dispatch(verifyThisPAN({ search_string: up }));

    const paragraphStyle = {
      // width: "161px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#F9F9F9",
      Opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      // justifyContent: "center",
      alignItems: "center",
    };
    const imageStyle = {
      width: "20px",
      height: "20px",
      Opacity: 1,
    };
    toast(
      <div style={divStyle} className="">
        <img src={loader} alt="" style={imageStyle} />
        <p style={paragraphStyle}>Fetching details</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          width: "100%",
          height: "49px",
          // borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );

    if (response?.payload?.status === true) {
      const res = await dispatch(verifyThisPANGet({ search_string: up }));

      setTimeout(() => {
        toast.success("Updated successfully", toast_position_fectching);
      }, 2000);
    } else {
      setTimeout(() => {
        toast.error("Failed to update", toast_position_fectching);
      }, 2000);
    }
  };

  return (
    <>
      <div
        className="mob-nav-bar z-[99] fixed w-full Qmd:hidden flex flex-col items-start justify-center  bg-custom-lightBlue text-base text-Primary_white"
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[56px]  z-50 top-0 flex flex-row justify-start items-center">
          <span>
            <img
              className="w-4 h-4 m-5"
              src={ArrowLeft}
              alt="Back button"
            ></img>
          </span>
          <span>PAN Verification</span>
        </div>
      </div>
      {/* 10 more than 56 */}
      <div
        className={`flex flex-col Qmd:mt-0 ${
          shouldSearchBarBeHidden ? "pt-[56px]" : "pt-[66px]"
        } Qmd:pt-0`}
      >
        {shouldSearchBarBeHidden ? <></> : searchDiv}
        {getVerifyPanLoading ? (
          <>
            <div
              className={`h-[${shouldSearchBarBeHidden ? "105px" : ""}]`}
            ></div>
            <div
              className={`w-full h-full flex items-center justify-center`}
              style={{
                height:
                  shouldSearchBarBeHidden || getVerifyPanLoading
                    ? "calc(-194px + 100vh)"
                    : "",
              }}
            >
              <img
                src={loader}
                alt="inputLoader"
                style={{
                  width: "60px",
                  height: "42px",
                  maxWidth: "60px",
                  maxHeight: "42px",
                }}
              />
            </div>
          </>
        ) : !getVerifyPanLoading && getVerifyPanData?.status ? (
          <>
            <div
              className={`hidden Qmd:flex cin_details_pan 
              ${shouldSearchBarBeHidden ? "pt-[6rem]" : "pt-[-1rem]"}`}
              style={{
                marginTop: shouldSearchBarBeHidden ? "0.125rem" : "",
              }}
            >
              <div className="pan_details_1024 border-t border-solid border-Blue_3">
                <div className="pan_details_block">
                  <div className="flex flex-row w-full h-full">
                    <div className="flex flex-col w-1/2 py-6 pr-16 gap-y-1">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={getVerifyPanData?.pan_details?.full_name}
                        arrow
                        // placement="bottom-start"
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                      >
                        <p className="w-[336px] text-Font_16 text-Primary_white font-sans font-normal leading-[21.79px] lowercase whitespace-normal break-words first-line:capitalize">
                          {getVerifyPanData?.pan_details?.full_name
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </p>
                      </Tooltip>
                      <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px]">
                        Full name
                      </p>
                    </div>
                    <div className="my-4 border-l border-solid border-[#1D3A6D]"></div>
                    <div className="flex flex-col w-1/2 py-6 ml-5 gap-y-1">
                      <p className="text-Font_16 text-Primary_white font-sans font-normal leading-[21.79px]">
                        {getVerifyPanData?.pan_details?.pan_number}
                      </p>
                      <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px]">
                        PAN number
                      </p>
                    </div>
                  </div>
                  <div className="update_details_block">
                    {getVerifyPanData?.status ? (
                      <>
                        <div
                          className="flex flex-col justify-center items-center gap-y-1 w-[102px] bg-Blue_7 cursor-pointer hover:bg-Blue_7_hover"
                          onClick={
                            //   () => {
                            // dispatch(verifyThisPAN({ search_string: panNo }));
                            //   }
                            handleUpdateDetails
                          }
                        >
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                            src={refresh_balance}
                            alt="cin"
                          />
                          <p className="text-Font_10 text-Primary_white text-center font-sans font-normal leading-[13.62px]">
                            Update fetched details
                          </p>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <div className="cin_img_div">
                    <img src={pan} alt="cin" />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="Qmd:hidden flex flex-col gap-1 bg-custom-card">
              <div className="flex flex-col p-4 gap-2">
                <span className="text-Font_16 text-Primary_white font-sans font-normal leading-[21.79px] lowercase whitespace-normal break-words first-line:capitalize">
                  {getVerifyPanData?.pan_details?.full_name
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </span>
                <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px]">
                  Full name
                </p>
              </div>
              <hr className="mx-4 border-custom-appBack"></hr>
              <div className="flex flex-col gap-2 p-4">
                <p className="text-Font_16 text-Primary_white font-sans font-normal leading-[21.79px]">
                  {getVerifyPanData?.pan_details?.pan_number}
                </p>
                <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px]">
                  PAN number
                </p>
              </div>
            </div>
            <div className="Qmd:hidden">
              <div className="spacing w-full h-4"></div>
              <div>
                <div className="flex flex-row h-10 bg-Blue_5 font-semibold text-Primary_white px-4 items-center">
                  <span>Quick Actions</span>
                </div>
                <hr className="border-custom-appBack"></hr>
                {getVerifyPanData?.status ? (
                  <>
                    <div
                      className="flex flex-col justify-center h-[5rem] p-4 items-center gap-y-1 bg-Blue_7 cursor-pointer hover:bg-Blue_7_hover"
                      onClick={handleUpdateDetails}
                    >
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                        }}
                        src={refresh_balance}
                        alt="cin"
                      />
                      <p className="text-Font_12 text-Primary_white text-center font-sans font-normal leading-[13.62px]">
                        Update fetched <br></br> details
                      </p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="GST_details_div">
              <div className="GST_details_1024">
                <div className="associated_GSTs_button py-4 px-4 Qmd:p-[0.625rem] flex justify-start Qmd:justify-center items-center Qmd:w-[7.125rem] h-10 Qmd:h-[1.688rem] Qmd:rounded-[1.25rem] rounded-none bg-Blue_5 Qmd:bg-Primary_white">
                  <span className="Qmd:text-xs text-Primary_white Qmd:text-Detail_chip Qmd:text-center font-sans Qmd:font-semibold leading-[16.34px]">
                    Associated GSTs
                  </span>
                </div>

                {getVerifyPanData?.gstin_details ? (
                  <div className="pan_gst_have Qmd:justify-start items-center flex-col Qmd:flex-row Qmd:items-start">
                    {getVerifyPanData?.gstin_details?.map((val) => {
                      return (
                        <div
                          className="pan_gst_have_1 Qmd:w-[20.5rem] w-[90%]"
                          onClick={() => handleNavigate(val?.gstin_no)}
                        >
                          <div className="flex flex-row justify-between w-full mb-4">
                            <span className="text-Font_14 text-Primary_grey font-sans font-semibold leading-[19.07px]">
                              GST Details
                            </span>
                            <div className="grid place-content-center w-5 h-5">
                              <img src={arrowRightWhite} alt="arrowright" />
                            </div>
                          </div>
                          <div className="flex flex-col gap-y-1 border-b border-solid border-Blue_3">
                            <p className="text-Font_14 text-Primary_white font-sans font-normal leading-[19.07px]">
                              {val?.pan_no}
                            </p>
                            <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px] mb-4">
                              PAN number
                            </p>
                          </div>
                          <div className="flex flex-col py-4 gap-y-1 border-b border-solid border-Blue_3">
                            <p className="text-Font_14 text-Primary_white font-sans font-normal leading-[19.07px] break-words">
                              {val?.gstin_no}
                            </p>
                            <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px]">
                              GST number
                            </p>
                          </div>
                          <div className="flex flex-col py-4 gap-y-1 border-b border-solid border-Blue_3">
                            <p className="text-Font_14 text-Primary_white font-sans font-normal leading-[19.07px]">
                              {val?.business_name}
                            </p>
                            <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px] break-words">
                              Company name
                            </p>
                          </div>
                          <div className="flex flex-col py-4 gap-y-1 border-b border-solid border-Blue_3">
                            <p className="text-Font_14 text-Primary_white font-sans font-normal leading-[19.07px]">
                              {formatGSTDate(val?.date_of_registration)}
                            </p>
                            <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px]">
                              Estd. on
                            </p>
                          </div>
                          <div className="flex flex-col pt-4 gap-y-1">
                            <p className="text-Font_14 text-Primary_white font-sans font-normal  leading-[19.07px]">
                              {val?.status}
                            </p>
                            <p className="text-Font_12 text-Primary_grey font-sans font-normal leading-[16.34px]">
                              Company status
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className="flex flex-col justify-center items-center mx-auto mt-20"
                    style={{
                      marginTop: shouldSearchBarBeHidden ? "10%" : "",
                    }}
                  >
                    <img
                      src={notFound}
                      style={{
                        width: "20rem",
                        height: "14.125rem",
                        marginBottom: "1.25rem",
                      }}
                      alt="notfound"
                    />
                    <span className="text-Font_16 text-Primary_white text-center font-sans font-normal leading-[21.79px]">
                      No associated GSTs found.
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : !getVerifyPanLoading && getVerifyPanError === true ? (
          <div
            className="flex flex-col justify-center items-center mx-auto"
            style={{
              marginTop: "8rem",
            }}
          >
            <img
              src={notFound}
              style={{
                width: "20rem",
                height: "14.125rem",
                marginBottom: "1.25rem",
              }}
              alt="notfound"
            />
            <p className="text-Font_14 text-Primary_white text-center font-sans font-normal leading-[21.79px]">
              No data found.
              {/* <br /> Please check the PAN number entered above. */}
            </p>
            <div
              className="pan_cta_button"
              onClick={() => {
                dispatch(resetGetPanData());
                setPanNo("");
              }}
            >
              Clear parameters
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col justify-center items-center mx-auto Qmd:w-[25.25rem]"
            style={{
              height: "18.875rem",
              marginTop: "8rem",
            }}
          >
            <img
              src={enter_ifsc}
              alt="bgDefault"
              style={{
                width: "18.75rem",
                height: "12.625rem",
                marginBottom: "1.25rem",
              }}
            />
            <p className="text-Font_16 text-Primary_white font-normal text-center">
              Enter PAN number in the search bar, to fetch the verified PAN
              details.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default PanVerification;
