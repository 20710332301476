import React, { useEffect, useRef } from "react";
import "./index.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editBeneficiary,
  resetEditBankBeneficiary,
  resetEditBeneficiary,
} from "../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import { checkUsertoken } from "../../../redux/features/login/login";
import { toast } from "react-toastify";
import { beneEditDetailBank } from "../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import AlertImg from "../../../assets/AlertIcons/alert2.svg";

const BeneEditStepper = ({
  steps,
  nextButtonEnabled,
  isClicked,
  email,
  contact,
  gstin,
  pan,
  id,
  setAccountType,
  activeStep,
  setActiveStep,
  setButtonDisabled,
  setIsClicked,
  type,
  disType,
  handleBackPan,
  handleBackContact,
  payload,
  bankID,
  handleNextBank,
  handleBackBank,
  bankNextButtonEnabled,
  selectedCategories,
  handleCategory,
  name,
  haveLinkNew,
  setIsDennied,
  setShowAction,
  setIsPending,
  setIsArchived,
  setIsApproved,
  beneLookError,
  beneBankLookError,
  account_name,
  internalAccount,
  isMobile,
  flow,
  setshowAlertCategories,
  showAlertCategories,
}) => {
  const scrollIt = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { editBeneficiaryData, editBeneficiaryLoading } = useSelector(
    (state) => state.beneCreateAccount
  );

  const sliceCategory = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );

  const { beneEditDetailBankData, beneEditDetailBankLoading } = useSelector(
    (state) => state.beneCreateAccount
  );

  const handleUpdateBank = () => {
    if (activeStep === 0) {
      handleNextBank();
    } else {
      dispatch(checkUsertoken());
      if (internalAccount === "internalAccount") {
        dispatch(
          beneEditDetailBank({
            id: bankID,
            bank_account: payload.bank_account,
            ifsc_code: payload.ifsc_code,
            name: payload.acc_holder_name,
            navigate,
          })
        );
      } else {
        dispatch(
          beneEditDetailBank({
            id: bankID,
            bank_account: payload.bank_account,
            ifsc_code: payload.ifsc_code,
            navigate,
          })
        );
      }
    }
  };

  const handleUpdateAccount = () => {
    if (disType === "gst") {
      dispatch(checkUsertoken());
      dispatch(
        editBeneficiary({
          id: id,
          email: email,
          contact_no: contact,
          name: account_name,
          navigate,
        })
      );
    } else if (disType === "category") {
      if (
        (selectedCategories?.length === sliceCategory?.length &&
          !showAlertCategories) ||
        (selectedCategories?.length === 0 && !showAlertCategories)
      ) {
        setshowAlertCategories(true);
      } else {
        dispatch(checkUsertoken());
        dispatch(
          editBeneficiary({
            id: id,
            category: selectedCategories.toString(),
            navigate,
          })
        );
        setshowAlertCategories(false);
      }
    } else {
      dispatch(checkUsertoken());
      dispatch(
        editBeneficiary({
          id: id,
          pan: pan,
          name: name,
          gstin: gstin,
          navigate,
        })
      );
      setAccountType({ type: "", id: "" });
      setIsArchived(false);
      setIsApproved(false);
      setIsDennied(false);
      setIsPending(false);
      setShowAction(false);
    }
  };

  useEffect(() => {
    if (editBeneficiaryData?.status === true) {
      if (disType === "gst") {
        navigate(
          `/accounts/${
            internalAccount === "internalAccount"
              ? "internal_account"
              : "beneficiary_account"
          }/contact_edit_success/${id}`
        );
      } else if (disType === "category") {
        navigate(`/accounts/beneficiary_account/category_edit_success/${id}`);
      } else {
        navigate(`/accounts/beneficiary_account/pan_edit_success/${id}`);
        setAccountType({ type: "", id: "" });
      }
      localStorage.setItem("haveAssociated", JSON.stringify(beneLookError));
      dispatch(resetEditBeneficiary());
    } else if (
      !editBeneficiaryLoading &&
      editBeneficiaryData?.status === false
    ) {
      toast.error(
        Object.values(editBeneficiaryData?.errors)[0][0],
        toast_position
      );
      dispatch(resetEditBeneficiary());
    }
  }, [editBeneficiaryData]);

  let newBeneId = beneEditDetailBankData?.beneficiary_accounts[0]?.id || "";

  useEffect(() => {
    if (beneEditDetailBankData?.status === true) {
      navigate(`/accounts/beneficiary_account/bank_edit_success/${newBeneId}`);
      dispatch(resetEditBankBeneficiary());
    } else if (
      !beneEditDetailBankLoading &&
      beneEditDetailBankData?.status === false
    ) {
      toast.error(
        Object.values(beneEditDetailBankData?.errors)[0][0],
        toast_position
      );
    }
  }, [beneEditDetailBankData]);

  return (
    <>
      {isMobile && flow === "EditContactDetaile" ? (
        <>
          <Button
            className="Edit-contact-update-btn"
            onClick={handleUpdateAccount}
            disabled={nextButtonEnabled || haveLinkNew}
            style={{
              backgroundColor:
                nextButtonEnabled || haveLinkNew ? "#707070" : "#69B76F",
              fontWeight:
                nextButtonEnabled || haveLinkNew ? "normal" : "semibold",
            }}
            sx={{
              borderRadius: 0,
              font: "normal normal normal 16px/22px Open Sans",
            }}
          >
            Update
          </Button>
        </>
      ) : (
        <>
          {" "}
          <div className="bene_Stepper_Main">
            {showAlertCategories && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center bg-Primary_black justify-center z-[9998] bg-opacity-80">
                <div className="w-[560px] h-auto rounded-[20px] bg-Blue_3 p-[20px] flex flex-col mx-[10px]">
                  <div className="flex flex-row gap-[15px]">
                    <img
                      src={AlertImg}
                      alt="alert-icon"
                      className="w-[30px] h-[30px]"
                    />
                    <span className="w-[215px] h-[27px] text-Toast_error text-Font_20 leading-[27.24px] font-semibold">
                      It is unsafe to proceed
                    </span>
                  </div>
                  <div className="flex flex-warp mt-[10px]">
                    <span className="text-Primary_white text-Font_14 leading-[21.79px] font-normal">
                      Assigning all categories to a single account is highly
                      unsafe. It is recommended to limit the number of
                      categories assigned to the account.
                    </span>
                  </div>
                  <div className="flex flex-row align-center justify-end gap-[30px] mt-[50px]">
                    <button
                      onClick={() => {
                        setshowAlertCategories(false);
                      }}
                    >
                      <span className="w-[38px] h-[16px] text-Primary_white text-Font_12 leading-[16.34px] font-normal">
                        <u>Cancel</u>
                      </span>
                    </button>
                    <button
                      className="w-[79px] h-[32px] rounded-[75px] py-[8px] px-[16px] bg-Button_green flex align-center"
                      onClick={handleUpdateAccount}
                    >
                      <span className="w-[48px] h-[16px] text-Primary_white text-Font_12 leading-[16.34px] font-semibold">
                        Proceed
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {steps.length === 1 ? (
              <Button
                className="create_Button_1"
                style={{
                  backgroundColor: "#506994",
                  color: "#FFFFFF",
                  opacity: "1",
                  font: "normal normal normal 16px/22px Open Sans",
                  fontWeight: "normal",
                }}
                sx={{ borderRadius: 0 }}
                onClick={() => {
                  disType === "pan"
                    ? handleBackPan()
                    : disType === "category"
                    ? handleCategory()
                    : handleBackContact();
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button
                className="create_Button_1"
                style={{
                  backgroundColor: "#506994",
                  color: "#FFFFFF",
                  opacity: "1",
                  fontWeight: activeStep === 0 ? "semibold" : "normal",
                }}
                sx={{ borderRadius: 0 }}
                onClick={handleBackBank}
              >
                {activeStep === 0 ? "Cancel" : "Previous"}
              </Button>
            )}
            <div className="stepperline_Div">
              {steps.length === 1 ? (
                <div className="stepinnerline">
                  <div
                    className={"stepDetails"}
                    style={{
                      opacity: "1",
                    }}
                  >
                    {steps[0]}
                  </div>

                  <div
                    className={"main_Line"}
                    style={{
                      width: "676px",
                      opacity: "1",
                    }}
                  ></div>
                </div>
              ) : (
                steps?.map((val, index) => {
                  return (
                    <div className="stepinnerline" key={index}>
                      <div
                        className={
                          activeStep === index && isClicked
                            ? "stepDetails growing-element"
                            : "stepDetails"
                        }
                        style={{
                          opacity: activeStep === index ? "1" : "0",
                        }}
                      >
                        {activeStep === index ? val : "Hello"}
                      </div>
                      <div
                        className={
                          activeStep === index && isClicked
                            ? "main_Line growing-element"
                            : "main_Line"
                        }
                        style={{
                          width: "300px",
                          opacity: activeStep === index ? "1" : "0.5",
                        }}
                      ></div>
                    </div>
                  );
                })
              )}
            </div>
            {steps.length === 1 ? (
              <Button
                className="create_Button_1"
                onClick={handleUpdateAccount}
                disabled={nextButtonEnabled || haveLinkNew}
                style={{
                  backgroundColor:
                    nextButtonEnabled || haveLinkNew ? "#707070" : "#69B76F",
                  fontWeight:
                    nextButtonEnabled || haveLinkNew ? "normal" : "semibold",
                }}
                sx={{
                  borderRadius: 0,
                  font: "normal normal normal 16px/22px Open Sans",
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                disabled={
                  beneEditDetailBankLoading
                    ? beneEditDetailBankLoading
                    : bankNextButtonEnabled
                }
                style={{
                  backgroundColor: !bankNextButtonEnabled
                    ? "#69B76F"
                    : "#707070",
                  color: "#FFFFFF",
                  opacity: bankNextButtonEnabled ? "0.4" : "1",
                  height: "100%",
                  textTransform: "revert",
                  fontWeight: bankNextButtonEnabled ? "normal" : "semibold",
                }}
                className="create_Button_1"
                onClick={handleUpdateBank}
                sx={{ borderRadius: 0 }}
              >
                {activeStep === 0 ? "Next" : "Create"}
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default BeneEditStepper;
