import React from "react";
import { useSelector } from "react-redux";
import Refreshbalancecard from "../../../../assets/RefreshIcons/refresh_with_blue_box.svg";
import BalanceErrorIcon from "../../../../assets/ErrorIcons/error_with_orange_circle.svg";
import Info from "../../../../assets/ViewIcons/view_statement_with_blue_box.svg";
import assignImgTag from "../../../../assets/General Icons/assign_tag_img_withoutCircle.svg";
import BalanceLoader from "../../../_utils/BalanceLoader/index";
import { Tooltip, tooltipClasses } from "@mui/material";
import { setStoredPage } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import IfscCodeMapper from "../../../_utils/IfscLogoMapper";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import AnimationUpAndDown from "../../../BeneTransaction/OwnerPopup/AnimationUpAndDown/index";
import { formatAccountNumber } from "../../../_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../../redux/features/login/login";
import selectWithGreenFilledCircle from "../../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import {
  beneViewAllAcc,
  resetViewAllAcc,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import notSelected from "../../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import yellowStar from "../.../../../../../assets/OwnerAccount/yellowStar.svg";
const OwnerListViewComponent = ({
  index,
  item,
  handleRefresh,
  isopen,
  page,
  refreshAllData,
  refreshType,
  setRefreshtype,
  setFailedRefreshId,
  failedRefreshId,
  successRefreshId,
  setSuccessRefreshId,
  currency,
  setPrev_Total_balance_state,
  total_balance,
  refreshAll,
  typepop,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  txnPayLoad,
  setTxnPayLoad,
  handleButtonClick,
  BenEnableNextButton,
  setOpenOwner,
  flow,
  handleViewStatement,
  ownerAccBalanceLoading,
  ownerAccBalanceError,
  navigate,
  dispatch,
  accId,
  setAccId,
  setStatementId,
  setAccData,
  prevId,
  setPrevId,
  prevBalance,
  setPrevBalance,
  multiSelect,
  isAssignTagsClicked,
  owneraccountnumberstate,
  setowneraccountnumberstate,
  popType,
}) => {
  const services = useSelector((state) => state.refreshToken.services);
  const isItemSelected = owneraccountnumberstate?.includes(item.id);

  return (
    <>
      <div
        style={{
          opacity:
            typepop === "popup" &&
            item?.connected_banking === "N" &&
            flow !== "internalTxn"
              ? "0.4"
              : "1",
          cursor:
            typepop === "popup" &&
            item?.connected_banking === "N" &&
            flow !== "internalTxn"
              ? "default"
              : "pointer",
          pointerEvents:
            typepop === "popup" &&
            item?.connected_banking === "N" &&
            flow !== "internalTxn"
              ? "none"
              : "auto",
        }}
        onClick={async (e) => {
          if (isAssignTagsClicked) {
            e.stopPropagation();
            if (owneraccountnumberstate?.includes(item.id)) {
              setowneraccountnumberstate(() => {
                return owneraccountnumberstate?.filter((acc) => {
                  return acc !== item.id;
                });
              });
            } else {
              setowneraccountnumberstate([...owneraccountnumberstate, item.id]);
            }
            return;
          }
          if (
            item?.pan_no === txnPayLoad?.pan &&
            item?.bank_acc_no === txnPayLoad?.bankAccountNo &&
            flow === "internalTxnSource"
          ) {
            toast.error("Same Account can't be selected", toast_position);
            return;
          }
          if (typepop === "popup") {
            if (flow === "createTxn" || flow === "internalTxnSource") {
              setTxnPayLoad({
                ...txnPayLoad,
                ownerId: item?.id,
                ownerBankIFSC: item?.ifsc_code,
                ownerBankBalance: item?.owner_account_balance,
                ownerBankLogo: item?.bank_name,
                ownerLastUpdated:
                  item?.owner_account_balance_last_fetched_at_in_words,
                ownerBankAccountNo: item?.bank_acc_no,
                ownerBankAccountName: item?.bank_account_holder_full_name,
                settleType: "neft",
              });
              setIsNeft(true);
              setIsImps(false);
              setIsRtgs(false);
              BenEnableNextButton(false);
            } else if (flow === "txnViewAll") {
              if (e && e.stopPropagation) {
                e.stopPropagation();
              }
              handleButtonClick(item);
            } else if (flow === "internalTxn") {
              dispatch(checkUsertoken());
              dispatch(resetViewAllAcc());
              dispatch(
                beneViewAllAcc({
                  page_no: 1,
                  page_size: 50,
                  bank_account_no: item?.bank_acc_no,
                  ifsc: item?.ifsc_code,
                  pan: item?.pan_no,
                  type: "internalAccount",
                })
              );
              setIsNeft(true);
              setIsImps(false);
              setIsRtgs(false);
            }
            setOpenOwner(false);
          } else {
            let selection = window.getSelection();
            if (selection.toString().length === 0) {
              if (!isopen) {
                await dispatch(setStoredPage(page));
                navigate(`/accounts/owner/owner_account_details/${item.id}`);
              }
            }
          }
        }}
        key={item.id}
        className={`bg-Blue_7 ${
          multiSelect && owneraccountnumberstate?.includes(item.id)
            ? "hover:bg-Category_selection_hover bg-Category_selection"
            : isAssignTagsClicked && owneraccountnumberstate?.includes(item.id)
            ? "hover:bg-Category_selection_hover bg-Category_selection"
            : "var(--Blue_6)"
        } list-section `}
      >
        <div className="bank-div-img">
          {
            <img
              style={{ backgroundColor: "var(--Primary_white)" }}
              src={require("../../../../assets/IfscLogo/" +
                IfscCodeMapper(item.ifsc_code))}
              alt="bank"
            />
          }
          <div
            className={`onhover ${
              isItemSelected
                ? "hover:bg-Category_selection_hover"
                : "hover:bg-Blue_1"
            }`}
          >
            <div
              style={{
                marginTop: "4px",
                display: "flex",
                width: "422px",
                height: "43px",
                // borderRadius: "0px 0px 10px 10px",
                backgroundColor: isItemSelected
                  ? "var(--Category_selection_hover)"
                  : item.increaseOrDecrease == "increased"
                  ? "var(--Increase_balance)"
                  : item.increaseOrDecrease == "decreased"
                  ? "var(--Decrease_balance)"
                  : "var(--Blue_1)",
              }}
            >
              <div style={{ width: "69px", minWidth: "69px" }}></div>
              {successRefreshId.includes(item.id) ? (
                <div
                  style={{
                    width: "178px",
                    paddingTop: "5px",
                    borderLeft: "2px solid  var(--Blue_7)",
                    borderRight: "2px solid var(--Blue_7)",
                  }}
                >
                  <p
                    style={{
                      padding: "0px 8px",
                      font: "normal normal normal 12px/17px Open Sans",
                      color: "var(--Primary_white)",
                    }}
                  >
                    {formatTotalBalance(item.difference, currency).amount}
                  </p>

                  <p
                    style={{
                      padding: "0px 8px",
                      font: "normal normal normal 10px/14px Open Sans",
                      color: "var(--Primary_grey)",
                    }}
                  >
                    Balance{" "}
                    {item.increaseOrDecrease == ""
                      ? "increased"
                      : item.increaseOrDecrease}{" "}
                    by{" "}
                    {formatTotalBalance(item.difference, currency).prefix == ""
                      ? ""
                      : "- in " +
                        formatTotalBalance(item.difference, currency).prefix}
                  </p>
                </div>
              ) : failedRefreshId.includes(item.id) ? (
                <p
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "50px",
                    position: "absolute",
                    width: "422px",
                    left: "0px",
                    backgroundColor: isItemSelected
                      ? "var(--Category_selection_hover)"
                      : "var(--Blue_1)",
                    height: "43px",
                    marginTop: "0px",
                    color: "var(--Primary_grey)",
                    borderRadius: "0px 0px 10px 10px ",
                    font: "normal normal normal 12px/17px Open Sans",
                  }}
                >
                  Currently unable to fetch balance details. Please try again
                  later.
                </p>
              ) : (
                <div
                  style={{
                    width: "178px",
                    borderLeft: "2px solid var(--Blue_7)",
                    borderRight: "2px solid var(--Blue_7)",
                    paddingTop: "5px",
                  }}
                >
                  <p
                    style={{
                      padding: "0px 8px",
                      font: "normal normal normal 12px/17px Open Sans",
                      color: "var(--Primary_white)",
                    }}
                  >
                    {item.ifsc_code}
                  </p>

                  <p
                    style={{
                      padding: "0px 8px",
                      font: "normal normal normal 10px/14px Open Sans",
                      color: "var(--Primary_grey)",
                    }}
                  >
                    IFSC code
                  </p>
                </div>
              )}
              {/* what? */}
              {!failedRefreshId.includes(item.id) && (
                <div
                  style={{
                    height: "43px",
                    width: "175px",
                    marginTop: "0px",
                    borderRadius: "0px 0px 10px 0px",
                    paddingTop: "5px",
                  }}
                >
                  <p
                    style={{
                      padding: "0px 30px 0px 0px",
                      font: "normal normal normal 12px/17px Open Sans",
                      color: "var(--Primary_white)",
                      textAlign: "right",
                    }}
                  >
                    {item.owner_account_balance_last_fetched_at_in_words
                      ? item.owner_account_balance_last_fetched_at_in_words
                      : "Not applicable"}
                  </p>

                  <p
                    style={{
                      padding: "0px 30px 0px 0px",
                      textAlign: "right",
                      font: "normal normal normal 10px/14px Open Sans",
                      color: "var(--Primary_grey)",
                    }}
                  >
                    Balance updated
                  </p>
                </div>
              )}
            </div>
            <div className="assign-tags-list-listView">
              <img src={assignImgTag} alt="assign-tag" />
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      zIndex: "2147483647 !important",
                    },
                  },
                }}
                title={item?.tags?.join(", ")}
              >
                <p>
                  {
                    item?.tags.length > 0
                      ? item?.tags?.join(", ")
                      : "No tags assigned"

                    // <div className="tags">Tag</div>
                  }
                </p>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="accounts-div text-Primary_grey">
          <p
            style={{
              padding: "0px 8px",
              font: "normal normal normal 12px/17px Open Sans",
            }}
          >
            {formatAccountNumber(item.bank_acc_no)}
          </p>

          <p
            className="w-[180px] marquee overflow-hidden whitespace-nowrap overflow-ellipsis font-normal pt-1  text-[14px] leading-19 text-Primary_grey opacity-100"
            style={{
              padding: "0px 8px",
              font: "normal normal normal 10px/14px Open Sans",
            }}
          >
            <p>{item.name}</p>
          </p>
          {item?.tags?.includes("favourite") && (
            <div className="favourite-icon-listView">
              <img src={yellowStar} alt="favourite icon" />
            </div>
          )}
        </div>
        <div>
          <div className="balance-data-div">
            {failedRefreshId.includes(item.id) ? (
              <div
                className="arrow_animate"
                style={{
                  position: "relative",
                  width: "20px",
                  height: "20px",
                  padding: "2px 0px 0px 2px",
                }}
              >
                <img
                  style={{
                    width: "19px",
                    height: "19px",
                    objectFit: "cover",
                  }}
                  src={BalanceErrorIcon}
                  alt="Error"
                />
              </div>
            ) : (
              <div className="arrow_animate">
                <AnimationUpAndDown
                  loader={ownerAccBalanceLoading}
                  currentId={item?.id}
                  previousId={
                    refreshType == false
                      ? false
                      : refreshType == "single"
                      ? prevId
                      : true
                  }
                  previousBalance={prevBalance}
                  currentBalance={item?.owner_account_balance}
                  refreshType={refreshType}
                  allPrevData={refreshAllData[index]?.prevbalance}
                />
              </div>
            )}
            <p
              style={{
                font: "normal normal normal 12px/17px Open Sans",
                textAlign: "right",
                color: "var(--Primary_white)",
              }}
            >
              {item.owner_account_balance ? (
                <>
                  {"₹ " +
                    formatTotalBalance(
                      Number(item.owner_account_balance),
                      currency
                    ).amount}
                  {item.owner_account_balance &&
                  formatTotalBalance(
                    Number(item.owner_account_balance),
                    currency
                  ).prefix == "" ? (
                    <p
                      style={{
                        font: "normal normal normal 12px/17px Open Sans",
                        color: "var(--Primary_grey)",
                      }}
                    >
                      Balance
                    </p>
                  ) : (
                    <p
                      style={{
                        font: "normal normal normal 12px/17px Open Sans",
                        color: "var(--Primary_grey)",
                      }}
                    >
                      Balance - in{" "}
                      {
                        formatTotalBalance(
                          Number(item.owner_account_balance),
                          currency
                        ).prefix
                      }
                    </p>
                  )}
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: `${flow === "internalTxn" ? "11px" : ""}`,
                      color: "var(--Primary_white)",
                    }}
                  >
                    {flow === "internalTxn"
                      ? "For Internal purpose only"
                      : "Not Available"}
                  </p>
                  <p style={{ color: "var(--Primary_grey)" }}>Balance</p>
                </>
              )}
            </p>

            {isAssignTagsClicked ? (
              <div className="assign-tag-account-select ml-[0.2rem] mt-[0.4rem]">
                <img
                  src={
                    isItemSelected ? selectWithGreenFilledCircle : notSelected
                  }
                  width={"16px"}
                  height={"16px"}
                />
              </div>
            ) : (
              <div className="balance_last_div">
                <div
                  style={{
                    cursor:
                      services?.includes("VAccountBalance") ||
                      services?.includes("Admin")
                        ? "pointer"
                        : "not-allowed",
                    opacity:
                      services?.includes("VAccountBalance") ||
                      services?.includes("Admin")
                        ? "1"
                        : "0.6",
                  }}
                  onClick={(e) => {
                    if (
                      !isopen &&
                      !refreshAll &&
                      item.connected_banking === "Y"
                    ) {
                      if (
                        services.includes("VAccountBalance") ||
                        services?.includes("Admin")
                      ) {
                        e.stopPropagation();
                        setFailedRefreshId([]);
                        setSuccessRefreshId([]);
                        setAccId(item.id);
                        setPrevId(item.id);
                        setRefreshtype("single");
                        setPrevBalance(item.owner_account_balance);
                        setPrev_Total_balance_state(total_balance);
                        handleRefresh(item.id, true);
                      } else {
                        e.stopPropagation();
                        navigate("/feature_not_assigned", {
                          state: {
                            unavailableService: "VAccountBalance,Admin",
                          },
                        });
                      }
                    } else {
                      e.stopPropagation();
                    }
                  }}
                  className="refresh-balance"
                >
                  {ownerAccBalanceLoading && accId == item.id ? (
                    <BalanceLoader loader={ownerAccBalanceLoading} />
                  ) : !ownerAccBalanceLoading &&
                    !ownerAccBalanceError &&
                    accId == item.id ? (
                    <BalanceLoader
                      loader={ownerAccBalanceLoading}
                      setAccId={setAccId}
                    />
                  ) : (
                    item.connected_banking === "Y" && (
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                                position: "absolute",
                                top: "-8px",
                                left: "-10px",
                                zIndex: "214379",
                              },
                              position: "absolute",
                              top: "-8px",
                              left: "-10px",
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Refresh"
                      >
                        <img src={Refreshbalancecard} alt="Refresh" />
                      </Tooltip>
                    )
                  )}
                </div>
                <div
                  style={{
                    cursor:
                      services?.includes("VAccountBalance") ||
                      services?.includes("Admin")
                        ? "pointer"
                        : "not-allowed",
                    opacity:
                      services?.includes("VAccountBalance") ||
                      services?.includes("Admin")
                        ? "1"
                        : "0.6",
                  }}
                  onClick={(e) => {
                    if (
                      services?.includes("VAccountBalance") ||
                      services?.includes("Admin")
                    ) {
                      if (!isopen && item.connected_banking === "Y") {
                        e.stopPropagation();
                        setAccData(item);
                        setStatementId(item.id);
                        handleViewStatement();
                      }
                    } else {
                      e.stopPropagation();
                      navigate("/feature_not_assigned", {
                        state: {
                          unavailableService: "VAccountBalance,Admin",
                        },
                      });
                    }
                  }}
                  className="info-div"
                >
                  {item.connected_banking === "Y" && (
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                              position: "absolute",
                              top: "-8px",
                              left: "-10px",
                              zIndex: "214379",
                            },
                            position: "absolute",
                            top: "-8px",
                            zIndex: "2147483647 !important",
                            left: "-10px",
                          },
                        },
                      }}
                      title="View statement"
                    >
                      <img src={Info} alt="Refresh" />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerListViewComponent;
