import React, { useEffect, useRef, useState } from "react";
import "./RejectingTransactionPopup.css";
import xpentraloader from "../../../../../assets/Beneficiary Icons/lodemore.gif";
import dataLoading from "../../../../../assets/Beneficiary Icons/inputloder.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import OwnerTransactionViewAllCard from "../../../../../components/Accounts/OwnerAccounts/OwnerTransactionViewAllCard";
import {
  beneTxnMultiDeniedApproval,
  beneTxnViewAllAcc,
} from "../../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";

import { checkUsertoken } from "../../../../../redux/features/login/login";
import BeneViewAllCard from "../../BeneViewAllCard";
import PendingTransactionAllCards from "../PendingTransactionAllCards/PendingTransactionAllCards.jsx";
import { toast } from "react-toastify";
import { IconButton, Tooltip, tooltipClasses } from "@mui/material";
import { Close } from "@mui/icons-material";
import RefreshToast from "../../../../../components/_utils/RefreshToast/index.jsx";
import { useNavigate } from "react-router-dom";
import { internalTxnViewAll } from "../../../../../redux/features/internalTransaction/internalTransactionViewAll.js";
import { internalTxnMultiRejectApproval } from "../../../../../redux/features/internalTransaction/internalTransactionSingleApproval.js";
import closeIcon from "../../../../../assets/General Icons/close.svg";
import BeneTxnViewall from "../../../../BeneficiaryTransactions/BeneTxnViewall/index.jsx";
import InternalTransactionViewAll from "../../../../InternalTransactionMain/InternalTransactionViewAll/index.jsx";

const RejectingTransactionPopup = (props) => {
  const [loading, setLoading] = useState(true);
  const [pendingTxn, setPendingTxn] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [showCancleBtn, setShowCancleBtn] = useState(true);
  const services = useSelector((state) => state.refreshToken.services);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );

  const {
    beneTxnViewAllAccLoading,
    beneTxnViewAllAccData,
    beneTxnViewAllAccError,
  } = useSelector((state) => state?.beneTxnViewAccData);

  const { internalTxnViewAllAccData, internalTxnViewAllAccLoading } =
    useSelector((state) => state?.internalTxnViewall);

  const { viewAccountDetailData, viewAccountDetailError } = useSelector(
    (state) => state.verify
  );

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleCloseRejectingPopup = () => {
    props.setShowRejectAllTxnReson(true);
    props.setRejectTxnPopup(false);
    if (props?.type != "ArchiveAccount") {
      props.setRejectTxnPopup(false);
    }
    setShowLoader(true);

    if (props.flow === "edit") {
      props.setIsDennied(false);
      props.setIsPending(false);
      props.setIsApproved(false);
      props.setIsArchived(false);
      props.setShutViewAll(false);
    }
  };

  const handleRejectAllTransaction = async () => {
    setShowCancleBtn(false);
    setShowLoader(true);
    let AccountIdData = props?.declineReason;
    let id = [];
    if (
      props?.type === "internalAccount" ||
      props?.type === "decline internalAccount details" ||
      props?.type === "decline_internal_account_require_approval_flow"
    ) {
      id =
        !internalTxnViewAllAccLoading &&
        internalTxnViewAllAccData?.transactions?.length > 0
          ? internalTxnViewAllAccData?.transactions.map((data) => {
              return data?.txn_id;
            })
          : [];
    } else {
      id =
        !beneTxnViewAllAccLoading &&
        beneTxnViewAllAccData?.transactions?.length > 0
          ? beneTxnViewAllAccData?.transactions.map((data) => {
              return data?.txn_id;
            })
          : [];
    }

    dispatch(checkUsertoken());
    RefreshToast();

    let data = [];
    if (
      props?.type === "internalAccount" ||
      props?.type === "decline internalAccount details"
    ) {
      data = await dispatch(
        internalTxnMultiRejectApproval({ id, AccountIdData })
      );
    } else {
      data = await dispatch(beneTxnMultiDeniedApproval({ id, AccountIdData }));
    }

    if (data?.payload?.errors?.user_unauthorized?.length > 0) {
      if (
        props?.type === "internalAccount" ||
        props?.type === "Internal_AccountApproval" ||
        props?.type === "Internal_Account_Detail"
      ) {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "AInternalTxn",
          },
        });
        return;
      } else {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "ABeneficiaryTxn",
          },
        });
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 2000));

    if (data?.payload?.status === false) {
      const error_msg = data?.payload?.errors?.user_unauthorized
        ? data?.payload?.errors?.user_unauthorized[0]
        : Array.isArray(data?.payload?.errors?.base) &&
          Array.isArray(data?.payload?.errors?.base[0]?.errors)
        ? data.payload.errors.base[0].errors[0]
        : data?.payload?.errors?.base[0] || "Unknown error occurred";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setShowCancleBtn(true);
      setShowLoader(false);
    } else if (data?.payload?.status) {
      if (
        props?.type === "internalAccount" ||
        props?.type === "Internal_AccountApproval" ||
        props?.type === "Internal_Account_Detail"
      ) {
        toast.success("Transaction Rejected successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          props.handleInternalAccountreject(
            props.accountId,
            props.declineReason
          );
        }, 500);
      } else {
        toast.success("Transaction Rejected successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          props.handleConfirm(props.accountId, props.declineReason);
        }, 500);
      }
    } else if (data?.payload == undefined) {
      toast.error("An error occured while taking action on this account", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        props.setRejectTxnPopup(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (!showLoader) {
          props?.type == "ArchiveAccount"
            ? handleCloseRejectingPopup()
            : handleRejectAllTransaction();
        }
      }

      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showLoader]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (currentPage === 1) {
          setLoading(true);
        }

        dispatch(checkUsertoken());
        let response = [];
        if (
          props?.type === "internalAccount" ||
          props?.type === "Internal_AccountApproval" ||
          props?.type === "Internal_Account_Detail"
        ) {
          let queryParams = {
            page_no: 1,
            page_item_count: 50,
            lifecycle_status: "pending",
            internal_account_id: props?.accountId,
          };
          response = await dispatch(internalTxnViewAll(queryParams));
        } else {
          let queryParams = {
            page_no: 1,
            page_item_count: 50,
            lifecycle_status: "pending",
            beneficiary_account_id: props?.accountId,
          };
          response = await dispatch(beneTxnViewAllAcc(queryParams));
        }

        const newData =
          props?.type === "internalAccount" ||
          props?.type === "Internal_AccountApproval" ||
          props?.type === "Internal_Account_Detail"
            ? internalTxnViewAllAccData?.transactions
            : response?.payload?.transactions || [];
        setPendingTxn((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchData();
  }, [dispatch, currentPage]);

  return (
    <>
      <div className="RejectAllTxn_pop_div flex flex-col overflow-hidden rounded-[20px] z-[99999] bg-[#1D3A6D]">
        <div className="flex-1 w-full overflow-hidden">
          {props?.type === "ArchiveAccount" ||
          props?.type === "decline_account_require_approval_flow" ? (
            <>
              <BeneTxnViewall
                bene_account_id={props?.accountId}
                typepop={"RejectAllTransactionPopup"}
                flowType={props?.type}
                closeRejectAllTxn={props?.handleClosePopup}
                bene_account_name={props?.accountName}
                handleRejectAllTransaction={
                  props?.type === "decline_account_require_approval_flow"
                    ? handleRejectAllTransaction
                    : handleCloseRejectingPopup
                }
              />
            </>
          ) : props?.type === "decline account details" &&
            window.location.pathname.includes("beneficiary") ? (
            <>
              <BeneTxnViewall
                bene_account_id={props?.accountId}
                typepop={"RejectAllTransactionPopup"}
                flowType={props?.type}
                closeRejectAllTxn={props?.handleClosePopup}
                bene_account_name={
                  viewAccountDetailData?.beneficiary_accounts[0]?.name
                }
                handleRejectAllTransaction={handleRejectAllTransaction}
              />
            </>
          ) : props?.type === "internalAccount" ||
            props?.type === "decline_internal_account_require_approval_flow" ? (
            <>
              <InternalTransactionViewAll
                typepop={"RejectAllTransactionPopup"}
                internal_acc_id={props?.accountId}
                bank_acc_no={props?.accountNumber}
                closeRejectAllTxn={props?.handleClosePopup}
                handleRejectAllTransaction={
                  props?.type ===
                  "decline_internal_account_require_approval_flow"
                    ? handleRejectAllTransaction
                    : handleCloseRejectingPopup
                }
              />
            </>
          ) : props?.type === "decline internalAccount details" ||
            (props?.type === "decline account details" &&
              window.location.pathname.includes("internal")) ? (
            <>
              <InternalTransactionViewAll
                typepop={"RejectAllTransactionPopup"}
                internal_acc_id={props?.accountId}
                bank_acc_no={props?.accountNumber}
                closeRejectAllTxn={props?.handleClosePopup}
                handleRejectAllTransaction={handleRejectAllTransaction}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default RejectingTransactionPopup;
