import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { Box, Button, tooltipClasses, Tooltip } from "@mui/material";
import arrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import CheckIcon from "../../../../assets/CheckmarksIcons/approve_white.svg";
import filterIcon from "../../../../assets/FilterSearchIcons/filter.svg";
import calendarIcon_blue from "../../../../../src/assets/CalendarIcons/Calender_blue.svg";
import AdditionalDetailsIcon_blue from "../../../../../src/assets/GeneralIcons/additional_fields_blue.svg";
import selectWithWhiteFilledCircle from "../../../../../src/assets/CheckmarksIcons/select_all_with_white_filled_circle.svg";
import selectWithGreenFilledCircle from "../../../../../src/assets/CheckmarksIcons/selected_with_green_circle.svg";
import selectAccount from "../../../../../src/assets/Graphics/Account selection.svg";
import ArrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowUp from "../../../../assets/ArrowsIcons/arrow_up_white.svg";
import ArrowDown8 from "../../../../assets/ArrowsIcons/arrow_down_blue.svg";
import ArrowDown5 from "../../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import ArrowUp4 from "../../../../assets/ArrowsIcons/arrow_up_blue.svg";
import Close from "../../../../assets/CloseIcons/close.svg";
import FilterBlue from "../../../../assets/FilterSearchIcons/filter_blue.svg";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import {
  downloadAllOwnerStatement,
  getAllOwnerAccStatement,
  ownerListViewAllAcc,
  resetStatementData,
  setOwnerAllAccStatementLoading,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import noaccount from "../../../../assets/Graphics/No transaction found.svg";
import DatePickerComponent from "../../../../components/_utils/Calendar";
import { useLocation, useNavigate } from "react-router-dom";
import SelectBank from "../../../../components/_utils/SelectBank";
import clearFilterImg from "../../../../assets/CloseIcons/close_with_blue_circle.svg";
import arrowforward from "../../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import { useDispatch, useSelector } from "react-redux";
import TypeDropdown from "../../../../components/_utils/typeDropdown/typedropdown";
import download_icon from "../../../../assets/GeneralIcons/download_statement.svg";
import PaginationComponent from "../../../../components/_utils/PaginationComponent";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import OwnerAccountPopUp from "../../../../components/BeneTransaction/OwnerAccountPopUp";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";
import OwnerDetailsPage from "../OwnerDetailsPage";
import Loader from "../../../../components/Loader/Index";

const OwnerAllStatement = (props) => {
  const amtDivRef = useRef(null);
  const dateRef = useRef(null);
  const scrollTopRef = useRef(null);
  const dropdownOptions = [50, 100];
  const [selectStatement, setSelectStatement] = useState(false);
  const [showOwnerPopup, setShowOwnerPopup] = useState(false);
  const [showOwnerDetailPopup, setShowOwnerDetailPopup] = useState(false);
  const [ownerDetailId, setOwnerDetailID] = useState("");
  const [showAmtFilterDiv, setShowAmtFilterDiv] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAmtFilterApplied, setIsAmtFilterApplied] = useState(false);
  const [isDateConfirm, setIsDateConfirm] = useState(false);
  const { ownerAllAccStatementData, ownerAllAccStatementLoading } = useSelector(
    (state) => state.ownerAllVerification
  );
  const yearDropdownRef = useRef(null);
  const scrollRef = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const calenderRef = useRef(null);
  const calenderButtonRef = useRef(null);
  const additionalDetailsRef = useRef(null);
  const additionalDetailsBtnRef = useRef(null);
  const filterButtonRef = useRef(null);
  const selectAccountRef = useRef(null);
  const location = useLocation();
  const [bankOptions, setBankOptions] = useState([]);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const filterapplyButtonRef = useRef(null);
  const addtionalapplyButtonRef = useRef(null);
  const calenderapplyButtonRef = useRef(null);
  const [showSelectBank, setShowSelectBank] = useState(false);
  const [selectedtBankState, setSelectedtBankState] = useState("");
  const [selectedtBank, setSelectedtBank] = useState("");
  const [typeOpen, setTypeOpen] = useState(false);
  const [statementType, setStatementType] = useState("");
  const [statementTypeState, setStatementTypeState] = useState("");
  const [amountLimitState, setAmountLimitState] = useState({
    min: "",
    max: "",
  });
  const [amountLimit, setAmountLimit] = useState({
    min: "",
    max: "",
  });

  const [accountnumberstate, setaccountnumberstate] = useState(
    props?.popType == "ownerStatement" ? [props?.ownerDetailId] : []
  );
  const [accountNumber, setAccountNumber] = useState(
    props?.popType == "ownerStatement" ? [props?.ownerDetailId] : []
  );
  const [accountDefaultNumber, setDefaultAccountNumber] = useState(
    props?.popType == "ownerStatement" ? [props?.ownerDetailId] : []
  );
  const [selectedType, setSelectedType] = useState("XLSX");
  const fileTypeRef = useRef(null);
  const [startDateState, setStartDateState] = useState(
    new Date(new Date().setDate(1))
  );
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(1)));
  const [endDateState, setEndDateState] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const errmsg = {
    400: "Bad Request Your request is invalid",
    401: "Sorry! you are not authorized to perform the action",
    403: "Forbidden The record requested is hidden for administrators only",
    404: "Not Found The specified record could not be found",
    405: "Method Not Allowed You tried to access a record with an invalid method",
    406: "Not Acceptable You requested a format that isn't json",
    410: "The record requested has been removed from our servers",
    422: "Unprocessable Entity Your request is not as per is specification or cannot be processed",
    429: "Too Many Requests You're requesting too many records! Slow down!",
    500: "Internal Server Error",
    503: "Service Unavailable We're temporarily offline for maintenance, Please try again later",
  };

  const order = [
    "bank/bank name",
    "bankref",
    "accno",
    "narration",
    "transactiondateandtime",
    "type",
    "valuedate",
    "amount",
    "closingbalance",
    "name",
    "debitamount",
    "creditamount",
  ];
  const [additionalDetailCol, setAdditionalDetailsCol] = useState([
    "bank/bank name",
    "bankref",
    "accno",
    "narration",
    "transactiondateandtime",
    "type",
    "valuedate",
    "amount",
  ]);
  const [defaultAdditionalDetailCol, setDefaultAdditionalDetailsCol] = useState(
    [
      "bank/bank name",
      "bankref",
      "accno",
      "narration",
      "transactiondateandtime",
      "type",
      "valuedate",
      "amount",
    ]
  );
  const [additionalDetailColState, setAdditionalDetailsColState] = useState([
    "bank/bank name",
    "bankref",
    "accno",
    "narration",
    "transactiondateandtime",
    "type",
    "valuedate",
    "amount",
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(checkUsertoken());
        let response = await dispatch(ownerListViewAllAcc({}));
        setBankOptions(response?.payload?.summary?.available_banks || []);
      } catch (error) {}
    };

    fetchData();

    return () => {
      dispatch(resetStatementData());
    };
  }, []);

  useEffect(() => {
    const handleFetch = () => {
      dispatch(checkUsertoken());
      dispatch(
        getAllOwnerAccStatement({
          id: accountNumber,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          page_no: currentPage,
          credit_debit: statementType,
          start_amount: isAmtFilterApplied ? amountLimit.min : "",
          end_amount: isAmtFilterApplied ? amountLimit.max : "",
          page_size: pageSize,
          bank_name: selectedtBank.length > 0 ? selectedtBank : undefined,
        })
      );
    };
    if (accountNumber.length > 0) {
      handleFetch();
    } else {
      dispatch(setOwnerAllAccStatementLoading(false));
    }
  }, [
    currentPage,
    statementType,
    pageSize,
    accountNumber,
    selectedtBank,
    amountLimit,
    startDate,
    endDate,
  ]);

  const handleApplyEnable = () => {
    if (
      accountDefaultNumber.length > 0 &&
      accountnumberstate.length > 0 &&
      accountNumber.length == 0
    ) {
      return true;
    }
    if (
      accountDefaultNumber.length === accountnumberstate.length &&
      accountDefaultNumber.every((ac) => {
        return accountnumberstate.includes(ac);
      }) &&
      accountDefaultNumber.length === accountNumber.length &&
      accountDefaultNumber.every((ac) => {
        return accountNumber.includes(ac);
      }) &&
      selectedtBank.length === 0 &&
      amountLimit.min.length === 0 &&
      amountLimit.max.length === 0 &&
      statementType.length === 0 &&
      selectedtBankState.length === 0 &&
      !isAmtFilterApplied &&
      statementTypeState.length === 0
    ) {
      return false;
    }
    return true;
  };

  const handleAddDetailaApplyEnable = () => {
    return (
      JSON.stringify(defaultAdditionalDetailCol) !=
        JSON.stringify(additionalDetailColState) ||
      JSON.stringify(defaultAdditionalDetailCol) !=
        JSON.stringify(additionalDetailCol)
    );
  };

  const downloadStatement = async () => {
    try {
      const response = await dispatch(
        downloadAllOwnerStatement({
          id: accountNumber,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          credit_debit: statementType,
          start_amount: isAmtFilterApplied ? amountLimit.min : "",
          end_amount: isAmtFilterApplied ? amountLimit.max : "",
          page_size: pageSize,
          formatType: selectedType.toLowerCase(),
          bank_name: selectedtBank.length > 0 ? selectedtBank : undefined,
        })
      );
      if (!response?.payload?.ok) {
        toast.error(errmsg[response?.payload?.status], toast_position);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
    if (filterOpen) {
      if (event.key === "Enter" && !showAmtFilterDiv && handleApplyEnable()) {
        filterapplyButtonRef.current.click();
      }
    }
    if (showCalendar) {
      if (
        event.key === "Enter" ||
        (isDateConfirm && startDateState && endDateState)
      ) {
        calenderapplyButtonRef.current.click();
      }
    }
    if (showAdditionalDetails) {
      if (event.key === "Enter" && handleAddDetailaApplyEnable()) {
        addtionalapplyButtonRef.current.click();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    filterOpen,
    showCalendar,
    showAdditionalDetails,
    showAmtFilterDiv,
    handleAddDetailaApplyEnable,
    handleApplyEnable,
  ]);

  const handleDateChange = (dates) => {
    setIsDateConfirm(false);
    const [start, end] = dates;
    setStartDateState(start);
    setEndDateState(end);
  };

  const move = () => {
    scrollRef?.current?.scrollTo({ left: 0, behavior: "smooth" });
    setTimeout(() => {
      scrollTopRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
  };

  const handleConfirm = () => {
    setIsDateConfirm(true);
    setShowDatePicker(false);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDateState(new Date(new Date().setDate(1)));
    setEndDateState(new Date());
  };

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        (additionalDetailsRef.current &&
          additionalDetailsRef.current.contains(e.target)) ||
        (additionalDetailsBtnRef.current &&
          additionalDetailsBtnRef.current.contains(e.target))
      ) {
      } else {
        setShowAdditionalDetails(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [additionalDetailsRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        (filterRef.current && filterRef.current.contains(e.target)) ||
        (filterButtonRef.current &&
          filterButtonRef.current.contains(e.target)) ||
        (selectAccountRef.current &&
          selectAccountRef.current.contains(e.target))
      ) {
      } else {
        setFilterOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [filterRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        (calenderRef.current && calenderRef.current.contains(e.target)) ||
        (calenderButtonRef.current &&
          calenderButtonRef.current.contains(e.target))
      ) {
      } else {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [calenderRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dateRef.current && dateRef.current.contains(e.target)) {
      } else {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dateRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (fileTypeRef.current && fileTypeRef.current.contains(e.target)) {
      } else {
        setSelectStatement(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fileTypeRef]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (amtDivRef.current && amtDivRef.current.contains(e.target)) {
      } else {
        setShowAmtFilterDiv(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [amtDivRef]);

  const handleAddDetail = (name) => {
    if (!additionalDetailColState.includes(name)) {
      let arr = [...additionalDetailColState, name].sort(
        (a, b) => order.indexOf(a) - order.indexOf(b)
      );
      setAdditionalDetailsColState(arr);
    } else {
      if (additionalDetailColState.length == 1) {
        return toast.error("Atleast one column is required", toast_position);
      }

      setAdditionalDetailsColState(
        additionalDetailColState
          .filter((itm) => {
            return itm !== name;
          })
          .sort((a, b) => order.indexOf(a) - order.indexOf(b))
      );
    }
  };

  const formatDateandTime = (date, showtime, value) => {
    let hours = date.getHours();
    let seconds = date.getSeconds();
    hours %= 12;
    hours = hours || 12;
    const minutes = date.getMinutes();
    if (showtime) {
      if (value) {
        return `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}, ${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }`;
      } else {
        return `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}, ${hours < 10 ? "0" + hours : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`;
      }
    } else {
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    }
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  const filterCategory = () => {
    move();
    setCurrentPage(1);
    setStartDate(startDateState);
    setEndDate(endDateState);
    setShowDatePicker(false);
    setShowCalendar(false);
  };

  const handleAmtFilterApply = () => {
    if (Object.values(amountLimitState).includes("")) return;
    setIsAmtFilterApplied(true);
    setTimeout(() => {
      setShowAmtFilterDiv(false);
    }, 500);
  };

  const handleClearAmts = () => {
    setAmountLimitState((prev) => ({
      ...prev,
      min: "",
      max: "",
    }));
    setIsAmtFilterApplied(false);
  };

  const handleAmtInput = (e) => {
    const { name, value } = e.target;
    if (value[0] != 0) {
      const sanitizedText = value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      const amount = parseFloat(sanitizedText);
      if (sanitizedText.length !== "" && !isNaN(amount)) {
        if (value.includes(".") && value.length > 12) return;
        if (!value.includes(".") && value.length > 9) return;
        const regex = /^\d*\.?\d{0,2}$/;

        if (regex.test(value)) {
          setIsAmtFilterApplied(false);
          setAmountLimitState((prevState) => ({
            ...prevState,
            [name]: sanitizedText,
          }));
        }
      } else {
        setIsAmtFilterApplied(false);
        setAmountLimitState((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      }
    }
  };

  const handleKey = (event) => {
    if (showAmtFilterDiv) {
      if (event.key === "Enter" && showAmtFilterDiv && isAmtFilterApplied) {
        handleAmtFilterApply();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [isAmtFilterApplied, showAmtFilterDiv]);

  const handleEnterKey = (e) => {
    if (
      amountLimitState.min !== "" &&
      amountLimitState.max !== "" &&
      Number(amountLimitState.max) >= Number(amountLimitState.min) &&
      e.key === "Enter"
    ) {
      handleAmtFilterApply();
    }
  };

  return (
    <>
      {showOwnerDetailPopup && (
        <div
          style={{ zIndex: "2147483647" }}
          className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
        >
          <div
            className="fixed top-0 left-0 w-screen h-screen z-[9998] "
            style={{ backgroundColor: "#000000", opacity: "0.8" }}
          ></div>
          <div
            style={{ backgroundColor: "var(--Blue_3)" }}
            className="main_pop_div relative"
          >
            <div className="absolute top-0 bg-Blue_3 z-[99999] px-[20px] left-0 h-[70px] flex justify-between items-center w-full">
              <p className="popup-header">Owner account details</p>
              <div
                onClick={() => setShowOwnerDetailPopup(false)}
                className="h-[30px] w-[30px] flex justify-center items-center bg-Blue_5 cursor-pointer rounded-[5px] hover:bg-Blue_5_hover"
              >
                <img className="h-[15px] w-[15px]" src={Close} alt="" />
              </div>
            </div>
            <OwnerDetailsPage
              onClosed={() => setShowOwnerDetailPopup(false)}
              popType={"ownerStatement"}
              ownerDetailId={ownerDetailId}
            />
          </div>
        </div>
      )}
      {showOwnerPopup && (
        <div
          style={{ zIndex: "2147483647" }}
          className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
        >
          <OwnerAccountPopUp
            setIsSidebarOpen={filterOpen}
            // setOpenOwner={() => {
            //   setaccountnumberstate(accountNumber);
            //   setShowOwnerPopup(false);
            // }}
            setOpenOwner={() => {
              setaccountnumberstate(accountNumber);
              setShowOwnerPopup(false);
            }}
            onClosed={() => setShowOwnerPopup(false)}
            accountNumber={accountNumber}
            setAccountNumber={setAccountNumber}
            accountnumberstate={accountnumberstate}
            setaccountnumberstate={setaccountnumberstate}
            multiSelect={true}
            setDefaultAccountNumber={setDefaultAccountNumber}
            accountDefaultNumber={accountDefaultNumber}
            flow={"txnViewAll"}
            popType={"ownerStatement"}
          />
        </div>
      )}
      {
        <div
          style={{
            zIndex:
              showCalendar || filterOpen || showAdditionalDetails
                ? "0"
                : "9999",
            width: props?.popType == "ownerStatement" ? "96%" : "100%",
            bottom: props?.popType == "ownerStatement" ? "4%" : "0",
            borderRadius:
              props?.popType == "ownerStatement" ? "0 0 20px 20px" : "0",
          }}
          className="fixed w-full bg-Blue_4 h-[50px]"
        >
          <PaginationComponent
            handleSelect={handleSelect}
            selectedValue={selectedValue}
            data={ownerAllAccStatementData || []}
            pageSize={pageSize}
            dropdownOptions={dropdownOptions}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
      }
      <div
        className={`bg-custom-appBack flex flex-col overflow-y-hidden h-screen`}
        style={{ overflow: filterOpen && "hidden" }}
      >
        <div
          className={`${
            props?.popType == "ownerStatement"
              ? "OAS-owner_div_container-popup"
              : "OAS-owner_div_container"
          }`}
        >
          <div className={"OAS-owner_div"}>
            <div
              style={{
                position: "relative",
                top: props?.popType == "ownerStatement" ? "25px" : "20px",
              }}
            >
              <p className="text-[16px] text-Primary_white">
                Total entries : {ownerAllAccStatementData?.total_count || 0}
              </p>
              <p className="text-[10px] text-Primary_grey">
                As on {formatDate(new Date())} - Statement update depends on
                availability from the bank
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "430px",
                position: "relative",
                top: "22px",
              }}
            >
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      zIndex: "2147483647 !important",
                    },
                  },
                }}
                title="Open Calendar"
              >
                <div
                  ref={calenderButtonRef}
                  style={{
                    opacity: showAdditionalDetails || filterOpen ? "0.6" : "1",
                    cursor:
                      showAdditionalDetails || filterOpen
                        ? "default"
                        : "pointer",
                  }}
                  className="OAS-owner_search_div"
                  onClick={() => {
                    if (!showAdditionalDetails && !filterOpen)
                      setShowCalendar(!showCalendar);
                  }}
                >
                  <img
                    className="h-[14px] w-[14px]"
                    src={calendarIcon_blue} // Use your calendar icon here
                    alt="calendar-image"
                  />
                  <img
                    style={{
                      transform: showCalendar ? "rotate(180deg)" : "none",
                    }}
                    src={ArrowDown8} // Same arrow icon logic
                    alt=""
                    className="OAS-filter-images"
                  />
                </div>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      zIndex: "2147483647 !important",
                    },
                  },
                }}
                title="Additional Fields"
              >
                <div
                  ref={additionalDetailsBtnRef}
                  style={{
                    opacity:
                      showCalendar ||
                      filterOpen ||
                      accountDefaultNumber.length == 0
                        ? "0.6"
                        : "1",
                    cursor:
                      showCalendar ||
                      filterOpen ||
                      accountDefaultNumber.length == 0
                        ? "default"
                        : "pointer",
                  }}
                  className="OAS-owner_search_div"
                  onClick={() => {
                    if (
                      !showCalendar &&
                      !filterOpen &&
                      accountDefaultNumber.length != 0
                    ) {
                      setShowAdditionalDetails(!showAdditionalDetails); // Toggle visibility
                    }
                  }}
                >
                  <img
                    className="h-[14px] w-[14px]"
                    src={AdditionalDetailsIcon_blue}
                    alt="additional-details"
                  />
                  <img
                    style={{
                      transform: showAdditionalDetails && "rotate(180deg)", // Rotate icon based on visibility
                    }}
                    src={ArrowDown8}
                    alt=""
                    className="OAS-filter-images"
                  />
                </div>
              </Tooltip>

              <div
                ref={filterButtonRef}
                onClick={() => {
                  if (
                    !showAdditionalDetails &&
                    !showCalendar &&
                    accountDefaultNumber.length != 0
                  ) {
                    setFilterOpen(!filterOpen);
                  }
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                        zIndex: "2147483647 !important",
                      },
                    },
                  }}
                  title="Filters"
                >
                  <div
                    style={{
                      backgroundColor:
                        accountNumber.length > 0 ||
                        statementType.length > 0 ||
                        selectedtBank.length > 0 ||
                        (amountLimit.max.length > 0 &&
                          amountLimit.max.length > 0)
                          ? "var(--Primary_white)"
                          : "",
                      opacity:
                        showAdditionalDetails ||
                        showCalendar ||
                        accountDefaultNumber.length == 0
                          ? "0.6"
                          : "1",
                      cursor:
                        showAdditionalDetails ||
                        showCalendar ||
                        accountDefaultNumber.length == 0
                          ? "default"
                          : "pointer",
                    }}
                    onClick={() => {
                      if (
                        !showAdditionalDetails &&
                        !showCalendar &&
                        accountDefaultNumber.length != 0
                      ) {
                        setFilterOpen(!filterOpen);
                      }
                    }}
                    className={`${
                      accountDefaultNumber.length == 0
                        ? ""
                        : "hover:bg-Blue_4_hover"
                    } OAS-filter-containers`}
                  >
                    <Box className="filters-box">
                      <img
                        src={
                          accountNumber.length > 0 ||
                          statementType.length > 0 ||
                          selectedtBank.length > 0 ||
                          (amountLimit.max.length > 0 &&
                            amountLimit.max.length > 0)
                            ? FilterBlue
                            : filterIcon
                        }
                        alt=""
                        className="OAS-filter-images"
                      />
                    </Box>
                    <Box className="filter-box">
                      <img
                        src={
                          filterOpen
                            ? accountNumber.length > 0 ||
                              statementType.length > 0 ||
                              selectedtBank.length > 0 ||
                              (amountLimit.max.length > 0 &&
                                amountLimit.max.length > 0)
                              ? ArrowUp4
                              : ArrowUp
                            : accountNumber.length > 0 ||
                              statementType.length > 0 ||
                              selectedtBank.length > 0 ||
                              (amountLimit.max.length > 0 &&
                                amountLimit.max.length > 0)
                            ? ArrowDown8
                            : ArrowDown
                        }
                        alt=""
                        className="OAS-filter-images"
                      />
                    </Box>
                  </div>
                </Tooltip>
              </div>
              <div
                style={{
                  opacity:
                    showAdditionalDetails || filterOpen || showCalendar
                      ? "0.6"
                      : "1",
                  cursor:
                    showAdditionalDetails || filterOpen || showCalendar
                      ? "default"
                      : "pointer",
                }}
                className="flex w-[247px] justify-between"
              >
                <div className="flex justify-between h-[30px]">
                  <div className=" flex bg-Failed rounded-[5px] h-[30px]">
                    <img
                      style={{
                        cursor:
                          showAdditionalDetails ||
                          filterOpen ||
                          showCalendar ||
                          !ownerAllAccStatementData?.statement
                            ? "default"
                            : "pointer",
                        opacity: !ownerAllAccStatementData?.statement
                          ? "0.6"
                          : "1",
                      }}
                      onClick={(e) => {
                        if (
                          showAdditionalDetails ||
                          filterOpen ||
                          showCalendar ||
                          !ownerAllAccStatementData?.statement
                        ) {
                          e.stopPropagation();
                          return;
                        } else {
                          downloadStatement();
                        }
                      }}
                      className="w-[20px] h-[20px] m-auto ml-2"
                      src={download_icon}
                      alt="download statement"
                    />
                    <div
                      style={{
                        cursor:
                          showAdditionalDetails ||
                          filterOpen ||
                          showCalendar ||
                          !ownerAllAccStatementData?.statement
                            ? "default"
                            : "pointer",
                        opacity: !ownerAllAccStatementData?.statement
                          ? "0.6"
                          : "1",
                      }}
                      onClick={(e) => {
                        if (
                          showAdditionalDetails ||
                          filterOpen ||
                          showCalendar ||
                          !ownerAllAccStatementData?.statement
                        ) {
                          e.stopPropagation();
                          return;
                        } else {
                          downloadStatement();
                        }
                      }}
                      className="flex justify-center items-center h-[30px] w-[136px] OAS-download_statement"
                    >
                      Download statement
                    </div>
                    <div
                      style={{
                        borderRight: "1px solid var(--Primary_white)",
                        margin: "5px 0px",
                      }}
                    ></div>
                    <div ref={fileTypeRef} className="OAS-statement-type">
                      <Button
                        sx={{
                          cursor:
                            showAdditionalDetails || filterOpen || showCalendar
                              ? "default"
                              : "pointer",
                        }}
                        className="OAS-pdf-main-btn"
                        onClick={(e) => {
                          if (
                            showAdditionalDetails ||
                            filterOpen ||
                            showCalendar
                          ) {
                            e.stopPropagation();
                            return;
                          } else {
                            setSelectStatement(!selectStatement);
                          }
                        }}
                        style={{
                          height: selectStatement ? "180px" : "30px",
                        }}
                      >
                        <div
                          style={{
                            height: "30px",
                            width: "80px",
                          }}
                          className="OAS-bank_high_to_low_select_select"
                        >
                          <span
                            className="font-semibold pt-[0.1rem]"
                            style={{
                              fontSize: "12px",
                              paddingLeft: "10px",
                              color: "var(--Primary_white)",
                            }}
                          >
                            {selectedType}
                          </span>
                          <img
                            src={arrowDown}
                            alt="Status Icon"
                            style={{
                              height: "12px",
                              width: "12px",
                              transform: selectStatement
                                ? "rotate(180deg)"
                                : "",
                            }}
                            className="status_icon-img"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "60px",
                            top: "36px",
                            borderBottom: "1px solid var(--Blue_2)",
                          }}
                        ></div>
                        <div
                          style={{
                            padding: "10px 5px",
                            top: "26px",
                            width: "80px",
                          }}
                          className="OAS-bank_high_to_low_select_scroll"
                        >
                          <Button
                            sx={{
                              width: "70px",
                              backgroundColor:
                                selectedType == "XLSX" &&
                                "var(--Failed_to_take_action)",
                            }}
                            className="OAS-statement_select_scroll_com"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedType("XLSX");
                              setSelectStatement(false);
                            }}
                          >
                            XLSX
                            <div
                              style={{
                                width: "70px",
                                display:
                                  selectedType == "XLSX" ? "flex" : "none",
                                textAlign: "right",
                                justifyContent: "end",
                                paddingRight: "5px",
                              }}
                            >
                              <img
                                style={{
                                  height: "13px",
                                  width: "11px",
                                }}
                                src={CheckIcon}
                                alt="check"
                              />
                            </div>
                          </Button>
                          <Button
                            sx={{
                              width: "70px",
                              backgroundColor:
                                selectedType == "PDF" &&
                                "var(--Failed_to_take_action)",
                            }}
                            className="OAS-statement_select_scroll_com"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedType("PDF");

                              setSelectStatement(false);
                            }}
                          >
                            PDF
                            <div
                              style={{
                                paddingRight: "5px",
                                width: "70px",
                                display:
                                  selectedType == "PDF" ? "flex" : "none",
                                textAlign: "right",
                                justifyContent: "end",
                              }}
                            >
                              <img
                                style={{
                                  height: "13px",
                                  width: "11px",
                                }}
                                src={CheckIcon}
                                alt="check"
                              />
                            </div>
                          </Button>

                          <Button
                            sx={{
                              width: "70px",
                              backgroundColor:
                                selectedType == "TSV" &&
                                "var(--Failed_to_take_action)",
                            }}
                            className="OAS-statement_select_scroll_com"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedType("TSV");

                              setSelectStatement(false);
                            }}
                          >
                            TSV
                            <div
                              style={{
                                paddingRight: "5px",
                                width: "70px",
                                display:
                                  selectedType == "TSV" ? "flex" : "none",
                                textAlign: "right",
                                justifyContent: "end",
                              }}
                            >
                              <img
                                style={{
                                  height: "13px",
                                  width: "11px",
                                }}
                                src={CheckIcon}
                                alt="check"
                              />
                            </div>
                          </Button>
                          <Button
                            sx={{
                              width: "70px",
                              backgroundColor:
                                selectedType == "CSV" &&
                                "var(--Failed_to_take_action)",
                            }}
                            className="OAS-statement_select_scroll_com"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedType("CSV");

                              setSelectStatement(false);
                            }}
                          >
                            CSV
                            <div
                              style={{
                                paddingRight: "5px",
                                width: "70px",
                                display:
                                  selectedType == "CSV" ? "flex" : "none",
                                textAlign: "right",
                                justifyContent: "end",
                              }}
                            >
                              <img
                                style={{
                                  height: "13px",
                                  width: "11px",
                                }}
                                src={CheckIcon}
                                alt="check"
                              />
                            </div>
                          </Button>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`OAS-filter-transition-${filterOpen ? "open" : "close"}`}
          >
            {filterOpen && (
              <div
                style={{
                  maxWidth: "1024px",
                  margin: "0 auto",
                  borderTop: "1px solid var(--Blue_3)",
                  paddingTop: "10px",
                }}
                ref={filterRef}
              >
                <span className="text-Button_red font-semibold ml-[10px]">
                  Filter{" "}
                </span>
                <div className="OAS-owner_filterdropdown">
                  <div
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      width: "25%",
                      gap: "20px",
                      height: "180px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <p
                        style={{
                          color: "var(--Primary_grey)",
                          height: "22px",
                          fontSize: "12px",
                          marginTop: "1px",
                        }}
                      >
                        Account
                      </p>
                      <div
                        onClick={() => {
                          if (props?.popType != "ownerStatement") {
                            setShowOwnerPopup(true);
                          }
                        }}
                        className="w-[216px] h-[36px] bg-Blue_8 rounded-[10px]"
                        style={{
                          height: "37px",
                          opacity:
                            props?.popType == "ownerStatement" ? "0.6" : "1",
                          cursor:
                            props?.popType == "ownerStatement"
                              ? "default"
                              : "pointer",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            color: "var(--Primary_white)",
                            padding: "9.5px 10px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {props?.popType == "ownerStatement"
                              ? formatAccountNumber(
                                  props?.ownerDetailData?.bank_acc_no
                                )
                              : accountnumberstate.length > 0
                              ? `${accountnumberstate.length} selected`
                              : "Select accounts"}
                          </span>
                          <img
                            src={ArrowDown5}
                            alt="down"
                            style={{
                              height: "12px",
                              transform: "rotate(270deg) translate(0px, 5px)",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <TypeDropdown
                        statementTypeState={statementTypeState}
                        setStatementTypeState={setStatementTypeState}
                        typeOpen={typeOpen}
                        setTypeOpen={setTypeOpen}
                        owner={true}
                      />
                    </div>
                    {/* <SelectBank
                      option={bankOptions || []}
                      selectedtBankState={selectedtBankState}
                      setSelectedtBankState={setSelectedtBankState}
                      selectBank={showSelectBank}
                      setSelectBank={setShowSelectBank}
                      statement={true}
                    /> */}
                  </div>
                  <div className="OAS-owner-border-div-line"></div>
                  <div
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      width: "25%",
                      gap: "20px",
                      height: "180px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div>
                      <p
                        style={{
                          color: "var(--Primary_grey)",
                          height: "22px",
                          fontSize: "12px",
                          marginTop: "1px",
                        }}
                      >
                        Amount
                      </p>
                      <div ref={amtDivRef} className="OAS-amtDiv_type">
                        <div
                          className="OAS-amount-main-btn"
                          onClick={() => {
                            setShowAmtFilterDiv(!showAmtFilterDiv);
                          }}
                          style={{
                            height: showAmtFilterDiv ? "230px" : "37px",
                            cursor: "default",
                          }}
                        >
                          <div
                            style={{
                              height: "37px",
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              cursor: "pointer",
                              zIndex: "9999",
                            }}
                            className="OAS-bank_high_to_low_select_select range_background"
                          >
                            <span
                              className={`${
                                isAmtFilterApplied && !showAmtFilterDiv
                                  ? "text-Primary_white"
                                  : "text-Primary_grey"
                              }  text-[12px]`}
                            >
                              {isAmtFilterApplied && !showAmtFilterDiv
                                ? `₹ ${amountLimitState.min} - ₹ ${amountLimitState.max}`
                                : "Set range"}
                            </span>

                            <img
                              src={ArrowDown5}
                              alt="Status Icon"
                              style={{
                                height: "12px",
                                width: "12px",

                                transform: showAmtFilterDiv
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                              className="status_icon-img"
                            />
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              width: "195px",
                              margin: "0 10px",
                              top: "37px",
                              borderBottom: "1px solid var(--Blue_2)",
                            }}
                          ></div>
                          <div
                            onClick={(e) => e.stopPropagation()}
                            className="OAS-amount-inputs-div"
                          >
                            <div className="OAS-input-container">
                              <label for="min">Minimum</label>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    position: "absolute",
                                    left: "8px",
                                    top: "5px",
                                    fontSize: "12px",
                                    display:
                                      amountLimitState.min === ""
                                        ? "none"
                                        : "block",
                                  }}
                                >
                                  ₹
                                </p>

                                <input
                                  id="min"
                                  onChange={handleAmtInput}
                                  type="text"
                                  value={amountLimitState.min}
                                  name="min"
                                  placeholder="Enter amount"
                                  onKeyDown={handleEnterKey}
                                />
                              </div>
                            </div>

                            <div className="OAS-input-container">
                              <label for="max">Maximum</label>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    position: "absolute",
                                    left: "8px",
                                    top: "5.5px",
                                    fontSize: "12px",
                                    display:
                                      amountLimitState.max === ""
                                        ? "none"
                                        : "block",
                                  }}
                                >
                                  ₹
                                </p>

                                <input
                                  id="max"
                                  onChange={handleAmtInput}
                                  type="text"
                                  value={amountLimitState.max}
                                  name="max"
                                  placeholder="Enter amount"
                                  onKeyDown={handleEnterKey}
                                />
                              </div>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                margin: "10px 0",
                              }}
                            >
                              * Specify the amount to filter by.
                            </p>
                            <div className="OAS-inputs-div-btns">
                              <button
                                className={`OAS-clear-button ${
                                  amountLimitState.min === "" &&
                                  amountLimitState.max === ""
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={handleClearAmts}
                              >
                                Clear
                              </button>
                              <button
                                onClick={handleAmtFilterApply}
                                className={`OAS-apply-button ${
                                  amountLimitState.min === "" ||
                                  amountLimitState.max === "" ||
                                  Number(amountLimitState.min) >=
                                    Number(amountLimitState.max)
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                      <TypeDropdown
                        statementTypeState={statementTypeState}
                        setStatementTypeState={setStatementTypeState}
                        typeOpen={typeOpen}
                        setTypeOpen={setTypeOpen}
                        owner={true}
                      />
                    </div> */}
                  </div>
                </div>
                <div
                  className="pb-[20px]"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%%",
                  }}
                >
                  <div className="OAS-buttoncontainer">
                    <div
                      style={{
                        cursor: handleApplyEnable() ? "pointer" : "default",
                        opacity: handleApplyEnable() ? "1" : "0.6",
                      }}
                      onClick={() => {
                        if (handleApplyEnable()) {
                          move();
                          setAccountNumber(accountDefaultNumber);
                          setaccountnumberstate(accountDefaultNumber);
                          setSelectedtBank("");
                          setSelectedtBankState("");
                          setAmountLimit({ min: "", max: "" });
                          setAmountLimitState({ min: "", max: "" });
                          setStatementType("");
                          setStatementTypeState("");
                          setIsAmtFilterApplied(false);
                          setCurrentPage(1);
                        }
                      }}
                      className={`OAS-acc-viewall-clear-filter border border-Primary_white text-Primary_white text-xs text-center w-[87px] h-[25px] ${
                        handleApplyEnable() ? "hover:bg-Blue_6_hover" : ""
                      }`}
                    >
                      Default
                    </div>
                    <div
                      ref={filterapplyButtonRef}
                      onClick={() => {
                        if (handleApplyEnable()) {
                          move();
                          setAccountNumber(accountnumberstate);
                          setSelectedtBank(selectedtBankState);
                          if (
                            isAmtFilterApplied &&
                            amountLimitState.min !== "" &&
                            amountLimitState.max !== "" &&
                            Number(amountLimitState.max) >
                              Number(amountLimitState.min)
                          ) {
                            setAmountLimit(amountLimitState);
                          } else if (
                            amountLimitState.min.length == 0 ||
                            amountLimitState.max.length == 0
                          ) {
                            setAmountLimit(amountLimitState);
                          }
                          setStatementType(statementTypeState);
                          setCurrentPage(1);
                          setFilterOpen(false);
                        }
                      }}
                      className={`OAS-apply ${
                        handleApplyEnable()
                          ? "hover:opacity-90 bg-Button_red hover:bg-Button_red_hover"
                          : "bg-Secondary_grey"
                      }`}
                      style={{
                        cursor: handleApplyEnable() ? "pointer" : "default",
                        // backgroundColor: handleApplyEnable()
                        //   ? "var(--Button_red)"
                        //   : "var(--Secondary_grey)",
                      }}
                    >
                      <p
                        style={{
                          color: handleApplyEnable()
                            ? "var(--Primary_white)"
                            : "var(--Primary_grey)",
                        }}
                        className="OAS-buttoncontainer-text"
                      >
                        Apply
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            ref={calenderRef}
            className={`OAS-beneTxnviewallsidebar ${
              showCalendar ? "open" : "closed"
            }`}
            style={{
              marginTop: props?.popType == "ownerStatement" ? "126px" : "170px",
            }}
          >
            <div
              className="OAS-beneTxnviewallFilterDiv flex justify-between"
              style={{
                visibility: showCalendar ? "visible" : "hidden",
                transition: `opacity 0.2s ease-in ${
                  showCalendar ? "0s" : "0.2s"
                }`,
                opacity: showCalendar ? "1" : "0",
                borderTop: "1px solid var(--Blue_3)",
              }}
            >
              <div className="OAS-beneTxnFilterSection1">
                <span className="text-Button_red font-semibold">Calender </span>
                <div className="OAS-beneTxnFilterSection3 mt-[15px]">
                  <span
                    style={{
                      color: "var(--Primary_white)",
                      width: "62px",
                      height: "17px",
                      font: "normal normal 12px/19px Open Sans",
                      marginBottom: "10px",
                    }}
                  >
                    Date range
                  </span>
                  <div
                    className="OAS-bene_Txn_Calendar_main_div relative flex flex-col items-center bg-Blue_8 cursor-pointer"
                    ref={dateRef}
                    style={{
                      width: "305px",
                      height: showDatePicker ? "" : "39px",
                      border: showDatePicker
                        ? "1px solid var(--Failed)"
                        : "none",
                    }}
                  >
                    <div
                      className="flex items-center justify-around rounded-[5px] bg-Blue_8 cursor-pointer"
                      style={{
                        width: "290px",
                        height: showDatePicker ? "" : "39px",
                      }}
                      onClick={() => {
                        setShowDatePicker(!showDatePicker);
                      }}
                    >
                      <div className="OAS-beneTxn_date_heading_div cursor-pointer">
                        <div className="OAS-beneTxn_date_heading_left">
                          {startDateState
                            ? formatDate(startDateState)
                            : "Select from date"}
                        </div>
                      </div>
                      <img
                        src={arrowforward}
                        className="OAS-bene_txn_viewall_calendar_icon"
                      />
                      <div className="OAS-beneTxn_date_select_div cursor-pointer">
                        <div className="OAS-beneTxn_date_heading_right">
                          {" "}
                          {endDateState
                            ? formatDate(endDateState)
                            : "Select to date"}
                        </div>
                      </div>
                    </div>
                    {showDatePicker && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <DatePickerComponent
                          startDate={startDateState}
                          endDate={endDateState}
                          handleDateChange={handleDateChange}
                          handleCancel={handleCancel}
                          handleConfirm={handleConfirm}
                          showDatePicker={showDatePicker}
                          yearDropdownRef={yearDropdownRef}
                          dateRef={dateRef}
                          setShowDatePicker={setShowDatePicker}
                          isOpen={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="OAS-beneTxnFilterbuttonContainer">
                <div
                  className={`OAS-acc-viewall-clear-filter border border-Primary_white text-Primary_white text-xs text-center hover:opacity-80 w-[87px] h-[25px] ${
                    ((+startDate !== +startDateState &&
                      +endDate !== +endDateState) ||
                      !startDate ||
                      !endDate ||
                      isDateConfirm) &&
                    "hover:bg-Blue_6_hover"
                  }`}
                  onClick={() => {
                    if (
                      (+startDate !== +startDateState &&
                        +endDate !== +endDateState) ||
                      !startDate ||
                      !endDate ||
                      isDateConfirm
                    ) {
                      move();
                      setStartDate(new Date(new Date().setDate(1)));
                      setStartDateState(new Date(new Date().setDate(1)));
                      setEndDate(new Date());
                      setEndDateState(new Date());
                      setIsDateConfirm(false);
                    }
                  }}
                  style={{
                    cursor:
                      (+startDate !== +startDateState &&
                        +endDate !== +endDateState) ||
                      !startDate ||
                      !endDate ||
                      isDateConfirm
                        ? "pointer"
                        : "default",
                    opacity:
                      (+startDate !== +startDateState &&
                        +endDate !== +endDateState) ||
                      !startDate ||
                      isDateConfirm ||
                      !endDate
                        ? "1"
                        : "0.6",
                  }}
                >
                  Default
                </div>
                <div
                  ref={calenderapplyButtonRef}
                  className={`OAS-acc-viewall-apply-filter text-center text-Primary_white text-xs ml-[10px] w-[87px] h-[25px] ${
                    isDateConfirm && startDateState && endDateState
                      ? "bg-Button_red hover:bg-Button_red_hover"
                      : "bg-Secondary_grey"
                  }`}
                  onClick={() => {
                    if (isDateConfirm && startDateState && endDateState) {
                      filterCategory();
                    }
                  }}
                  style={{
                    // backgroundColor:
                    //   isDateConfirm && startDateState && endDateState
                    //     ? "var(--Button_red)"
                    //     : "var(--Secondary_grey)",
                    opacity:
                      isDateConfirm && startDateState && endDateState
                        ? "1"
                        : "0.6",
                    cursor:
                      isDateConfirm && startDateState && endDateState
                        ? "pointer"
                        : "default",
                  }}
                >
                  <p style={{ height: "18px" }}>Apply</p>
                </div>
              </div>
            </div>
          </div>

          <div
            ref={additionalDetailsRef}
            className={`OAS-beneTxnviewallsidebar ${
              showAdditionalDetails
                ? "addtionalDetailsopen"
                : "additionalDetailsclosed"
            }`}
            style={{
              marginTop: props?.popType == "ownerStatement" ? "126px" : "170px",
            }}
          >
            <div
              className="max-w-[1024px] mx-auto justify-between pt-[10px]"
              style={{
                visibility: showAdditionalDetails ? "visible" : "hidden",
                transition: `opacity 0.2s ease-in ${
                  showAdditionalDetails ? "0s" : "0.2s"
                }`,
                opacity: showAdditionalDetails ? "1" : "0",
                borderTop: "1px solid var(--Blue_3)",
              }}
            >
              <div className="h-auto">
                <span className="text-Button_red font-semibold">
                  Additional Fields{" "}
                </span>
                <div className="flex justify-between text-[12px] text-Primary_grey">
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div
                      onClick={() => handleAddDetail("bank/bank name")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Bank/ Bank name</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("bank/bank name")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("bankref")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Bank reference no.</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("bankref")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("debitamount")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Debit amount</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("debitamount")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("name")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Name</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("name")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div
                      onClick={() => handleAddDetail("accno")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Account number</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("accno")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("narration")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Narration</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("narration")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("creditamount")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Credit amount</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("creditamount")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div
                      onClick={() => handleAddDetail("transactiondateandtime")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Transaction date and time</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes(
                              "transactiondateandtime"
                            )
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("type")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Type</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("type")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("closingbalance")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Closing balance</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("closingbalance")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div
                      onClick={() => handleAddDetail("valuedate")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Value date</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("valuedate")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("amount")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>Amount</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("amount")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => handleAddDetail("ifcscode")}
                      className="w-[216px] h-[36px] cursor-pointer bg-Blue_8 hover:bg-Blue_8_hover rounded-[10px] items-center justify-between flex px-[10px]"
                    >
                      <p>IFSC code</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={
                            additionalDetailColState.includes("ifcscode")
                              ? selectWithGreenFilledCircle
                              : selectWithWhiteFilledCircle
                          }
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex pt-[20px] pb-[20px] justify-end">
                <div
                  className={`OAS-acc-viewall-clear-filter border border-Primary_white text-Primary_white text-xs text-center w-[87px] h-[25px] ${
                    handleAddDetailaApplyEnable() ? "hover:bg-Blue_6_hover" : ""
                  }`}
                  onClick={() => {
                    if (handleAddDetailaApplyEnable()) {
                      move();
                      setAdditionalDetailsColState([
                        "bank/bank name",
                        "bankref",
                        "accno",
                        "narration",
                        "transactiondateandtime",
                        "type",
                        "valuedate",
                        "amount",
                      ]);
                      setAdditionalDetailsCol([
                        "bank/bank name",
                        "bankref",
                        "accno",
                        "narration",
                        "transactiondateandtime",
                        "type",
                        "valuedate",
                        "amount",
                      ]);
                    }
                  }}
                  style={{
                    cursor: handleAddDetailaApplyEnable()
                      ? "pointer"
                      : "default",
                    opacity: handleAddDetailaApplyEnable() ? "1" : "0.6",
                  }}
                >
                  Default
                </div>
                <div
                  ref={addtionalapplyButtonRef}
                  className={`OAS-acc-viewall-apply-filter text-center text-Primary_white text-xs ml-[10px] w-[87px] h-[25px] ${
                    handleAddDetailaApplyEnable()
                      ? "hover:opacity-90 bg-Button_red hover:bg-Button_red_hover"
                      : "bg-Secondary_grey"
                  }`}
                  onClick={() => {
                    if (handleAddDetailaApplyEnable()) {
                      move();
                      setAdditionalDetailsCol(additionalDetailColState);
                      setShowAdditionalDetails(false);
                    }
                  }}
                  style={{
                    // backgroundColor: handleAddDetailaApplyEnable()
                    //   ? "var(--Button_red)"
                    //   : "var(--Secondary_grey)",
                    opacity: handleAddDetailaApplyEnable() ? "1" : "0.6",
                    cursor: handleAddDetailaApplyEnable()
                      ? "pointer"
                      : "default",
                  }}
                >
                  <p style={{ height: "17px" }}>Apply</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="OAS-filter_applied_div w-full">
          <div
            style={{
              borderTop: "1px solid var(--Blue_3)",
            }}
            className="w-[1024px] mx-auto py-[10px] h-[60px] flex justify-between items-center"
          >
            <div className="flex">
              <p className="filterby_text">Filtered by :</p>
              <div className="filter_badge_div">
                {startDate && endDate && (
                  <div className="OAS-filter_badge">
                    <p
                      style={{ maxWidth: "160px" }}
                      className="filter_badge_text"
                    >
                      {`${formatDate(startDate).replace(
                        ",",
                        ""
                      )} - ${formatDate(endDate).replace(",", "")}`}
                    </p>
                    <img
                      style={{
                        opacity: "0.6",

                        cursor: "default",
                      }}
                      className="filter_badge_img"
                      src={clearFilterImg}
                      alt="X"
                    />
                  </div>
                )}
                {accountNumber.length > 0 && (
                  <div className="OAS-filter_badge">
                    <p
                      style={{ maxWidth: "130px" }}
                      className="filter_badge_text"
                    >
                      {props?.popType == "ownerStatement"
                        ? formatAccountNumber(
                            props?.ownerDetailData?.bank_acc_no
                          )
                        : `${accountNumber.length} ${
                            accountNumber.length > 1
                              ? "accounts selected"
                              : "account selected"
                          }`}
                    </p>
                    <img
                      style={{
                        opacity: "0.6",

                        cursor: "default",
                      }}
                      className="filter_badge_img"
                      src={clearFilterImg}
                      alt="X"
                    />
                  </div>
                )}
                {selectedtBank.length > 0 && (
                  <div className="OAS-filter_badge">
                    <p className="filter_badge_text">{selectedtBank}</p>
                    <img
                      onClick={() => {
                        setSelectedtBank("");
                        setSelectedtBankState("");
                      }}
                      className="filter_badge_img"
                      src={clearFilterImg}
                      alt="X"
                    />
                  </div>
                )}
                {statementType.length > 0 && (
                  <div className="OAS-filter_badge">
                    <p className="filter_badge_text">{statementType}</p>
                    <img
                      onClick={() => {
                        setStatementType("");
                        setStatementTypeState("");
                      }}
                      className="filter_badge_img"
                      src={clearFilterImg}
                      alt="X"
                    />
                  </div>
                )}
                {amountLimit.min.length > 0 && amountLimit.max.length > 0 && (
                  <div className="OAS-filter_badge">
                    <p
                      style={{ maxWidth: "160px" }}
                      className="filter_badge_text"
                    >
                      {`₹ ${amountLimit.min} - ₹ ${amountLimit.max}`}
                    </p>
                    <img
                      onClick={() => {
                        setAmountLimit({ min: "", max: "" });
                        setAmountLimitState({ min: "", max: "" });
                        setIsAmtFilterApplied(false);
                      }}
                      className="filter_badge_img"
                      src={clearFilterImg}
                      alt="X"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position:
              showCalendar || filterOpen || showAdditionalDetails
                ? "absolute"
                : "relative",
            opacity:
              showCalendar || filterOpen || showAdditionalDetails ? "0.8" : "0",
            display:
              showCalendar || filterOpen || showAdditionalDetails
                ? "block"
                : "hidden",
            backgroundColor:
              showCalendar || showAdditionalDetails || filterOpen ? "#000" : "",
            height:
              showCalendar || filterOpen || showAdditionalDetails
                ? "100%"
                : "0",
            width:
              showCalendar || filterOpen || showAdditionalDetails
                ? "100%"
                : "0",
            zIndex: "99",
          }}
        ></div>
        <div
          ref={scrollRef}
          style={{
            overflowY: "hidden",
            marginBottom: props?.popType == "ownerStatement" ? "8%" : "55px",
            height: "100%",
            width: props?.popType == "ownerStatement" ? "96vw" : "100vw",
          }}
          className={`${
            props?.popType == "ownerStatement"
              ? "popupstatementviewallscroll"
              : ""
          } overflow-x-auto  statementviewallscroll`}
        >
          <div className="w-max min-w-full z-10">
            <div className="OAS-table-div-container mb-1 pr-[5px]">
              {additionalDetailCol.includes("bank/bank name") && (
                <>
                  <p className="OAS-label-div-trans OAS_grey OAS-label-bank-width w-[130px]">
                    Bank
                  </p>
                  <div
                    style={{
                      display: "block",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("bank/bank name") && (
                <>
                  <p className="OAS-label-div-trans OAS_grey OAS-label-bank-width w-[130px]">
                    Bank name
                  </p>
                  <div
                    style={{
                      display:
                        additionalDetailCol.length > 1 ? "block" : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("ifcscode") && (
                <>
                  <p className="OAS-label-div-ref OAS_grey">IFSC code</p>
                  <div
                    style={{
                      display: [
                        "transactiondateandtime",
                        "valuedate",
                        "accno",
                        "bankref",
                        "narration",
                        "type",
                        "amount",
                        "closingbalance",
                        "name",
                        "debitamount",
                        "creditamount",
                      ].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("accno") && (
                <>
                  <p className="OAS-label-div-trans OAS_grey OAS-label-account-width">
                    Account number
                  </p>
                  <div
                    style={{
                      display: [
                        "transactiondateandtime",
                        "valuedate",
                        "bankref",
                        "narration",
                        "type",
                        "amount",
                        "closingbalance",
                        "name",
                        "debitamount",
                        "creditamount",
                      ].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("transactiondateandtime") && (
                <>
                  <p className="OAS-label-div-trans OAS_grey OAS-label-TimeDate-width">
                    Transaction date <br />
                    and time
                  </p>
                  <div
                    style={{
                      display: [
                        "valuedate",
                        "bankref",
                        "narration",
                        "type",
                        "amount",
                        "closingbalance",
                        "name",
                        "debitamount",
                        "creditamount",
                      ].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("valuedate") && (
                <>
                  <p className="OAS-label-div-val OAS_grey">Value date</p>
                  <div
                    style={{
                      display: [
                        "bankref",
                        "narration",
                        "type",
                        "amount",
                        "closingbalance",
                        "name",
                        "debitamount",
                        "creditamount",
                      ].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("bankref") && (
                <>
                  <p className="OAS-label-div-ref OAS_grey">
                    Bank reference no.
                  </p>
                  <div
                    style={{
                      display: [
                        "narration",
                        "type",
                        "amount",
                        "closingbalance",
                        "name",
                        "debitamount",
                        "creditamount",
                      ].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("narration") && (
                <>
                  <p className="OAS-label-div-narr OAS_grey">Narration</p>
                  <div
                    style={{
                      display: [
                        "type",
                        "amount",
                        "closingbalance",
                        "name",
                        "debitamount",
                        "creditamount",
                      ].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("type") && (
                <>
                  <div className="OAS-label-div-type OAS_grey">Type</div>
                  <div
                    style={{
                      display: [
                        "amount",
                        "closingbalance",
                        "name",
                        "debitamount",
                        "creditamount",
                      ].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("amount") && (
                <>
                  <div className="OAS-label-div-amt OAS_grey">Amount</div>
                  <div
                    style={{
                      display: [
                        "closingbalance",
                        "name",
                        "debitamount",
                        "creditamount",
                      ].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("creditamount") && (
                <>
                  <div className="OAS-label-div-amt OAS_grey">
                    Credit amount
                  </div>
                  <div
                    style={{
                      display: ["closingbalance", "name", "debitamount"].some(
                        (col) => {
                          return additionalDetailCol.includes(col);
                        }
                      )
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("debitamount") && (
                <>
                  <div className="OAS-label-div-amt OAS_grey">Debit amount</div>
                  <div
                    style={{
                      display: ["closingbalance", "name"].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}
              {additionalDetailCol.includes("closingbalance") && (
                <>
                  <p className="OAS-label-div-clamt OAS_grey">
                    Closing Balance
                  </p>
                  <div
                    style={{
                      display: ["name"].some((col) => {
                        return additionalDetailCol.includes(col);
                      })
                        ? "block"
                        : "none",
                    }}
                    className="OAS-border-mid-div"
                  ></div>
                </>
              )}

              {additionalDetailCol.includes("name") && (
                <>
                  <p className="OAS-label-div-narr OAS_grey">Name</p>
                </>
              )}
            </div>
          </div>

          {accountNumber.length == 0 ? (
            <div className="h-full flex-col m-auto pt-[40px] pb-[70px]  overflow-y-scroll justify-center items-center flex">
              <img
                style={{
                  width: "240px",
                  height: "220px",
                  marginBottom: "20px",
                }}
                src={selectAccount}
                alt="No accounts found"
                className="statement_noacc_found"
              />
              <p className="select_text flex flex-col">
                <span>Select account/s to</span>
                <span>view consolidated statement</span>
              </p>
              <div
                ref={selectAccountRef}
                className="oas-selectaccount min-h-[36px]"
                onClick={() => {
                  setShowOwnerPopup(true);
                }}
              >
                <p className="oas-selectaccounttext">Select account</p>
              </div>
            </div>
          ) : ownerAllAccStatementLoading ? (
            <div
              className={`absolute top-auto overflow-x-auto flex h-[50vh] m-auto justify-center items-center ${
                props?.popType == "ownerStatement" ? "w-[96vw]" : "w-screen"
              }`}
            >
              <Loader />
            </div>
          ) : ownerAllAccStatementData?.statement?.length > 0 ? (
            <div
              ref={scrollTopRef}
              style={{
                overflowY:
                  ownerAllAccStatementLoading ||
                  ownerAllAccStatementData?.owner_account_details?.statement
                    ?.length == 0
                    ? "hidden"
                    : "auto",
              }}
              className="overflow-auto h-[90%] 4.6xl:h-[95%] overflow-y-scroll w-max min-w-full "
            >
              {ownerAllAccStatementData?.statement?.map((statement) => {
                return (
                  <div className="OAS-table-div-content mb-1">
                    {additionalDetailCol.includes("bank/bank name") && (
                      <>
                        <div className="OAS-label-div-trans OAS-label-bank-width">
                          <div className="h-[26px] w-[70px] rounded-[56px] bg-Primary_white flex justify-center items-center">
                            <img
                              className="w-[50px] h-[13px]"
                              src={require("../../../../assets/IfscLogo/" +
                                IfscCodeMapper(statement.ifsc_code))}
                              alt="ICICI-Bank"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            border: "none",
                            display: "block",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("bank/bank name") && (
                      <>
                        <p
                          style={{ minWidth: "130px" }}
                          className="OAS-label-div-trans OAS-label-bank-width"
                        >
                          {statement.bank_name}
                        </p>
                        <div
                          style={{
                            display:
                              additionalDetailCol.length > 1 ? "block" : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("ifcscode") && (
                      <>
                        <p className="OAS-label-div-ref">
                          {statement.ifsc_code}
                        </p>
                        <div
                          style={{
                            display: [
                              "transactiondateandtime",
                              "valuedate",
                              "accno",
                              "bankref",
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("accno") && (
                      <>
                        <p
                          onClick={() => {
                            if (props?.popType != "ownerStatement") {
                              setOwnerDetailID(statement.owner_account_id);
                              setTimeout(() => {
                                setShowOwnerDetailPopup(true);
                              }, 500);
                            }
                          }}
                          className={`OAS-label-div-trans OAS-label-account-width ${
                            props?.popType != "ownerStatement"
                              ? "ownerredirect"
                              : ""
                          } `}
                        >
                          {formatAccountNumber(
                            statement.owner_bank_account_number
                          )}
                        </p>
                        <div
                          style={{
                            display: [
                              "transactiondateandtime",
                              "valuedate",
                              "bankref",
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("transactiondateandtime") && (
                      <>
                        <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                          {formatDateandTime(
                            new Date(statement.transaction_date),
                            true
                          )}
                        </p>
                        <div
                          style={{
                            display: [
                              "valuedate",
                              "bankref",
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("valuedate") && (
                      <>
                        <p className="OAS-label-div-val">
                          {formatDateandTime(new Date(statement.value_date))}
                        </p>
                        <div
                          style={{
                            display: [
                              "bankref",
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}

                    {additionalDetailCol.includes("bankref") && (
                      <>
                        <p className="OAS-label-div-ref">
                          {statement.bank_reference}
                        </p>
                        <div
                          style={{
                            display: [
                              "narration",
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("narration") && (
                      <>
                        <p className="OAS-label-div-narr">
                          {statement.narration}
                        </p>
                        <div
                          style={{
                            display: [
                              "type",
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("type") && (
                      <>
                        <div className="OAS-label-div-type">
                          {statement.credit_debit}
                        </div>
                        <div
                          style={{
                            display: [
                              "amount",
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("amount") && (
                      <>
                        <div
                          className="OAS-label-div-amt"
                          style={{
                            color: "var(--Primary_white)",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          ₹ <FormatAmount price={Number(statement.amount)} />
                        </div>
                        <div
                          style={{
                            display: [
                              "closingbalance",
                              "name",
                              "debitamount",
                              "creditamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("creditamount") && (
                      <>
                        <div
                          className="OAS-label-div-amt"
                          style={{
                            color: "var(--Settled)",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          {statement.credit_debit == "Credit" ? (
                            <>
                              ₹{" "}
                              <FormatAmount price={Number(statement.amount)} />
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div
                          style={{
                            display: [
                              "closingbalance",
                              "name",
                              "debitamount",
                            ].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("debitamount") && (
                      <>
                        <div
                          className="OAS-label-div-amt"
                          style={{
                            color: "var(--Failed)",
                            textAlign: "right",
                            justifyContent: "end",
                          }}
                        >
                          {statement.credit_debit == "Debit" ? (
                            <>
                              ₹{" "}
                              <FormatAmount price={Number(statement.amount)} />
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                        <div
                          style={{
                            display: ["closingbalance", "name"].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}
                    {additionalDetailCol.includes("closingbalance") && (
                      <>
                        <p
                          style={{ justifyContent: "end" }}
                          className="OAS-label-div-clamt"
                        >
                          ₹{" "}
                          <FormatAmount
                            price={Number(statement.closing_balance)}
                          />
                        </p>
                        <div
                          style={{
                            display: ["name"].some((col) => {
                              return additionalDetailCol.includes(col);
                            })
                              ? "block"
                              : "none",
                            border: "none",
                          }}
                          className="OAS-border-mid-div"
                        ></div>
                      </>
                    )}

                    {additionalDetailCol.includes("name") && (
                      <p className="OAS-label-div-narr">{statement.name}</p>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className={`absolute top-auto overflow-x-auto flex flex-col h-[50vh] items-center justify-center ${
                props?.popType == "ownerStatement" ? "w-[96vw]" : "w-screen"
              }`}
            >
              <img
                src={noaccount}
                alt="No accounts found"
                className="statement_no_found"
              />
              <p className="select_text">No data found.</p>
              <div
                style={{
                  opacity:
                    filterOpen || showAdditionalDetails || showCalendar
                      ? "0.2"
                      : "1",
                  pointerEvents:
                    filterOpen || showAdditionalDetails || showCalendar
                      ? "none"
                      : "auto",
                  display:
                    accountNumber.length !== accountDefaultNumber.length ||
                    (new Date(startDate).setHours(0, 0, 0, 0) !==
                      new Date(new Date().setDate(1)).setHours(0, 0, 0, 0) &&
                      new Date(endDate).setHours(0, 0, 0, 0) !==
                        new Date().setHours(0, 0, 0, 0)) ||
                    selectedtBank.length > 0 ||
                    statementType.length > 0 ||
                    (amountLimit.min.length > 0 && amountLimit.max.length > 0)
                      ? "flex"
                      : "none",
                }}
                className="oas-selectaccount min-h-[36px]"
                onClick={() => {
                  move();
                  setAccountNumber(accountDefaultNumber);
                  setaccountnumberstate(accountDefaultNumber);
                  setSelectedtBank("");
                  setSelectedtBankState("");
                  setAmountLimit({ min: "", max: "" });
                  setAmountLimitState({ min: "", max: "" });
                  setStatementType("");
                  setStatementTypeState("");
                  setIsAmtFilterApplied(false);
                  setStartDate(new Date(new Date().setDate(1)));
                  setStartDateState(new Date(new Date().setDate(1)));
                  setEndDate(new Date());
                  setEndDateState(new Date());
                  setIsDateConfirm(false);
                  setCurrentPage(1);
                }}
              >
                <p className="oas-selectaccounttext">Clear parameters</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OwnerAllStatement;
