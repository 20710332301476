import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";

const FullScreen = ({ id, setAlertPopUp, type }) => {
  const navigate = useNavigate();

  const detailPage = (id) => {
    if (type === "txn") {
      navigate(`/transaction/beneficiary_transaction/details/${id}`);
    } else {
      navigate(`/accounts/beneficiary_account/details/${id}`);
    }
  };
  return (
    <div className="w-[35rem] h-auto rounded-[1.25rem] bg-Blue_3 p-[1.25rem] flex flex-col gap-[1rem] mx-[0.625rem] slideIn">
      <div className="mb-[3.125rem]">
        <h2 className="text-Header_orange font-semibold text-xl">
          Open in full view?
        </h2>
        <p className="text-Primary_white mt-[0.3125rem]">
          This will exit you from the current flow.
        </p>
      </div>
      <div className="flex justify-end">
        <button
          className="text-Primary_white underline text-sm hover:text-Primary_grey"
          onClick={() => setAlertPopUp(false)}
        >
          Cancel
        </button>
        <button
          className="text-Primary_white px-[0.125rem] bg-Button_green ml-5 rounded-2xl w-[4.375rem] h-[2rem]  hover:bg-Button_green_hover text-sm"
          onClick={() => detailPage(id)}
        >
          Open
        </button>
      </div>
    </div>
  );
};

export default FullScreen;
