import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Pagination from "@mui/material/Pagination";
import Arrow from "../../../assets/ArrowsIcons/arrow_down_white.svg";
import CheckIcon from "../../../assets/CheckmarksIcons/check_mark_white.svg";
import { useDispatch, useSelector } from "react-redux";

const PaginationComponent = ({
  selectedValue,
  handleSelect,
  data,
  dropdownOptions,
  handlePagination,
  currentPage,
  typepop,
  flowType,
  handleRejectAllTransaction,
  accountType,
  totalCount,
}) => {
  const [isPerPageOpen, setIsPerPageOpen] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const dropdownRef = useRef(null);

  const handleChange = (e, page) => {
    handlePagination(page);
  };

  useEffect(() => {
    const handleDropDown = (e) => {
      if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      } else {
        setIsPerPageOpen(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [dropdownRef]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div
        style={{
          height: typepop === "RejectAllTransactionPopup" ? "102px" : "50px",
        }}
        className="flex flex-col"
      >
        <div
          className="w-auto max-w-[1024px] mx-auto flex justify-between h-[50px]"
          style={{
            width: "100%",
            padding: isMobile ? "0 2px" : "0 10px",
            flexDirection: isMobile ? "column" : "",
            alignItems: isMobile ? "center" : "",
          }}
        >
          {!isMobile && (
            <div className="flex min-w-[132px]">
              <p
                style={{
                  height: "16px",
                  width: "76px",
                  margin: "auto 0",
                  color: "var(--Primary_grey)",
                  fontSize: "12px",
                }}
              >
                Per page :
              </p>
              <div
                ref={dropdownRef}
                style={{
                  height: "26px",
                  width: "56px",
                  borderRadius: "5px",
                  margin: "auto 0",
                  position: "relative",
                  cursor: "pointer",
                }}
                className="statement-type"
              >
                <div
                  className="owner-main-btn"
                  onClick={() => {
                    setIsPerPageOpen(!isPerPageOpen);
                  }}
                  style={{
                    position: isPerPageOpen ? "absolute" : "relative",
                    bottom: "0",
                    borderRadius: "5px",
                    height: isPerPageOpen ? "95px" : "26px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "56px",
                      top: isPerPageOpen ? "0px" : "36px",
                    }}
                  ></div>
                  <div
                    style={{
                      padding: "5px",
                      top: isPerPageOpen ? "0px" : "30px",
                      width: "56px",
                    }}
                    className="bank_high_to_low_select_scroll"
                  >
                    {dropdownOptions.map((value) => (
                      <div
                        key={value}
                        style={{
                          fontWeight: "600",
                          width: "47px",
                          backgroundColor:
                            selectedValue == value && "var(--Blue_4)",
                        }}
                        className="owner_select_scroll_com"
                        onClick={() => handleSelect(value)}
                      >
                        {value}
                        <div
                          style={{
                            width: "56px",
                            display: selectedValue == value ? "flex" : "none",
                            textAlign: "right",
                            justifyContent: "end",
                            paddingRight: "1px",
                          }}
                        >
                          <img
                            style={{
                              height: "13px",
                              width: "11px",
                            }}
                            src={CheckIcon}
                            alt="check"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      height: "26px",
                      width: "56px",
                      backgroundColor: "var(--Blue_8)",
                      bottom: "0px",
                      top: "auto",
                    }}
                    className="bank_high_to_low_select_select"
                  >
                    <span
                      style={{
                        color: "var(--Primary_white)",
                        fontSize: "12px",
                        paddingLeft: "8px",
                        fontWeight: "600",
                      }}
                    >
                      {selectedValue}
                    </span>
                    <img
                      src={Arrow}
                      alt="Status Icon"
                      style={{
                        height: "12px",
                        width: "12px",
                        transform: isPerPageOpen
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      }}
                      className="status_icon-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="my-auto min-w-[355px] justify-center items-center flex"
            style={{ paddingTop: isMobile ? "4px" : "" }}
          >
            <Pagination
              sx={{ margin: "0 2px" }}
              size="small"
              color="primary"
              count={data?.title?.total_pages || 1}
              shape="rounded"
              page={Number(currentPage) || 1}
              onChange={handleChange}
              siblingCount={isMobile ? 0 : 1}
            />
          </div>
          <div
            className={`w-auto my-auto min-w-[150px] ${
              isMobile ? "text-center" : "text-right"
            }`}
          >
            <p
              style={{ fontSize: "12px", fontWeight: "600" }}
              className="DataCount_text"
            >
              {data?.title?.page_item_count * data?.title?.current_page -
                data?.title?.page_item_count +
                1 || 0}{" "}
              -{" "}
              {Math.min(
                accountType === "internal" && data?.total_count > 0
                  ? totalCount
                  : data?.total_count,
                data?.title?.page_item_count * data?.title?.current_page
              ) || 0}{" "}
              of{" "}
              {accountType === "internal" && data?.total_count > 0
                ? totalCount?.toLocaleString()
                : data?.total_count?.toLocaleString() || 0}
            </p>
          </div>
        </div>
        <div
          className="h-[52px] bg-[#506994]"
          style={{
            display: typepop === "RejectAllTransactionPopup" ? "flex" : "none",
            width: "100%",
            borderRadius: "0 0 20px 20px",
          }}
        >
          <div className="flex flex-row justify-between items-center px-5 h-full w-full">
            <p className="text-sm text-[#F9F9F9] font-sans font-normal leading-[19.07px]">
              {typepop === "RejectAllTransactionPopup" &&
              (flowType === "decline_account_require_approval_flow" ||
                flowType === "decline account details") &&
              services?.find((service) => service === "ABeneficiaryTxn") !==
                undefined
                ? 'To reject the account, please reject the transactions listed above by clicking the "Reject all" button.'
                : typepop === "RejectAllTransactionPopup" &&
                  services?.find((service) => service === "ABeneficiaryTxn") ===
                    undefined
                ? "To reject the transactions, please contact the authorised person who has the feature to reject the transactions."
                : 'To archive the account, please reject the transactions listed above by clicking the "Reject all" button.'}
            </p>
            <div
              className={`w-[85px] h-[32px] rounded-[75px] ${
                services?.find((service) => service === "ABeneficiaryTxn") !==
                undefined
                  ? "cursor-pointer bg-[#69B76F] hover:bg-[#87C58C]"
                  : "cursor-default bg-[#707070]"
              }`}
              onClick={() => {
                if (
                  services?.find((service) => service === "ABeneficiaryTxn") !==
                  undefined
                ) {
                  handleRejectAllTransaction();
                }
              }}
            >
              <p className="py-2 px-4 text-xs text-[#F9F9F9] text-center font-sans font-normal leading-[16.34px]">
                Reject all
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginationComponent;
