import React, { useEffect, useRef, useState } from "react";
import "./navbar.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../src/redux/features/Settings/Authorization/index.js";
import { setCurrentMenu } from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { setCurrentMenum } from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import {
  AppBar,
  Box,
  Button,
  Container,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowUpBlue from "../../assets/ArrowsIcons/arrow_up_blue.svg";
import ArrowDown from "../../assets/ArrowsIcons/arrow_down_blue.svg";
import ArrowUpWhite from "../../assets/ArrowsIcons/arrow_up_white.svg";
import ArrowDownWhite from "../../assets/ArrowsIcons/arrow_down_white.svg";
import AccountUnselect from "../../assets/newdashboardicon/account_unselected.png";
import AccountSelect from "../../assets/newdashboardicon/account_selected.png";
import CustomizedBreadcrumbs from "./CustomizedBreadcrumbs";
import HomeUnselect from "../../assets/newdashboardicon/home_unselected.png";
import HomeSelect from "../../assets/newdashboardicon/home_selected.png";
import NavbarMenuPopover from "./NavbarMenuPopover";
import MobileViewPopover from "./NavbarMenuPopover/MobileViewPopover";
import XpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import UserProfile from "../_utils/userProfile/userprofile";
import Profile from "../../assets/UserProfileIcons/profile_icon_of_navigationbar.svg";
import TransactionUnselect from "../../assets/newdashboardicon/transaction_unselected.png";
import TransactionSelect from "../../assets/newdashboardicon/transaction_selected.png";
import ProfileUnselect from "../../assets/newdashboardicon/profile_unselected.png";
import ProfileSelect from "../../assets/newdashboardicon/profile_selected.png";

const pages = ["Home", "Transaction", "Accounts"];

const pagesm = ["Home", "Transaction", "Accounts", "Profile"];

const transactions = [
  {
    title: "Debit Transaction",
    menuItems: [
      {
        icon: "beneficiary_transaction_with_blue_circle.svg",
        label: "Beneficiary Transaction",
        labelm: "Beneficiary",
        labela: "Transaction",
        route: "beneficiary_transaction_dashboard",
        menu: "Transaction",
      },
    ],
  },
  {
    title: "Corporate Transaction",
    menuItems: [
      {
        icon: "internal_transaction_with_blue_circle.svg",
        label: "Internal Transaction",
        labelm: "Internal",
        labela: "Transaction",
        route: "transaction/internal_transaction/dashboard",
        menu: "Transaction",
      },
    ],
  },
];

const initial = [
  {
    title: "",
    menuItems: [
      {
        icon: "",
        label: "",
        labelm: "",
        labela: "",
        route: "",
        menu: "",
      },
    ],
  },
];
const accounts = [
  {
    title: "Debit Accounts",
    menuItems: [
      // {
      //   icon: "Vendor.png",
      //   label: "Vendor Accounts",
      //   route: "accounts/vendor/dashboard",
      //       menu: 'Accounts'
      // },
      {
        icon: "beneficiary_account_with_blue_circle.svg",
        label: "Beneficiary Accounts",
        labelm: "Beneficiary",
        labela: "Accounts",
        route: "accounts/beneficiary/dashboard",
        menu: "Accounts",
      },
    ],
  },
  {
    title: "Corporate accounts",
    menuItems: [
      {
        icon: "internal_transaction_with_blue_circle.svg",
        label: "Internal Accounts",
        labelm: "Internal",
        labela: "Accounts",
        route: "accounts/internal_account/dashboard",
        menu: "Accounts",
      },
      {
        icon: "owner_accounts_with_blue_circle.svg",
        label: "Owner Accounts",
        labelm: "Owner",
        labela: "Accounts",
        route: "accounts/owner/dashboard",
        menu: "Accounts",
      },
      // {
      //   icon: "Vendor.png",
      //   label: "Cards Accounts",
      //   route: "accounts/cards/dashboard",
      //       menu: 'Accounts'
      // },
    ],
  },
  // {
  //   title: "",
  //   menuItems: [
  //     {
  //       icon: "Internal.png",
  //       label: "Owner Accounts",
  //       labelm: "Owner",
  //       labela: "Accounts",
  //       route: "accounts/owner/dashboard",
  //       menu: "Accounts",
  //     },
  //     // {
  //     //   icon: "Vendor.png",
  //     //   label: "Cards Accounts",
  //     //   route: "accounts/cards/dashboard",
  //     //       menu: 'Accounts'
  //     // },
  //   ],
  // },

  // {
  //   title: "Employee Accounts",
  //   menuItems: [
  //     {
  //       icon: "Field force.png",
  //       label: "Field Force Accounts",
  //       route: "accounts/field_force/dashboard",
  //       menu: 'Accounts'
  //     },
  //     {
  //       icon: "Reimbursement.png",
  //       label: "Reimbursement Accounts",
  //       route: "reimbursement_accounts",
  //       menu: 'Accounts'
  //     },
  //   ],
  // },
  // {
  //   title: "Credit Accounts",
  //   menuItems: [
  //     {
  //       icon: "Client.png",
  //       label: "Client Accounts",
  //       route: "accounts/client/dashboard",
  //       menu: 'Accounts'
  //     },
  //   ],
  // },
];
const appBarPathsArr = [
  "/home",
  // "/beneficiary_transactions",
  "/transaction/internal_transaction/dashboard",
  "/accounts/beneficiary/dashboard",
  "/accounts/internal_account/dashboard",
  "/accounts/owner/dashboard",
  "/beneficiary_transaction_dashboard",
];

function ResponsiveAppBar() {
  const appBarRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [zIndex, setZIndex] = useState(5);
  const [isMobile, setIsMobile] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openMobilePopover, setOpenMobilePopover] = useState(false);
  const [rightPosition, setRightPosition] = useState("10%");
  const services = useSelector((state) => state.refreshToken.services);
  const hideBreadCrumb = useSelector((state) => state.hideBreadCrumb.hide);
  const currentmenu = useSelector((state) => state.hideBreadCrumb.menu);
  const [arrowUp, setArrowUp] = useState({
    Transaction: false,
    Accounts: false,
    Home: true,
  });
  const [arrowUpm, setArrowUpm] = useState({
    Transaction: false,
    Accounts: false,
    Home: true,
    Profile: false,
  });

  const handleClick = () => {
    setZIndex((prevZIndex) => (prevZIndex === 8 ? 5 : 5));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (appBarRef.current && !appBarRef.current.contains(event.target)) {
        setOpenPopover(false);
        setOpenMobilePopover(false);
        setArrowUp({
          Transaction: false,
          Accounts: false,
          Home: false,
        });
        setArrowUpm({
          Transaction: false,
          Accounts: false,
          Home: false,
          Profile: false,
        });
      }
      setZIndex((prevZIndex) => (prevZIndex === 8 ? 5 : 5));
    };

    document.addEventListener("mousedown", handleClickOutside);

    if (openMobilePopover) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [appBarRef, openMobilePopover]);

  useEffect(() => {
    if (openProfile) {
      setOpenProfile(false);
    }
    let menu = location.pathname.split("/")[1];
    if (menu == "accounts") {
      dispatch(setCurrentMenu("Accounts"));
    } else if (menu == "beneficiary_transactions" || menu == "transactions") {
      dispatch(setCurrentMenu("Transaction"));
    } else {
      dispatch(setCurrentMenu("Home"));
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   let menu = location.pathname.split("/")[1];
  //   if (menu == "accounts") {
  //     dispatch(setCurrentMenum("Accounts"));
  //   } else if (menu == "beneficiary_transactions" || menu == "transaction") {
  //     dispatch(setCurrentMenum("Transactions"));
  //   } else if (menu == "settings") {
  //     dispatch(setCurrentMenum("Profile"));
  //   } else {
  //     dispatch(setCurrentMenum("Home")) || dispatch(setCurrentMenum("Profile"));
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    if (arrowUp.Transaction || arrowUp.Accounts) {
      setOpenPopover(true);
    } else {
      setOpenPopover(false);
    }
  }, [arrowUp.Transaction, arrowUp.Accounts]);

  useEffect(() => {
    if (arrowUpm.Transaction || arrowUpm.Accounts) {
      setOpenMobilePopover(true);
    } else {
      setOpenMobilePopover(false);
    }
  }, [arrowUpm]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setOpenPopover(false);
    setOpenMobilePopover(true);
    setAnchorElNav(null);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
    setOpenMobilePopover(false);
    setArrowUp({
      Transaction: false,
      Accounts: false,
      Home: true,
    });
    setArrowUpm({
      Transaction: false,
      Accounts: false,
      Home: true,
      Profile: false,
    });
  };

  // const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      setIsMobile(window.innerWidth < 801);

      if (width >= 3840 && height >= 1920) {
        setRightPosition("34%");
      } else if (width >= 2560 && height >= 1440) {
        setRightPosition("26%");
      } else if (width >= 2560 && height >= 1330) {
        setRightPosition("26%");
      } else {
        setRightPosition("10%");
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };

  const showBorderRadius = !(
    location.pathname.includes("success") ||
    location.pathname.includes("error") ||
    location.pathname.includes("declined")
  );
  useEffect(() => {
    let menu = location.pathname.split("/")[1];
    if (
      [
        "owner_accounts",
        "internal_accounts",
        "beneficiary_accounts",
        "accounts",
      ].includes(menu)
    ) {
      dispatch(setCurrentMenu("Accounts"));
      setArrowUp({ Transaction: false, Accounts: false });
    } else if (menu.includes("transaction")) {
      dispatch(setCurrentMenu("Transaction"));
      setArrowUp({ Transaction: false, Accounts: false });
    } else {
      dispatch(setCurrentMenu("Home"));
      setArrowUp({ Transaction: false, Accounts: false });
    }
  }, [location.pathname]);

  return (
    <>
      {!isMobile && (
        <AppBar
          ref={appBarRef}
          style={{
            background: "var(--Nav_background)",
            height: "65px",
            borderRadius: !showBorderRadius
              ? "0px 0px 20px 20px"
              : hideBreadCrumb
              ? "0px 0px 20px 20px"
              : "0px",
          }}
        >
          <Container className="mx-auto" sx={{ maxWidth: "1024px" }}>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                marginLeft="5%"
                component="a"
                onClick={() => {
                  navigate("/home");
                  dispatch(setCurrentMenu("Home"));
                }}
              >
                <img
                  className="cursor-pointer w-[10.12rem] h-[2.25rem]"
                  src={XpentraLogo}
                  alt="XpentraLogo"
                />
              </Typography>

              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              ></Typography>

              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  margin: { md: "0 10%" },
                }}
              >
                {pages.map((page) => (
                  <Link key={page} to={page === "Home" ? "/home" : "#"}>
                    <Button
                      onClick={() => {
                        if (page === "Home") {
                          dispatch(setCurrentMenu(page));
                          setArrowUp({ Transaction: false, Accounts: false });
                        } else if (page === "Transaction") {
                          setArrowUp((prev) => ({
                            ...prev,
                            Transaction: !prev.Transaction,
                            Accounts: false,
                          }));
                        } else if (page === "Accounts") {
                          setArrowUp((prev) => ({
                            ...prev,
                            Accounts: !prev.Accounts,
                            Transaction: false,
                          }));
                        } else {
                          dispatch(setCurrentMenu(page));
                          setArrowUp({ Transaction: false, Accounts: false });
                        }
                      }}
                      sx={{
                        display: "flex",
                        fontSize: "var(--Font_14)",
                        margin: "0 1.25rem",
                        height: "39px !important",
                        fontFamily: "Open Sans !important",
                        fontWeight: "var(--Font_semibold)",
                        borderRadius: "10px !important",
                        backgroundColor:
                          page === currentmenu ? "var(--Blue_6)" : "",
                        "&:hover": {
                          backgroundColor:
                            page === currentmenu ? "var(--Blue_6)" : "",
                        },
                        color:
                          page === currentmenu
                            ? "var(--Primary_white)"
                            : "var(--Blue_6)",
                        textTransform: "capitalize",
                      }}
                    >
                      {page}

                      {(page === "Transaction" || page === "Accounts") &&
                        (page === currentmenu ? (
                          arrowUp[page] ? (
                            <img
                              src={ArrowUpWhite}
                              className="w-[15px] h-[10px] ml-[5px]"
                            />
                          ) : (
                            <img
                              src={ArrowDownWhite}
                              className="w-[15px] h-[10px] ml-[5px]"
                            />
                          )
                        ) : arrowUp[page] ? (
                          <img
                            src={ArrowUpBlue}
                            className="w-[15px] h-[10px] ml-[5px]"
                          />
                        ) : (
                          <img
                            src={ArrowDown}
                            className="w-[15px] h-[10px] ml-[5px]"
                          />
                        ))}
                    </Button>
                  </Link>
                ))}
              </Box>
              <div
                className="SettingAndNotifications relative hover:cursor-pointer"
                aria-describedby={"profile"}
                onClick={() => setOpenProfile(!openProfile)}
              >
                <img
                  src={Profile}
                  alt="Profile"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
                {openProfile && (
                  <>
                    <div
                      className="fixed top-0 left-0 w-full h-full z-40 cursor-default"
                      onClick={() => {
                        setOpenProfile(false);
                      }}
                    ></div>
                    <div
                      className="w-full"
                      style={{
                        position: "absolute",
                        height: "100dvh",
                        // overflowY: "hidden",
                        // top: 0,
                        maxWidth: "1024px !important",
                        cursor: "default",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "var(--Blue_2)",
                          height: "auto",
                          width: "305px",
                          overflow: "hidden",
                          position: "absolute",
                          top: "-37px",
                          right: "-90px",
                          left: "auto",
                          zIndex: 1000,
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <UserProfile
                          setOpenProfile={setOpenProfile}
                          openProfile={openProfile}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* {services && (
                <Box
                  className="SettingAndNotifications"
                  onClick={handleClosePopover}
                >
                  <SettingAndNotifications />
                </Box>
              )} */}
            </Toolbar>
            {/* <Divider style={{ maxWidth: "1024px", margin: "0 auto" }} /> */}
          </Container>
          {!hideBreadCrumb ? (
            <Box
              className="desktopPopover hidden md:block"
              sx={{
                backgroundColor: "none",
              }}
            >
              <NavbarMenuPopover
                open={openPopover}
                setOpenPopover={setOpenPopover}
                setArrowUp={setArrowUp}
                type={
                  arrowUp["Transaction"]
                    ? "transactions"
                    : arrowUp["Accounts"]
                    ? "accounts"
                    : "initial"
                }
                data={
                  arrowUp["Transaction"]
                    ? transactions
                    : arrowUp["Accounts"]
                    ? accounts
                    : initial
                }
              />
              {!openPopover && showBorderRadius && (
                <Box
                  sx={{
                    backgroundColor: "var(--Nav_background)",
                    height: "40px",
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    borderRadius: "0 0px 100px 100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    position: "static",
                  }}
                >
                  <div className="w-full px-3">
                    <CustomizedBreadcrumbs />
                  </div>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              <NavbarMenuPopover
                open={openPopover}
                setOpenPopover={setOpenPopover}
                setArrowUp={setArrowUp}
                type={
                  arrowUp["Transaction"]
                    ? "transactions"
                    : arrowUp["Accounts"]
                    ? "accounts"
                    : "initial"
                }
                data={
                  arrowUp["Transaction"]
                    ? transactions
                    : arrowUp["Accounts"]
                    ? accounts
                    : initial
                }
              />
            </Box>
          )}
        </AppBar>
      )}
      {isMobile &&
        !window.location.pathname.includes("/feature_not_assigned") && (
          <>
            <AppBar
              ref={appBarRef}
              style={{
                background: "var(--Blue_7)",
                height: "auto",
                borderRadius: "40px 40px 0px 0px",
                top: "auto",
                bottom: "0px",
                zIndex: zIndex,
                display: appBarPathsArr.includes(window.location.pathname)
                  ? "block"
                  : "none",
              }}
            >
              <Container className="mobilmenumain">
                {pagesm.map((pagem) => (
                  <Link
                    key={pagem}
                    to={pagem === "Home" ? "/home" : "#"}
                    className="mobilmenu"
                  >
                    <div
                      onClick={() => {
                        if (pagem === "Home") {
                          dispatch(setCurrentMenum(pagem));
                        } else if (pagem === "Accounts") {
                          setArrowUpm({ ["Home"]: false });
                          setArrowUpm({ ["Transaction"]: false });
                          setArrowUpm({ ["Profile"]: false });
                          setArrowUpm({ [pagem]: !arrowUp[pagem] });
                          handleClick();
                        }
                        if (pagem === "Transaction") {
                          setArrowUpm({ ["Home"]: false });
                          setArrowUpm({ ["Accounts"]: false });
                          setArrowUpm({ ["Profile"]: false });
                          setArrowUpm({ [pagem]: !arrowUp[pagem] });
                          handleClick();
                        }

                        if (pagem === "Profile") {
                          // showToast();
                          // dispatch(setCurrentMenum(pagem));
                          navigate("/home/LogoutProfile");
                        }
                      }}
                    >
                      {pagem === "Home" ? (
                        pagem === currentmenu ? (
                          <img
                            width={"30%"}
                            src={HomeSelect}
                            alt=""
                            style={{ margin: "5px auto" }}
                          />
                        ) : (
                          <img
                            width={"30%"}
                            src={HomeUnselect}
                            alt=""
                            style={{ margin: "5px auto" }}
                          />
                        )
                      ) : null}
                      {pagem === "Transaction" ? (
                        pagem === currentmenu ? (
                          <img
                            width={"30%"}
                            src={TransactionSelect}
                            alt=""
                            style={{ margin: "5px auto" }}
                          />
                        ) : (
                          <img
                            width={"30%"}
                            src={TransactionUnselect}
                            alt=""
                            style={{ margin: "5px auto" }}
                          />
                        )
                      ) : null}
                      {pagem === "Accounts" ? (
                        pagem === currentmenu ? (
                          <img
                            width={"30%"}
                            src={AccountSelect}
                            alt=""
                            style={{ margin: "5px auto" }}
                          />
                        ) : (
                          <img
                            width={"30%"}
                            src={AccountUnselect}
                            alt=""
                            style={{ margin: "5px auto" }}
                          />
                        )
                      ) : null}
                      {pagem === "Profile" ? (
                        pagem === currentmenu ? (
                          <img
                            width={"30%"}
                            src={ProfileSelect}
                            alt=""
                            style={{ margin: "5px auto" }}
                          />
                        ) : (
                          <img
                            width={"30%"}
                            src={ProfileUnselect}
                            alt=""
                            style={{ margin: "5px auto" }}
                          />
                        )
                      ) : null}
                      {pagem}
                      {pagem === "Transaction" || pagem === "Accounts" ? (
                        arrowUpm[pagem] ? (
                          <></>
                        ) : (
                          <></>
                        )
                      ) : null}
                    </div>
                  </Link>
                ))}
              </Container>
              <Box className="mobilePopover">
                {openMobilePopover ? (
                  <MobileViewPopover
                    open={openMobilePopover}
                    setOpenMobilePopover={setOpenMobilePopover}
                    setArrowUpm={setArrowUpm}
                    data={arrowUpm["Transaction"] ? transactions : accounts}
                    showToast={showToast}
                  />
                ) : null}
              </Box>
            </AppBar>
          </>
        )}
    </>
  );
}
export default ResponsiveAppBar;
