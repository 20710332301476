import React, { useState, useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import {
  getAPIToken,
  setDeviceData,
} from "../../../redux/features/devices/devices";
import DeviceTokenPopup from "../../../components/MyDevices/DeviceToken";
import DeleteDevicePopup from "../../../components/MyDevices/DeleteDevicePopup";
import formatDate from "../../../utils/formatdate";
import Android from "../../../assets/MultiPlatformHubIcons/Android.svg";
import ArrowLeft from "../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import Apple from "../../../assets/MultiPlatformHubIcons/MacOs.svg";
import Chrome from "../../../assets/MultiPlatformHubIcons/Chrome.svg";
import DefaultPlatform from "../../../assets/MultiPlatformHubIcons/default_platform.svg";
import DeleteIcon from "../../../assets/DeleteIcons/delete_with_white_circle.svg";
import Firefox from "../../../assets/MultiPlatformHubIcons/MozillaFirefox.svg";
import GenerateToken from "../../../assets/GenerateToken/generate_token.svg";
import InternetExplorer from "../../../assets/MultiPlatformHubIcons/Explorer.svg";
import Safari from "../../../assets/MultiPlatformHubIcons/Safari.svg";

const MyDeviceDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [wantDeleted, setWantDeleted] = useState(false);
  const [isTokenGenerated, setIsTokenGenerated] = useState(false);
  const [tokenGeneratedFlag, setTokenGeneratedFlag] = useState(false);

  const locationData = location?.state?.ddata || {};
  const services = useSelector((state) => state.refreshToken.services);
  const loggedInDeviceFromState = useSelector(
    (state) => state?.devices?.deviceData?.loggedInUser
  );
  const deviceIdFromState = useSelector(
    (state) => state?.devices?.deviceData?.deviceId
  );
  const devicesDataFromState = useSelector(
    (state) => state?.devices?.deviceData?.devices
  );

  const deviceId = locationData.deviceId || deviceIdFromState;
  let devicesData = locationData.devices || devicesDataFromState;
  const loggedInDevice = locationData.loggedInUser || loggedInDeviceFromState;
  const currDevice = devicesData?.find((device) => device?.id === deviceId);

  useEffect(() => {
    if (!devicesDataFromState) {
      dispatch(setDeviceData(locationData));
    }

    if (location.state === undefined) {
      devicesData = deviceIdFromState;
    }
  }, [locationData, devicesDataFromState, dispatch]);

  useEffect(() => {
    const tokenGenerated = sessionStorage.getItem("tokenGenerated");

    if (tokenGenerated === "true") {
      setIsTokenGenerated(true);
    } else {
      setIsTokenGenerated(false);
    }
  }, [tokenGeneratedFlag]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.className === "device-content-page") {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isPopupOpen]);

  async function getToken() {
    try {
      const response = await dispatch(getAPIToken(loggedInDevice?.id));
      setToken(response.payload.device_details.q2p_token);
      sessionStorage.setItem("tokenGenerated", "true");
      setTokenGeneratedFlag((prev) => !prev);
      setIsTokenGenerated(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleTokenClick = () => {
    if (services && !services?.includes("GenerateAPIToken")) {
      navigate("/feature_not_assigned");
      return;
    }
    getToken();
    setIsPopupOpen(true);
  };

  const {
    id,
    browser_name,
    logged_in_flag,
    os_version,
    platform,
    created_by_name,
    logged_in_time,
    last_used,
    is_unauthorized,
    is_primary,
    model,
  } = currDevice || {};

  return (
    <div
      style={{
        overflowY: isPopupOpen || wantDeleted ? "hidden" : "",
        // height: "100vh",
      }}
    >
      {wantDeleted && services?.includes("DDevice") && (
        <DeleteDevicePopup close={setWantDeleted} deviceId={id} />
      )}
      {isPopupOpen && (
        <DeviceTokenPopup
          className="bg-opacity-80"
          token={token}
          onClose={setIsPopupOpen}
          isPopupOpen={isPopupOpen}
        />
      )}
      {loading ? (
        <div className="device-loader-container m-auto justify-center items-center flex"></div>
      ) : (
        <div className="device-content-page">
          <div
            className="lg:hidden fixed w-full top-0 bg-custom-lightBlue h-[56px] sm:h-16 flex items-center cursor-pointer"
            style={{ zIndex: 999 }}
          >
            <div onClick={() => navigate(-1)}>
              <img src={ArrowLeft} alt="Close" className="w-4 h-4 m-3" />
            </div>
            <h1 className="text-base flex-1 text-Primary_white ml-3">
              Device details
            </h1>
          </div>
          <div className="device-container">
            <div className="w-auto bg-custom-card md:pt-0">
              <div className="max-w-[1024px] mx-auto mt-[-2px] lg:pt-[33px] pt-0 sm:mt-0">
                <div className="flex items-center border-b border-Blue_3 lg:pt-0 md:pt-[32px] lg:mt-[-1px] mt-0">
                  <div className="flex-[9] flex flex-col md:flex-row">
                    <div className="border-b md:border-b-0 md:border-r border-Blue_3 flex flex-row md:flex-col justify-between md:w-1/2">
                      <div className="pl-[15px] py-[15px]">
                        <h4 className="truncate lowercase first-line:capitalize text-base text-Primary_white pb-1">
                          {browser_name || model}
                        </h4>
                        <h6 className="text-xs text-Primary_grey">Model</h6>
                      </div>
                      <div className="block md:hidden">
                        <div className="w-[74.8px] h-full p-[18.5px] bg-Primary_white flex flex-col items-center justify-center text-center">
                          <img
                            className="w-[30px] h-[30px]"
                            src={
                              currDevice?.platform === "android"
                                ? Android
                                : currDevice?.platform === "apple"
                                ? Apple
                                : currDevice?.browser_name === "Google Chrome"
                                ? Chrome
                                : currDevice?.browser_name === "Mozilla Firefox"
                                ? Firefox
                                : currDevice?.browser_name ===
                                  "Internet Explorer"
                                ? InternetExplorer
                                : currDevice?.browser_name === "Apple Safari"
                                ? Safari
                                : DefaultPlatform
                            }
                            alt="generate token"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col md:w-1/2 pl-[15px] py-[15px]">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={created_by_name}
                      >
                        <h4 className="truncate lowercase first-line:capitalize text-base text-Primary_white pb-1">
                          {created_by_name}
                        </h4>
                      </Tooltip>
                      <div className="text-xs text-Primary_grey">
                        Created by
                      </div>
                    </div>
                  </div>

                  <div className="justify-end flex-col md:flex-row items-center flex-[1] hidden md:justify-center md:flex">
                    <div className="w-[100px] h-[96px] bg-Primary_white flex flex-col items-center justify-center text-center py-[15px] px-2.5">
                      <img
                        className="w-[30px] h-[30px]"
                        src={
                          currDevice?.platform === "android"
                            ? Android
                            : currDevice?.platform === "apple"
                            ? Apple
                            : currDevice?.browser_name === "Google Chrome"
                            ? Chrome
                            : currDevice?.browser_name === "Mozilla Firefox"
                            ? Firefox
                            : currDevice?.browser_name === "Internet Explorer"
                            ? InternetExplorer
                            : currDevice?.browser_name === "Apple Safari"
                            ? Safari
                            : DefaultPlatform
                        }
                        alt="generate token"
                      />
                      <span className="text-Primary_black text-[10px] mt-1">
                        Current
                        <br />
                        device
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex items-center border-b border-Blue_3">
                  <div className="flex-[9] flex flex-col md:flex-row">
                    <div className="border-b md:border-b-0 md:border-r border-Blue_3 flex flex-row md:flex-col justify-between md:w-1/2">
                      <div className="pl-[15px] py-[15px]">
                        <h4 className="truncate lowercase first-line:capitalize text-base text-Primary_white pb-1">
                          {platform
                            ?.toLowerCase()
                            ?.split(" ")
                            ?.map(function (word) {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            ?.join(" ")}
                        </h4>
                        <h6 className="text-xs text-Primary_grey">Platform</h6>
                      </div>
                    </div>

                    <div className="flex flex-col md:w-1/2 pl-[15px] py-[15px]">
                      <h4 className="pr-2 text-base text-Primary_white pb-1">
                        {os_version ? os_version : "Not Available"}
                      </h4>
                      <h6 className="text-xs text-Primary_grey">
                        Operating System
                      </h6>
                    </div>
                  </div>
                  <div className="justify-end flex-col md:flex-row items-center flex-[1] hidden md:block">
                    <div className="w-full flex flex-col items-center justify-center text-center">
                      <div
                        onClick={
                          logged_in_flag === "N" || isTokenGenerated
                            ? undefined
                            : handleTokenClick
                        }
                        className={`device-token-div py-[15px] px-2.5 ${
                          logged_in_flag === "N" || isTokenGenerated
                            ? ""
                            : " hover:bg-Blue_7_hover"
                        }`}
                        style={{
                          cursor:
                            logged_in_flag === "N" || isTokenGenerated
                              ? "default"
                              : "cursor",
                        }}
                      >
                        <Box>
                          <button
                            className="device-token-btn flex justify-center px-2"
                            disabled={
                              logged_in_flag === "N" || isTokenGenerated
                                ? true
                                : false
                            }
                            style={{
                              opacity:
                                logged_in_flag === "N" || isTokenGenerated
                                  ? "0.1"
                                  : "0.9",
                              cursor:
                                logged_in_flag === "N" || isTokenGenerated
                                  ? "default"
                                  : "cursor",
                            }}
                          >
                            <img
                              className="device-token-img"
                              src={GenerateToken}
                              alt="generate token"
                            />
                            <p className="device-image-label text-Primary_white text-[10px] h-8/12 self-center antialiased truncate">
                              Generate <br />
                              Token
                            </p>
                          </button>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex-[9] flex flex-col md:flex-row">
                    <div className="border-b md:border-b-0 md:border-r border-Blue_3 flex flex-row md:flex-col justify-between md:w-1/2">
                      <div className="pl-[15px] py-[15px]">
                        <h4 className="truncate lowercase first-line:capitalize text-base text-Primary_white pb-1">
                          {formatDate(logged_in_time)}
                        </h4>
                        <h6 className="text-xs text-Primary_grey">
                          Last logged in at
                        </h6>
                      </div>
                    </div>

                    <div className="flex flex-col md:w-1/2 pl-[15px] py-[15px]">
                      <h4 className="truncate lowercase first-line:capitalize text-base text-Primary_white pb-1">
                        {formatDate(last_used)}
                      </h4>
                      <h6 className="text-xs text-Primary_grey">
                        Last used at
                      </h6>
                    </div>
                  </div>
                  <div className="justify-end flex-col md:flex-row items-center flex-[1] hidden md:block">
                    <div className="w-full flex flex-col items-center justify-center text-center cursor-pointer">
                      <div
                        onClick={() => {
                          if (!services?.includes("DDevice")) {
                            navigate("/feature_not_assigned");
                            return;
                          }
                          setWantDeleted(true);
                        }}
                        className={`device-deleteDevice-div py-[15px] px-2.5 ${
                          services?.includes("DDevice") &&
                          "hover:bg-Blue_7_hover"
                        }`}
                        style={{
                          cursor: services?.includes("DDevice")
                            ? "pointer"
                            : "default",
                        }}
                      >
                        <Box>
                          <button
                            style={{
                              opacity: services?.includes("DDevice")
                                ? "0.7"
                                : "0.1",
                              cursor: services?.includes("DDevice")
                                ? "pointer"
                                : "default",
                            }}
                          >
                            <img
                              className="device-deleteDevice-img"
                              src={DeleteIcon}
                              alt="delete device"
                            />
                            <p className="device-image-label text-Primary_white text-[10px] h-8/12 self-center antialiased truncate">
                              Delete
                            </p>
                          </button>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="block md:hidden mt-5">
              <p className="bg-Blue_5 h-12 text-sm font-semibold text-Primary_white pl-[15px] flex items-center">
                Quick Actions
              </p>
              <div className="w-full bg-Blue_7 h-[90px] flex border-t border-Blue_3">
                <div
                  onClick={
                    logged_in_flag === "N" || isTokenGenerated
                      ? undefined
                      : handleTokenClick
                  }
                  className={`w-1/2 flex flex-col items-center justify-center text-center cursor-pointer py-4 ${
                    logged_in_flag === "N" || isTokenGenerated
                      ? ""
                      : "hover:bg-custom-cardHover"
                  }`}
                  style={{
                    cursor:
                      logged_in_flag === "N" || isTokenGenerated
                        ? "default"
                        : "pointer",
                  }}
                >
                  <button
                    className="device-token-btn flex flex-col items-center justify-center"
                    disabled={logged_in_flag === "N" || isTokenGenerated}
                    style={{
                      opacity:
                        logged_in_flag === "N" || isTokenGenerated
                          ? "0.1"
                          : "0.9",
                      cursor:
                        logged_in_flag === "N" || isTokenGenerated
                          ? "default"
                          : "pointer",
                    }}
                  >
                    <img
                      className="w-10 h-10 mb-1"
                      src={GenerateToken}
                      alt="generate token"
                    />
                    <p className="device-image-label text-Primary_white text-xs">
                      Generate <br /> Token
                    </p>
                  </button>
                </div>
                <div className="border-r border-Blue_3"></div>

                <div
                  onClick={() => {
                    if (!services?.includes("DDevice")) {
                      navigate("/feature_not_assigned");
                      return;
                    }
                    setWantDeleted(true);
                  }}
                  className={`w-1/2 flex flex-col items-center justify-center text-center cursor-pointer py-4 ${
                    services?.includes("DDevice") && "hover:bg-custom-cardHover"
                  }`}
                  style={{
                    cursor: services?.includes("DDevice")
                      ? "pointer"
                      : "default",
                  }}
                >
                  <button
                    disabled={!services?.includes("DDevice")}
                    style={{
                      opacity: services?.includes("DDevice") ? "0.7" : "0.1",
                      cursor: services?.includes("DDevice")
                        ? "pointer"
                        : "default",
                    }}
                    className="flex flex-col items-center justify-center"
                  >
                    <img
                      className="w-10 h-10 mb-1"
                      src={DeleteIcon}
                      alt="delete device"
                    />
                    <p className="device-image-label text-Primary_white text-xs">
                      Delete
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div className="device-details-div-section w-screen">
              <div className="device-details-section Qlg:px-0 md:px-5 px-5">
                <button className="device-details-heading text-white">
                  Details
                </button>
                <div className="device-addDetails-div">
                  <div className="device-detail-card w-1/4">
                    <h4 className="device-title1">Login details</h4>
                    <div className="flex flex-row justify-between">
                      <div className="device-data-div">Logged out</div>
                    </div>

                    <div className="device-status-div pb-3 border-b border-solid border-Blue_3">
                      Login status
                    </div>

                    <div className="w-50 h-50 device-acc-type-section ">
                      <div className="data-div">
                        {is_primary ? "Primary" : "Secondary"}
                      </div>
                      <div className="device-status-div pb-3 border-b border-solid border-Blue_3">
                        Device priority
                      </div>
                    </div>
                    <div className="w-50 h-50 device-acc-type-section">
                      <div className="data-div">
                        {is_unauthorized ? "Unauthorized" : "Authorized"}
                      </div>
                      <div className="device-status-div">
                        Device authorization
                      </div>
                    </div>
                  </div>
                  {platform === "android" && (
                    <div className="device-detail-card w-1/4">
                      <h4 className="title1">Device details</h4>
                      <div className="flex flex-row justify-between">
                        <div className="device-data-div">
                          {currDevice?.manufacturer}
                        </div>
                      </div>

                      <div className="device-status-div pb-3 border-b border-solid border-Blue_3">
                        Manufacturer
                      </div>

                      <div className="w-50 h-50 device-acc-type-section ">
                        <div className="device-data-div">
                          {currDevice?.brand}
                        </div>
                        <div className="device-status-div pb-3 border-b border-solid border-Blue_3">
                          Brand
                        </div>
                      </div>
                      <div className="w-50 h-50 device-acc-type-section">
                        <div className="device-data-div">
                          {currDevice?.app_version_name}
                        </div>
                        <div className="device-status-div">App version</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyDeviceDetails;
