import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import noaccount from "../../../../assets/No transaction found.svg";
import ArrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowUp4 from "../../../../assets/ArrowsIcons/arrow_up_blue.svg";
import ArrowUp from "../../../../assets/ArrowsIcons/arrow_up_white.svg";
import FilterBlue from "../../../../assets/FilterSearchIcons/filter_blue.svg";
import Filter from "../../../../assets/FilterSearchIcons/filter.svg";
import clearFilter from "../../../../assets/CloseIcons/close_with_blue_circle.svg";
import SearchIcon from "../../../../assets/FilterSearchIcons/search_white.svg";
import SearchBlue from "../../../../assets/FilterSearchIcons/search_blue.svg";
import ArrowDown8 from "../../../../assets/ArrowsIcons/arrow_down_blue.svg";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import { getEntities } from "../../../../redux/features/Settings/Management/entities";
import { checkUsertoken } from "../../../../redux/features/login/login";
import PaginationComponent from "../../../../components/_utils/PaginationComponent";
import RectangleSearchBar from "../../../../components/_utils/RectangleSearchBar";
import Validation from "../../../../utils/validation";
import { useNavigate } from "react-router-dom";

const EntitiesViewAll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownOptions = [50, 100];
  const [totalCount, setTotalCount] = useState(false);
  const [entitiesDetails, setEntitiesDetails] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [entityIDState, setEntityIDState] = useState("");
  const [searchUserText, setSearchUserText] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const searchRef = useRef(null);
  const filterButtonRef = useRef(null);
  const filterRef = useRef(null);
  const applyBtnRef = useRef(null);
  const [filterOpen, setFilterOpen] = useState(null);
  const [enitityID, setEntityID] = useState("");
  const searchButtonRef = useRef(null);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const { isLoading } = useSelector((state) => state.entities);
  // const { userStoredPage, userStoredPagesize } = useSelector(
  //   (state) => state.users
  // );

  // useEffect(() => {
  //   if (Number(userStoredPage) > 1) {
  //     setCurrentPage(Number(userStoredPage));
  //   }
  //   if (Number(userStoredPagesize) > 50) {
  //     setSelectedValue(userStoredPagesize);
  //   }
  // }, []);

  const handlePaste = (e) => {
    e.preventDefault();
    setSearchUserText(e.target.value);
  };

  useEffect(() => {
    const handleDropDown = (e) => {
      if (searchRef.current && searchRef.current.contains(e.target)) {
      } else if (
        searchButtonRef.current &&
        searchButtonRef.current.contains(e.target)
      ) {
      } else {
        setShowSearchBar(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [searchRef]);

  const fetchData = async (val) => {
    let queryParams = {
      page_no: currentPage,
      page_size: pageSize,
      search_string: val ? "" : searchUserText,
      entity_id: enitityID,
    };

    try {
      dispatch(checkUsertoken());
      const response = await dispatch(getEntities(queryParams));
      const newData = response?.payload?.entities || [];

      setMainData(response?.payload || []);

      setTotalCount(response?.payload?.total_count);

      setEntitiesDetails(newData);
    } catch (error) {
      setMainData([]);

      setTotalCount(0);

      setEntitiesDetails([]);
    }
  };

  const handleInput = (e) => {
    let { name, value } = e.target;
    let val = value?.replaceAll(" ", "");
    if (Validation.allowedBankAccNum(val)) {
      setEntityIDState(value?.toUpperCase());
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
    if (event.key === "Enter") {
      applyBtnRef.current.click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchUserText, pageSize, currentPage, enitityID]);

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handlefilter = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
      } else if (
        filterButtonRef.current &&
        filterButtonRef.current.contains(e.target)
      ) {
      } else {
        setFilterOpen((prev) => (prev === null ? null : false));
      }
    };
    document.addEventListener("click", handlefilter);
  }, [filterRef]);

  return (
    <>
      {
        <div
          style={{
            zIndex: filterOpen ? "0" : "9999",
            width: "100%",
            bottom: "0",
          }}
          className="fixed w-full bg-Blue_4 h-[50px]"
        >
          <PaginationComponent
            handleSelect={handleSelect}
            selectedValue={selectedValue}
            data={mainData}
            pageSize={pageSize}
            dropdownOptions={dropdownOptions}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
      }

      <div
        className={`bg-custom-appBack flex flex-col h-screen overflow-y-hidden`}
        style={{
          overflow: filterOpen && "hidden",
        }}
      >
        <div
          className={`entities_div_container
           ${showSearchBar ? "entities_div_height" : ""}`}
        >
          <div className={"entities_div"}>
            <div className="entities_allcontainers">
              <div
                onClick={() => {
                  if (filterOpen) {
                    setFilterOpen(false);
                  } else {
                    navigate(-1);
                  }
                }}
                className="entities_back_btn"
              >
                <img src={ArrowDown} alt="" />
              </div>
              <div className="flex flex-col w-[113px]">
                <div
                  style={{
                    color: "var(--Primary_white)",
                  }}
                  className="entities_no_of"
                >
                  {totalCount || "0"}
                </div>
                <div className="entities_views-counts">
                  <p>Total entities</p>
                </div>
              </div>
            </div>

            <div className="entities_btn_container">
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      zIndex: "2147483647 !important",
                    },
                  },
                }}
                title="Search"
              >
                <div
                  ref={searchButtonRef}
                  style={{
                    backgroundColor:
                      searchUserText.length > 0 && "var(--Primary_white)",
                    opacity: filterOpen ? "0.6" : "1",
                    cursor: filterOpen ? "default" : "pointer",
                  }}
                  className="entities_search_div hover:bg-custom-cardHover"
                  onClick={() => {
                    if (!filterOpen) {
                      setShowSearchBar(!showSearchBar);
                    }
                  }}
                >
                  <img
                    className="entitiessearch-images"
                    src={searchUserText.length > 0 ? SearchBlue : SearchIcon}
                    alt="search-image"
                  />
                  <img
                    style={{
                      transform: showSearchBar && "rotate(180deg)",
                    }}
                    src={searchUserText.length > 0 ? ArrowDown8 : ArrowDown}
                    alt=""
                    className="entitiesfilter-images"
                  />
                </div>
              </Tooltip>

              <div
                style={{ display: "flex" }}
                ref={filterButtonRef}
                onClick={(e) => {
                  if (!showSearchBar) {
                    setFilterOpen(filterOpen === null ? true : !filterOpen);
                  }
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                        zIndex: "2147483647 !important",
                      },
                    },
                  }}
                  title="Filters"
                >
                  <div
                    style={{
                      backgroundColor:
                        enitityID.length > 0 ? "var(--Primary_white)" : "",
                      opacity: showSearchBar ? "0.6" : "1",
                      cursor: showSearchBar ? "default" : "pointer",
                    }}
                    className={`entities-containers ${
                      enitityID.length == 0
                        ? "entities_containers_responsive"
                        : ""
                    } `}
                  >
                    <Box className="filters-box">
                      <img
                        src={enitityID.length > 0 ? FilterBlue : Filter}
                        alt=""
                        className="entitiesfilter-images"
                      />
                    </Box>
                    <Box className="filter-box">
                      <img
                        src={
                          filterOpen
                            ? enitityID.length > 0
                              ? ArrowUp4
                              : ArrowUp
                            : enitityID.length > 0
                            ? ArrowDown8
                            : ArrowDown
                        }
                        alt=""
                        className="entitiesfilter-images"
                      />
                    </Box>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            style={{ height: showSearchBar && "0px" }}
            className={`entities-filter-transition-${
              filterOpen == true
                ? "open"
                : filterOpen == false
                ? "close"
                : "default"
            }`}
          >
            <div ref={filterRef}>
              <div className="entities-owner_filterdropdown">
                <div className="entities_filterdropdown_first_div">
                  <div className="entities_filterdropdown_main_div">
                    <p
                      style={{
                        color: "var(--Failed)",
                        width: "100px",
                        height: "19px",
                        font: "normal normal 600 14px/19px Open Sans",
                        marginBottom: "20px",
                        paddingTop: "10px",
                      }}
                    >
                      Filter
                    </p>
                    <p
                      style={{
                        color: "var(--Primary_grey)",
                        height: "22px",
                        fontSize: "12px",
                        marginBottom: "5px",
                      }}
                    >
                      Entity ID
                    </p>
                    <div className="entities-owner_connected_bank cursor-pointer">
                      <input
                        maxLength={20}
                        className="entities_account_number_text_input"
                        type="text"
                        onChange={handleInput}
                        value={entityIDState.toUpperCase()}
                        name="entityid"
                        placeholder="Enter entity ID"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>

                  <div className="entities-buttoncontainer entitiesfilter">
                    <div
                      style={{
                        cursor:
                          entityIDState?.length > 0 || enitityID.length > 0
                            ? "pointer"
                            : "default",
                        opacity:
                          entityIDState?.length > 0 || enitityID.length > 0
                            ? "1"
                            : "0.6",
                      }}
                      onClick={() => {
                        if (entityIDState?.length > 0 || enitityID.length > 0) {
                          setEntityID("");
                          setEntityIDState("");
                          setCurrentPage(1);
                        }
                      }}
                      className={`entities-clear ${
                        entityIDState?.length > 0 || enitityID.length > 0
                          ? "hover:bg-Blue_6_hover"
                          : ""
                      }`}
                    >
                      <p className="entities-buttoncontainer-text">Clear</p>
                    </div>
                    <div
                      ref={applyBtnRef}
                      onClick={() => {
                        if (entityIDState?.length > 0 || enitityID.length > 0) {
                          setEntityID(entityIDState);

                          setCurrentPage(1);

                          setFilterOpen(false);
                        }
                      }}
                      className={`entities-apply ${
                        entityIDState?.length > 0 || enitityID.length > 0
                          ? "bg-Failed hover:bg-Failed_hover"
                          : "bg-Secondary_grey"
                      }`}
                      style={{
                        cursor:
                          entityIDState?.length > 0 || enitityID.length > 0
                            ? "pointer"
                            : "default",

                        opacity:
                          entityIDState?.length > 0 || enitityID.length > 0
                            ? "1"
                            : "0.6",
                      }}
                    >
                      <p className="entities-buttoncontainer-text">Apply</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mx-auto">
            {showSearchBar && (
              <RectangleSearchBar
                handleSearch={setSearchUserText}
                showSearchBar={showSearchBar}
                searchUserText={searchUserText}
                setSearchUserText={setSearchUserText}
                onPaste={handlePaste}
                toggleShowSearchBar={() => {
                  setFilterOpen(false);
                  setShowSearchBar(!showSearchBar);
                }}
                bgColor={"bg-Blue_3"}
                bgHover={"hover:bg-custom-cardHover"}
                setCurrentPage={setCurrentPage}
                searchRef={searchRef}
                placeholder="Search by name/address/PAN number"
              />
            )}
          </div>
        </div>
        {enitityID?.length > 0 && (
          <div className="entities_applied_div w-full">
            <div
              style={{
                borderTop: "1px solid var(--Blue_1)",
              }}
              className="w-full max-w-[1024px] mx-auto py-[10px] flex"
            >
              <p className="entitesfilterby_text">Filtered by :</p>
              <div className="entitiesfilter_badge_div">
                {enitityID?.length > 0 && (
                  <div className="entitiesfilter_badge">
                    <p className="entitiesfilter_badge_text">{enitityID}</p>
                    <img
                      onClick={() => {
                        setEntityID("");
                        setEntityIDState("");
                      }}
                      className="entitiesfilter_badge_img"
                      src={clearFilter}
                      alt="X"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {isLoading ? (
          <>
            <div className="loader-container m-auto justify-center items-center flex loader-height">
              <img src={dataLoading} alt="" />
            </div>
          </>
        ) : entitiesDetails.length != 0 ? (
          <>
            <div
              className={
                filterOpen
                  ? "overflow-y-hidden h-screen entitiesviewallscroll opacity-80  bg-black mt-[5px]"
                  : "overflow-y-auto h-screen mb-[50px] entitiesviewallscroll mt-[5px]"
              }
            >
              <div
                className={`${
                  filterOpen &&
                  "bg-black opacity-10 z-[-99999] pointer-events-none "
                } entitiesCard`}
              >
                {entitiesDetails.map((userDtls) => {
                  return (
                    <div
                      key={userDtls?.id}
                      className="entities-details-card 
                          bg-Blue_6 hover:bg-Blue_6_hover"
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={userDtls.entity_name}
                      >
                        <div className="w-[215px] flex justify-between">
                          <div className="entities-name truncate">
                            {userDtls.entity_name}
                          </div>
                        </div>
                      </Tooltip>
                      <div className="entities-id">{userDtls.entity_pan}</div>
                      <div className="entities-label flex justify-between">
                        PAN number
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={
                filterOpen
                  ? `h-full w-full bg-black opacity-80 flex flex-col items-center justify-center m-auto`
                  : "flex flex-col items-center justify-center m-auto"
              }
            >
              <img
                src={noaccount}
                alt="No accounts found"
                className={filterOpen ? "no-acc-image-opacity" : "no-acc-image"}
              />
              <p
                className={
                  filterOpen
                    ? "no-owneraccount-text-opacity"
                    : "no-owneraccount-text"
                }
              >
                No data found.
              </p>
              <div
                style={{
                  display:
                    enitityID?.length > 0 || searchUserText.length > 0
                      ? "flex"
                      : "none",
                }}
                className="oas-selectaccount min-h-[36px]"
                onClick={() => {
                  setSearchUserText("");
                  setEntityID("");
                  setEntityIDState("");
                  setCurrentPage(1);
                }}
              >
                <p className="oas-selectaccounttext">Clear parameters</p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EntitiesViewAll;
