import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../../Authorization";
import axios from "axios";
import RefreshBalanceToast from "../../../../../components/_utils/RefreshBalanceToast";

const baseUrl = env.REACT_APP_BASE_URL;

export const getInvoices = createAsyncThunk(
  "getInvoices",
  async (values, { rejectWithValue }) => {
    const { page_no, page_size } = values;
    try {
      const response = await axios.get(`${baseUrl}/billing/invoices`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      return rejectWithValue(error);
    }
  }
);

export const downloadInvoice = createAsyncThunk(
  "downloadInvoice",
  async (id) => {
    try {
      const response = await fetch(
        `${baseUrl}/billing/${id}/download_invoice.pdf`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      let blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `statement.pdf`);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return true;
    } catch (error) {
      console.error("Download error:", error);
      throw error;
    }
  }
);

export const invoiceId = createAsyncThunk("invoiceId", async (id) => {
  try {
    const response = await fetch(`${baseUrl}/billing/${id}/invoice`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    });

    return response.json();
  } catch (error) {
    toast.error(error.response.data.error, toast_position);
    console.error("Error", error);
    throw error;
  }
});

const invoicesSlice = createSlice({
  name: "invoices",
  initialState: {
    invoiceData: [],
    isLoading: true,
    failData: null,

    specificData: [],
    specificLoader: true,
    specificFail: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.invoiceData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getInvoices.rejected, (state, action) => {
      state.invoiceData = null;
      state.isLoading = false;
      state.failData = action.payload;
    });
    builder.addCase(getInvoices.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(invoiceId.fulfilled, (state, action) => {
      state.specificData = action.payload;
      state.specificLoader = false;
    });
    builder.addCase(invoiceId.rejected, (state, action) => {
      state.specificData = null;
      state.specificLoader = false;
      state.specificFail = action.payload;
    });
    builder.addCase(invoiceId.pending, (state, action) => {
      state.specificLoader = true;
    });

    builder.addCase(downloadInvoice.pending, () => {
      toast.success("Download Started Successfully", toast_position);
    });
  },
});

export default invoicesSlice.reducer;
