import React, { useEffect, useState } from "react";
import "./index.css";
import close from "../../../assets/CloseIcons/close_with_blue_square.svg";
import refresh from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import download from "../../../assets/GeneralIcons/download_statement.svg";
import loader from "../../../assets/LoaderIcons/Loader.gif";
import view_statement from "../../../assets/ViewIcons/view_statement.svg";
import PaginationComponent from "../../../components/_utils/PaginationComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadInvoice,
  getInvoices,
  invoiceId,
} from "../../../redux/features/Settings/Management/Invoices";
import { checkUsertoken } from "../../../redux/features/login/login";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import AmountToWords from "../../../components/_utils/AmountToWords";
import Loader from "../../../components/Loader/Index";

const InvoicesDetail = () => {
  const dropdownOptions = [50, 100];
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [viewStatement, setViewStatement] = useState(false);

  const { failData, isLoading, invoiceData, specificData } = useSelector(
    (state) => state.invoicesSlice
  );

  const singleStatement = (id) => {
    setViewStatement(true);
    dispatch(checkUsertoken());
    dispatch(invoiceId(id));
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPage(Number(currentPage));
    toggleDropdown();
  };

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getInvoices({ page_size: "50" }));
  }, [dispatch]);

  return (
    <>
      <div
        style={{
          zIndex: "9999",
          width: "100%",
          bottom: "0",
          borderRadius: "0",
        }}
        className="fixed w-full bg-[#244277] h-[50px]"
      >
        <PaginationComponent
          handleSelect={handleSelect}
          selectedValue={selectedValue}
          data={invoiceData}
          pageSize={pageSize}
          dropdownOptions={dropdownOptions}
          handlePagination={handlePagination}
          currentPage={currentPage}
        />
      </div>

      {viewStatement && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center bg-[#000000] justify-center z-[9999] bg-opacity-80 overflow-y-scroll pt-[17.813rem] pb-6">
          <div className="w-[52.188rem] h-auto rounded-[20px] bg-Blue_3 p-[20px] flex flex-col gap-[16px] mx-[10px]">
            <div>
              <div className="flex items-center justify-between h-[30px]">
                <div className="flex">
                  <p className="text-Font_16 font-sans font-semibold text-Primary_white mr-1">
                    Invoice no. - {specificData?.invoice?.invoice_number}
                  </p>
                  <p className="unpaid">Unpaid</p>
                </div>
                <img
                  className="w-[30px] h-[50px] cursor-pointer"
                  onClick={() => setViewStatement(false)}
                  src={close}
                  alt="Close"
                />
              </div>
              <div className="w-[49.938rem] h-auto border border-Blue_8 rounded-md mt-[1.313rem] mb-5">
                <div className="bg-Blue_6 rounded-md">
                  <div className="flex">
                    <div className="w-[50%] mt-4 mb-4 pl-6">
                      <div className="flex mb-1">
                        <p className="text-Font_14 text-Primary_grey">
                          Invoice Number :
                        </p>
                        <span className="text-Primary_white text-Font_14 pl-0.5">
                          {specificData?.invoice?.invoice_number}
                        </span>
                      </div>
                      <div className="flex mb-1">
                        <p className="text-Font_14 text-Primary_grey">Date :</p>
                        <span className="text-Primary_white text-Font_14 pl-0.5">
                          {specificData?.invoice?.invoice_date}
                        </span>
                      </div>
                      <div className="flex ">
                        <p className="text-Font_14 text-Primary_grey">
                          Mode/Terms of Payment :
                        </p>
                        <span className="text-Primary_white text-Font_14 pl-0.5">
                          {specificData?.invoice?.terms_of_payment}
                        </span>
                      </div>
                    </div>
                    <div className="w-[50%] border-l border-Blue_8 mt-4 mb-4  pl-6">
                      <div className="flex mb-1">
                        <p className="text-Font_14 text-Primary_grey">
                          Entity ID :
                        </p>
                        <span className="text-Primary_white text-Font_14 pl-0.5">
                          {specificData?.invoice?.entity_id}
                        </span>
                      </div>
                      <div className="flex mb-1">
                        <p className="text-Font_14 text-Primary_grey">
                          Start Date :
                        </p>
                        <span className="text-Primary_white text-Font_14 pl-0.5">
                          {specificData?.invoice?.start_date}
                        </span>
                      </div>
                      <div className="flex ">
                        <p className="text-Font_14 text-Primary_grey">
                          End Date :
                        </p>
                        <span className="text-Primary_white text-Font_14 pl-0.5">
                          {specificData?.invoice?.end_date}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="m-5">
                  <table
                    className="table-auto w-full border border-Blue_8 rounded-lg"
                    style={{ borderCollapse: "separate", borderSpacing: 0 }}
                  >
                    <thead className="bg-Blue_6 h-[30px] rounded-t-lg">
                      <tr className="text-Primary_white">
                        <th className="border-r border-Blue_8 text-sm rounded-tl-lg font-normal">
                          Sr. No
                        </th>
                        <th className="border-r border-Blue_8 text-sm font-normal">
                          Particulars
                        </th>
                        <th className="border-r border-Blue_8 text-sm font-normal">
                          No. of Accounts
                        </th>
                        <th className="border-r border-Blue_8 text-sm font-normal">
                          HSN/SAC
                        </th>
                        <th className="border-r border-Blue_8 text-sm font-normal">
                          Rate per A/C
                        </th>
                        <th className="border-r border-Blue_8 text-sm font-normal">
                          GST Rate
                        </th>
                        <th className="text-sm rounded-tr-lg font-normal">
                          Amount(₹){" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-Primary_white">
                      <tr className="text-sm">
                        <td className="border border-Blue_8 text-center">
                          <p>1</p>
                        </td>
                        <td className="border border-Blue_8 capitalize">
                          {specificData?.invoice?.items.map((items, index) => {
                            return (
                              <React.Fragment key={index}>
                                <p className="h-8  pt-2 pl-5">
                                  {items.description}
                                </p>
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td className="border border-Blue_8">
                          {specificData?.invoice?.items.map((items, index) => {
                            return (
                              <React.Fragment key={index}>
                                <p className="h-8 pt-2 text-center">
                                  {items.quantity}
                                </p>
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td className="border border-Blue_8">
                          {specificData?.invoice?.items.map((items, index) => {
                            return (
                              <React.Fragment key={index}>
                                <p className="h-8 pt-2 text-center">
                                  {items.hsn}
                                </p>
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td className="border border-Blue_8">
                          {specificData?.invoice?.items.map((items, index) => {
                            return (
                              <React.Fragment key={index}>
                                <p className="h-8 pt-2 text-right pr-2.5">
                                  ₹ {items.unit_price}
                                </p>
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td className="border border-Blue_8"></td>
                        <td className="border border-Blue_8">
                          {specificData?.invoice?.items.map((items, index) => {
                            return (
                              <React.Fragment key={index}>
                                <p className="h-8 pt-2 text-right pr-2.5">
                                  ₹ {items.total}
                                </p>
                              </React.Fragment>
                            );
                          })}
                        </td>
                      </tr>
                      <tr className="text-sm">
                        <td className="border border-Blue_8 text-center">
                          <p>2</p>
                        </td>
                        <td className="border border-Blue_8">
                          {specificData?.invoice?.discounts.map(
                            (discount, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <p className="h-8 pt-2 pl-5">
                                    {discount.title}
                                  </p>
                                </React.Fragment>
                              );
                            }
                          )}
                        </td>
                        <td className="border border-Blue_8"></td>
                        <td className="border border-Blue_8"></td>
                        <td className="border border-Blue_8">
                          {specificData?.invoice?.discounts.map(
                            (discount, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <p className="h-8 pt-2 text-right pr-2.5">
                                    ₹ {discount.amount}
                                  </p>
                                </React.Fragment>
                              );
                            }
                          )}
                        </td>
                        <td className="border border-Blue_8"> </td>
                        <td className="border border-Blue_8">
                          {specificData?.invoice?.discounts.map(
                            (discount, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <p className="h-8 pt-2 text-right pr-2.5">
                                    ₹ {discount.amount}
                                  </p>
                                </React.Fragment>
                              );
                            }
                          )}
                        </td>
                      </tr>
                      <tr className="text-sm">
                        <td className="border border-Blue_8 text-center">
                          <p>3</p>
                        </td>
                        <td className="border border-Blue_8">
                          {specificData?.invoice?.taxes.map((tax, index) => {
                            return (
                              <React.Fragment key={index}>
                                <p className="h-8 pt-2 pl-5">
                                  {Object.keys(tax)[0].toLocaleUpperCase()}
                                </p>{" "}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td className="border border-Blue_8"></td>
                        <td className="border border-Blue_8"></td>
                        <td className="border border-Blue_8 text-right pr-2.5 pt-2 ">
                          {specificData?.invoice?.taxes.map((tax, index) => {
                            return (
                              <React.Fragment key={index}>
                                {tax.cgst && (
                                  <p className="h-8">{tax.cgst?.rate}%</p>
                                )}
                                {tax.sgst && (
                                  <p className="h-8">{tax.sgst?.rate}%</p>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </td>
                        <td className="border border-Blue_8"></td>
                        <td className="border border-Blue_8 text-right pr-2.5 pt-2 ">
                          {specificData?.invoice?.taxes.map((tax, index) => {
                            return (
                              <React.Fragment key={index}>
                                {tax.cgst && (
                                  <p className="h-8"> ₹ {tax.cgst?.amount}</p>
                                )}
                                {tax.sgst && (
                                  <p className="h-8"> ₹ {tax.sgst?.amount}</p>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </td>
                      </tr>
                      <tr className="bg-Blue_6 h-[30px] text-sm ">
                        <td className="rounded-bl-lg"></td>
                        <td className="pl-5">
                          <p>Total</p>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right pr-2.5 rounded-br-lg">
                          <p>
                            ₹ {specificData?.invoice?.totals?.final_amount_due}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mb-2.5 border border-Blue_6 mx-5"></div>
                <div className="px-5">
                  <div className="flex justify-between">
                    <p className="text-Primary_white font-normal capitalize font-normal">
                      Amount chargeable in words :
                      {AmountToWords(
                        specificData?.invoice?.totals?.final_amount_due
                      )}
                    </p>
                    <span className="italic text-Primary_grey w-20">
                      E. & O.E
                    </span>
                  </div>
                  <div className="my-5">
                    <p className="text-Primary_white mb-1 ">Bank Details</p>
                    <div className="flex mb-0.5">
                      <p className="text-Font_14 text-Primary_grey ">
                        Bank Name :
                      </p>
                      <span className="text-Primary_white text-Font_14 pl-0.5">
                        {specificData?.invoice?.bank_details?.bank}
                      </span>
                    </div>
                    <div className="flex mb-0.5">
                      <p className="text-Font_14 text-Primary_grey">
                        Account number :
                      </p>
                      <span className="text-Primary_white text-Font_14 pl-0.5">
                        {specificData?.invoice?.bank_details?.account_number}
                      </span>
                    </div>
                    <div className="flex mb-0.5">
                      <p className="text-Font_14 text-Primary_grey">
                        Branch & IFSC code :
                      </p>
                      <span className="text-Primary_white text-Font_14 pl-0.5">
                        {specificData?.invoice?.bank_details?.ifsc_code}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  dispatch(downloadInvoice(specificData?.invoice?.id));
                }}
                className=" h-8 flex justify-center items-center w-[95%] rounded-lg bg-Failed text-Primary_white m-auto"
              >
                <img className="w-4 h-4 mr-1.5" src={download} alt="download" />
                <p className="text-Font_14">Download PDF</p>
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col h-screen overflow-y-hidden">
        <div className="NavBar-Main">
          <div className="Main-Content_In flex">
            <div className="Total">
              <p className="Main_Number font-semibold">
                {invoiceData?.total_count}
              </p>
              <p className="Number_Count font-sans">Total invoices</p>
            </div>
            <div className="flex justify-end items-center py-4 pl-10 w-full">
              {/* <div className="flex gap-16">
                <div>
                  <p className="font-semibold text-Settled text-Font_14">0</p>
                  <p className="Number_Count font-sans">Paid</p>
                </div>
                <div>
                  <p className="font-semibold text-Failed text-Font_14">14</p>
                  <p className="Number_Count font-sans">Unpaid</p>
                </div>
              </div> */}
              <div className="w-8 h-8 hover:bg-Blue_4_hover bg-Blue_4 cursor-pointer rounded-md pt-2 pl-2">
                <img
                  onClick={() => {
                    dispatch(getInvoices({ page_size: "50" }));
                  }}
                  className="w-4 h-4"
                  src={refresh}
                  alt="Refresh"
                />
              </div>
            </div>
          </div>

          <div className="Data-Table">
            <div className="Each-Card font-sans">Date</div>
            <div className="Each-Card font-sans">Invoice No.</div>
            <div className="Each-Card font-sans">Status</div>
            <div className="Each-Card font-sans">Amount</div>
            <div className="Last-Card font-sans">Action</div>
          </div>
        </div>

        {isLoading ? (
          <div className="loader-container m-auto justify-center items-center flex pt-16">
            <Loader />
          </div>
        ) : (
          <div className="overflow-y-auto h-screen mb-[3.125rem] ownerviewallscroll mt-[15.875rem]">
            {invoiceData?.invoices?.map((data) => (
              <div key={data?.id} className="Table-Data">
                <div className="first-Data">{data.invoice_date}</div>
                <div className="second-Data">{data.invoice_number}</div>
                <div
                  className="second-Data"
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  <p
                    className={`${
                      data.status === "paid" ? "paid" : "unpaid"
                    } capitalize`}
                  >
                    {data.status}
                  </p>
                </div>

                <div
                  className="second-Data"
                  style={{
                    justifyContent: "flex-end",
                    paddingRight: "20px",
                  }}
                >
                  ₹ <FormatAmount price={data.final_amount_due} />
                </div>
                <div className="Action-Data">
                  <img
                    className="imageClass"
                    onClick={() => {
                      dispatch(downloadInvoice(data?.id));
                    }}
                    src={download}
                    alt="download"
                  />
                  <img
                    onClick={() => singleStatement(data?.id)}
                    className="imageClass"
                    src={view_statement}
                    alt="view_statement"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default InvoicesDetail;
