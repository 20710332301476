import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toast_position } from "../../Authorization";
import { env } from "../../../../../env";
import axios from "axios";

const baseUrl = env.REACT_APP_BASE_URL;

export const getEntities = createAsyncThunk(
  "getEntities",
  async (values, { rejectWithValue }) => {
    const { page_no, page_size, search_string, entity_id } = values;
    try {
      const response = await axios.get(`${baseUrl}/entities`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          search_string: search_string,
          entity_id: entity_id,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      return rejectWithValue(error);
    }
  }
);

export const createEntity = createAsyncThunk("createEntity", async (values) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify(values),
  };
  return await fetch(`${baseUrl}/entities`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
});

const entitiesSlice = createSlice({
  name: "entities",
  initialState: {
    isLoading: false,
    entities: null,
    grades: [],
    isError: false,
    createEntityRes: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getEntities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getEntities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.entities = action.payload;
      try {
        state.grades = action.payload?.entities[0]?.grades;
      } catch {}
    });
    builder.addCase(getEntities.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(createEntity.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createEntity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.createEntityRes = action.payload;
      if (action.payload?.status)
        toast.success("Entity created successfully", toast_position);
      if (action.payload?.errors)
        toast.error(action.payload?.errors[0], toast_position);
    });
    builder.addCase(createEntity.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default entitiesSlice.reducer;
