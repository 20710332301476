import React, { useState, useEffect } from "react";
import "./index.css";
import Decline from "../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import Approved from "../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import close from "../../../assets/CloseIcons/close_with_white_circle.svg";
import RightArrow from "../../../assets/ArrowsIcons/arrow_right_white.svg";
import WhiteReload from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import { viewAccountDetail } from "../../../redux/features/verifyPAN/verifyPAN";
import { beneTxnAccountDetail } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import addStatus from "../../../assets/InfoIcons/add_status.svg";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../assets/Approve transactions/filter.png";
import FormatAmount from "../../_utils/FormatAmount/FormatAmount";
import approve_white from "../../../assets/CheckmarksIcons/approve_white.svg";
import rejected_white from "../../../assets/CloseIcons/rejected_white.svg";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import RefreshBalanceToast from "../../_utils/RefreshBalanceToast";
import {
  getCreateTransactionBalance,
  resetOwnerBalance,
} from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import BalanceLoader from "../../_utils/BalanceLoader";
import { Tooltip, tooltipClasses } from "@mui/material";
import { beneTransactionMultiApproval } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeclinedPopup from "../../../containers/Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import whiteLoader from "../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import ListWhite from "../../../assets/OwnerAccount/listview-white.png";
import arrow_left_white from "../../../assets/arrow_left_white.svg";
import BeneTxnSideDrawer from "../../SideDrawer";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import AnimationUpAndDown from "../../BeneTransaction/OwnerPopup/AnimationUpAndDown";
import { checkUsertoken } from "../../../redux/features/login/login";
import { resetBeneTxnMultiDeniedApprovalData } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import BeneTxnDetailPagePopup from "../../BeneTransaction/BeneTxnDetailPopup";
import confidential_transactionImg from "../../../assets/GeneralIcons/confidential_transaction.svg";
import Loader from "../../Loader/Index";

const BeneficiaryMultiApprovalTxnDetails = ({ state }) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showTxnDetailPop, setShowTxnDetailPop] = useState(false);
  const [txnId, setTxnId] = useState(null);
  const [DrawerStatus, SetDrawerStatus] = useState("");
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState([]);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );
  const [widthCheck, setIswidthCheck] = useState(window.innerWidth <= 950);
  const [datas, setDatas] = useState([]);
  const [AllBeneTxnAccounts, setAllBeneTxnAccounts] = useState([]);
  const [refreshId, setRefreshId] = useState("");
  const [refreshAnimation, setrefreshAnimation] = useState("");
  const [PrevBalance, setPrevBalance] = useState(null);
  const [transactionFilterData, setTransactionFilterData] = useState([]);
  const [transactionFilterID, setTransactionFilterID] = useState([]);
  const [isInsufficientFunds, setIsInsufficientFunds] = useState(false);
  const [sideDrawerDisabled, setSideDrawerDisabled] = useState(false);
  const [isApprovalError, setIsApprovalError] = useState(false);
  const [isApprovingTxn, setApprovingTxn] = useState(false);
  const { showReloginModal } = useSelector((state) => state.login);

  const TimestampConverter = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return formattedDate;
  };
  const services = useSelector((state) => state.refreshToken.services);
  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      setTransactionFilterData(
        storedSelectedApproval?.selectedSendTransactions
      );
      setTransactionFilterID(storedSelectedApproval.selectedTransactions);
    } else {
      setTransactionFilterData([]);
      setTransactionFilterID([]);
    }
  }, []);
  const openBeneTxnDetailModal = () => {
    setShowTxnDetailPop(true);
  };
  const closeBeneTxnDetailModal = () => {
    setShowTxnDetailPop(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
      setIswidthCheck(window.innerWidth <= 950);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const {
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
    createTransactionBankRefreshData,
  } = useSelector((state) => state.ownerAllVerification);

  const handleRefresh = (e, id, txnId, prevBalance) => {
    if (services?.includes("VAccountBalance") || services?.includes("Admin")) {
      e.stopPropagation();
      setPrevBalance(prevBalance);
      setrefreshAnimation(id);
      setRefreshId(txnId);
      dispatch(checkUsertoken());
      dispatch(getCreateTransactionBalance(id));
    } else {
      e.stopPropagation();
      Navigate("/feature_not_assigned");
    }
  };

  useEffect(() => {
    if (createTransactionBankRefreshLoading) {
      toast.dismiss();
      setTimeout(() => {
        RefreshBalanceToast();
      }, 200);
    } else if (createTransactionBankRefreshData?.status) {
      toast.dismiss();
      setTimeout(() => {
        toast.success("Balance refreshed successfully", toast_position);
      }, 500);
      // setTimeout(() => {
      //   setRefreshId(null);
      // }, 5000);
    } else if (
      createTransactionBankRefreshError &&
      !createTransactionBankRefreshLoading
    ) {
      toast.dismiss();
      setTimeout(() => {
        toast.error(createTransactionBankRefreshError, toast_position);
      }, 100);
    }
    if (createTransactionBankRefreshData) {
      let data = transactionFilterData.map((val) => {
        if (
          val.owner_account_id ===
          createTransactionBankRefreshData?.owner_accounts[0]?.owner_account_id
          // &&
          // val?.txn_id === refreshId
        ) {
          return {
            ...val,
            owner_account_balance:
              createTransactionBankRefreshData?.owner_accounts[0]
                ?.owner_account_balance,
            owner_account_balance_last_fetched_at_in_words:
              createTransactionBankRefreshData?.owner_accounts[0]
                ?.owner_account_balance_last_fetched_at_in_words,
          };
        }
        return val;
      });

      setTransactionFilterData(data);
      const selectedData = {
        selectedSendTransactions: data,
        selectedTransactions: transactionFilterID,
      };

      sessionStorage.setItem("selectedData", JSON.stringify(selectedData));
    }
    return () => {
      dispatch(checkUsertoken());
      dispatch(resetOwnerBalance());
    };
  }, [
    createTransactionBankRefreshData,
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
  ]);
  let total = transactionFilterData?.reduce((acc, obj) => {
    return acc + parseInt(obj?.amount);
  }, 0);

  const handleOpenPopup = (id) => {
    setShowPopup(true);
    // SetdeniedId(id);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const updateAllAccounts = (updatedAccounts) => {
    setAllBeneTxnAccounts(updatedAccounts);
    // SetstatustxnApproval("TxnReQuiredApproval");
  };
  const SideDrawerOpen = async (status, id, txnId) => {
    SetDrawerStatus(status);

    setisDrawerOpen(!isDrawerOpen);
    dispatch(checkUsertoken());
    await dispatch(viewAccountDetail({ id }));
    await dispatch(beneTxnAccountDetail(txnId));
  };

  const handleTxnApproval = async (id) => {
    setLoading(true);

    const imageStyle = {
      width: "40px",
      height: "40px",
      opacity: 1,
    };

    const paragraphStyle = {
      width: "160px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#FFFFFF",
      opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    };

    {
      isMobileResponsive
        ? //  <>
          setApprovingTxn(true)
        : //  </>
          toast(
            <div style={divStyle}>
              <img src={whiteLoader} alt="" style={imageStyle} />
              <p style={paragraphStyle}>Approving transaction</p>
            </div>,
            {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              fontSize: "20px",
              transition: Bounce,
              style: {
                width: "252px",
                height: "84px",
                borderRadius: "10px",
                backgroundColor: "#AE9041",
                color: "#fff",
              },
            }
          );
    }

    if (transactionFilterID.length > 0) {
      dispatch(checkUsertoken());
      dispatch(resetBeneTxnMultiDeniedApprovalData());
      const data = await dispatch(beneTransactionMultiApproval(id));

      setTimeout(() => {
        setLoading(false);
        setApprovingTxn(false);
        toast.dismiss();
        if (data?.payload?.status === 500) {
          setTimeout(() => {
            toast.error(data?.payload?.error, toast_position);
          }, 1000);
        }
        if (data?.payload?.errors?.user_unauthorized?.[0]) {
          setTimeout(() => {
            // toast.error(
            //   data?.payload?.errors?.user_unauthorized?.[0],
            //   toast_position
            // );
            Navigate("/feature_not_assigned", {
              state: {
                unavailableService: "VBeneficiaryTxn",
              },
            });
          }, 1000);
        } else if (data.payload.status === true) {
          const selectedData = {
            selectedSendTransactions: data?.payload?.transactions,
            selectedTransactions: transactionFilterID,
          };

          sessionStorage.setItem("selectedData", JSON.stringify(selectedData));
          Navigate(
            "/transaction/Beneficiary_Transaction/multi_transaction_approval/success"
          );
        } else if (data.payload.status === false) {
          // Navigate(
          //   "/transaction/Beneficiary_Transaction/multi_transaction_approval/error"
          // );
          setIsApprovalError(true);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (isApprovalError && showReloginModal === false) {
      Navigate(
        "/transaction/Beneficiary_Transaction/multi_transaction_approval/error"
      );
    }
  }, [showReloginModal, isApprovalError]);

  useEffect(() => {
    const hasInsufficientFunds = transactionFilterData.some(
      (val) => parseInt(val.amount) > parseFloat(val.owner_account_balance)
    );

    setIsInsufficientFunds(hasInsufficientFunds);
  }, [transactionFilterData]);

  return (
    <>
      {isMobileResponsive ? (
        <div
          className=""
          style={{
            height: "100vh",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div className="flex justify-between pl-[30px] pr-[30px] items-center  h-[60px] bg-[#3A5686] bg-[0%_0%] bg-no-repeat opacity-100">
            <div
              className="flex items-center gap-5 cursor-pointer"
              onClick={() => Navigate(-1)}
            >
              <img
                className="w-[16px] h-[16px]"
                src={arrow_left_white}
                alt="No Images"
              />
              <div>
                <p className=" text-[#F9F9F9] text-left text-[16px]  font-sans opacity-100">
                  Beneficiary
                </p>
                <span className=" text-[#F9F9F9] text-left text-[12px]  font-sans opacity-100">
                  Transaction approval
                </span>
              </div>
            </div>
          </div>
          <div className="h-[76px] bg-[#3A5686] bg-[0%_0%] bg-no-repeat opacity-100 mt-[1px] pl-[30px]">
            <div className="flex h-full justify-between items-center">
              <div className="flex flex-col">
                <p className="  h-[22px] text-left text-[16px] font-semibold leading-[22px] text-[#4DDD37] whitespace-nowrap">
                  ₹ {<FormatAmount price={total} />}
                </p>
                <span className=" text-left text-[10px] font-normal  text-[#DADADA]">
                  Transaction Amount
                </span>
              </div>
              <div className="flex flex-col justify-center items-center w-[80px] h-[76px] bg-[#506994] bg-[0%_0%] bg-no-repeat opacity-100">
                <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#667CA3] bg-[0%_0%] bg-no-repeat opacity-100 rounded-4xl">
                  <p className="w-[10px] flex justify-center items-center font-semibold text-[16px]  font-sans tracking-normal text-white opacity-100">
                    {transactionFilterData?.length}
                  </p>
                </div>
                <span className=" flex items-center mt-[7px] w-[40px]   text-[10px] font-normal leading-[14px] text-[#FFFFFF]">
                  Selected
                </span>
              </div>
            </div>
          </div>
          <div className="w-full h-[149px]  bg-[#3A5686] mt-[1px] flex flex-col gap-2 justify-center pl-[30px]">
            <p className="w-[150px] h-[19px]  font-semibold text-[14px]    text-[#FFFFFF]">
              Post Approval Process
            </p>
            <span
              className="w-[320px]   font-[400] text-[12px]  text-left text-[#FFFFFF]
"
            >
              Once approved, distribution of the transaction will get created
              and auto settled using selected source account. If there are
              insufficient funds, the transaction will settle once the source
              account is refilled, and distribution will be initiated
              thereafter.
            </span>
          </div>

          <div
            className=" w-full mx-auto"
            style={{
              height: "calc(100vh - 336px)",
              overflow: "hidden",
              overflowY: "scroll",
              paddingBottom: "26px",
            }}
          >
            {transactionFilterData?.map((val, i) => {
              return (
                <div
                  key={i}
                  className="h-[189px] bg-[#506994]  rounded-[10px] opacity-100 ml-5 mt-5 mr-[20px] mb-[20px] relative"
                >
                  <div className="h-[89px] bg-[#3a5686]  rounded-tl-[10px] rounded-tr-[10px]  opacity-100 pl-[10px] flex flex-col justify-between pt-[10px] min-w-0">
                    <div className="flex flex-row justify-between pr-[10px]">
                      <h4 className=" w-[186px] h-[22px] font-semibold text-[16px] text-[#4DDD37] whitespace-nowrap">
                        ₹ {<FormatAmount price={val?.amount} />}
                      </h4>
                      {val?.confidential_transaction === "Y" ? (
                        <span>
                          <img
                            className="w-[16px] h-[16px]"
                            src={confidential_transactionImg}
                            alt="confidential"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={val?.beneficiary_account_name
                        .toLowerCase()
                        .split(" ")
                        .map(function (word) {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                        })
                        .join(" ")}
                    >
                      <h5 className=" font-[400] text-[14px]  leading-[19.07px] text-left text-[#F9F9F9] whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[90%] h-[22px] ">
                        {val?.beneficiary_account_name
                          .toLowerCase()
                          .split(" ")
                          .map(function (word) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ")}
                      </h5>
                    </Tooltip>
                    <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_top_date">
                      <p className=" w-[77px] h-[14px] text-left text-[10px] font-semibold leading-[13.62px] text-[#DADADA]w-[76px]   tracking-[0px] text-[#b0bbce] opacity-100 whitespace-nowrap">
                        {TimestampConverter(val?.created_on)}
                      </p>
                      <Button
                        onClick={() => {
                          Navigate(
                            `/transaction/beneficiary_transaction/details/${val.txn_id}`,
                            {
                              state: {
                                type: "txn_single_approval",
                              },
                            }
                          );
                        }}
                        className="beneficiaryMultiApprovalTxnDetails_bottom_data_top_details"
                      >
                        <h6>More details</h6>
                        <img src={RightArrow} />
                      </Button>
                    </div>
                  </div>
                  <div className="h-[100px]  pt-[10px] pl-[10px]">
                    <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1">
                      <div className="">
                        <h4 className="   text-[14px] font-normal leading-[19.07px] text-[#F9F9F9]">
                          {" "}
                          {val?.owner_account_bank_acc_no
                            .toString()
                            .substring(0, 4)}{" "}
                          ••••{" "}
                          {val?.owner_account_bank_acc_no
                            .toString()
                            .substring(
                              val?.owner_account_bank_acc_no?.length - 4
                            )}
                        </h4>
                        <h5 className=" text-left text-[10px] font-normal leading-[13.62px] text-[#DADADA]">
                          Source account
                        </h5>
                      </div>
                      <div className="w-[80px] h-[25px] bg-[#F9F9F9] rounded-[20px] opacity-100 flex justify-center items-center">
                        <img
                          src={require("../../../assets/IfscLogo/" +
                            IfscCodeMapper(val?.owner_account_ifsc_code))}
                          alt="bank"
                          className="w-[50] h-[14px]"
                        />
                      </div>
                    </div>
                    <div className="multiLowerBottomBene">
                      <div className="refreshBottom">
                        <div className="Owner_Balance">
                          <div className="name1 text-xs">
                            <h4
                              style={{
                                color:
                                  val?.owner_account_balance >= val?.amount
                                    ? "#4DDD37"
                                    : "#FF5860",
                              }}
                            >
                              ₹
                              {
                                <FormatAmount
                                  price={val?.owner_account_balance}
                                />
                              }
                            </h4>
                          </div>
                          <div className="balanceTitle">
                            <p>
                              <AnimationUpAndDown
                                loader={createTransactionBankRefreshLoading}
                                currentId={val?.owner_account_id}
                                currentBalance={val?.owner_account_balance}
                                previousId={refreshAnimation}
                                previousBalance={PrevBalance}
                                refreshType={"single"}
                                classType={"MultiApproval"}
                              />
                            </p>
                          </div>
                        </div>
                        <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="Refresh Balance"
                          >
                            <button
                              className={`multiRefreshButton ${
                                (services &&
                                  services?.includes("VAccountBalance")) ||
                                services?.includes("Admin")
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed opacity-50"
                              }`}
                              onClick={(e) =>
                                handleRefresh(
                                  e,
                                  val?.owner_account_id,
                                  val?.txn_id,
                                  val?.owner_account_balance
                                )
                              }
                            >
                              {createTransactionBankRefreshLoading &&
                              refreshId === val?.txn_id ? (
                                <BalanceLoader
                                  loader={createTransactionBankRefreshLoading}
                                  type={"createTxn"}
                                />
                              ) : !createTransactionBankRefreshLoading &&
                                refreshId === val?.txn_id ? (
                                <BalanceLoader
                                  loader={createTransactionBankRefreshLoading}
                                  setAccId={setRefreshId}
                                  type={"createTxn"}
                                />
                              ) : (
                                <>
                                  {" "}
                                  <img src={WhiteReload} />
                                  <h6 className="text-[10px]">Refresh</h6>
                                </>
                              )}
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_left">
                        <div className="balanceText">
                          <h5 className="whitespace-nowrap w-[151px] overflow-ellipsis overflow-hidden text-[10px]">
                            Balance -{" "}
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                val?.owner_account_balance_last_fetched_at_in_words
                              }
                            >
                              {
                                val?.owner_account_balance_last_fetched_at_in_words
                              }
                            </Tooltip>
                          </h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2">
                      <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_left">
                        <div className="h-[17px] text-left text-[12px] font-semibold leading-[17px] tracking-[0px] text-[#F9F9F9] opacity-100 mb-[4px]">
                          <div className="h-[17px] text-left text-[12px] font-semibold leading-[17px] tracking-[0px] text-[#F9F9F9] opacity-100 mb-[4px]">
                            <h4
                              className="h-[17px] text-left text-[14px] font-semibold text-[#F9F9F9] opacity-100 mb-[4px]"
                              style={{
                                color:
                                  val?.owner_account_balance >= val.amount
                                    ? "#4DDD37"
                                    : "#FF5860",
                              }}
                            >
                              {" "}
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={val?.owner_account_balance}
                                />
                              }
                            </h4>
                          </div>
                          <div className="name2 ">
                            <p>
                              <AnimationUpAndDown
                                loader={createTransactionBankRefreshLoading}
                                currentId={val?.owner_account_id}
                                currentBalance={val?.owner_account_balance}
                                previousId={refreshAnimation}
                                previousBalance={PrevBalance}
                                refreshType={"single"}
                                classType={"MultiApproval"}
                              />
                            </p>
                          </div>
                        </div>

                        <h5 className="whitespace-nowrap  w-[140px]  overflow-ellipsis overflow-hidden ">
                          Balance -{" "}
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={
                              val?.owner_account_balance_last_fetched_at_in_words
                            }
                          >
                            {
                              val?.owner_account_balance_last_fetched_at_in_words
                            }
                          </Tooltip>
                        </h5>
                      </div>

                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Refresh Balance"
                      >
                        <button
                          className="w-[94px] h-[34px] bg-[#667CA3] rounded-tl-[10px] rounded-br-[10px]  flex justify-center items-center absolute bottom-0 right-0"
                          style={{ position: "absolute" }}
                          onClick={(e) =>
                            handleRefresh(
                              e,
                              val?.owner_account_id,
                              val?.txn_id,
                              val?.owner_account_balance
                            )
                          }
                        >
                          {createTransactionBankRefreshLoading &&
                          refreshId === val?.txn_id ? (
                            <BalanceLoader
                              loader={createTransactionBankRefreshLoading}
                              type={"createTxn"}
                            />
                          ) : !createTransactionBankRefreshLoading &&
                            refreshId === val?.txn_id ? (
                            <BalanceLoader
                              loader={createTransactionBankRefreshLoading}
                              setAccId={setRefreshId}
                              type={"createTxn"}
                            />
                          ) : (
                            <>
                              {" "}
                              <img
                                className="w-[12px] h-[12px] opacity-100 mr-[4px]"
                                src={WhiteReload}
                                alt="Img"
                              />
                              <h6 className="w-[37px] text-left font-semibold text-[10px] leading-[26px] tracking-[0px] text-[#ffffff] transform-none">
                                Refresh
                              </h6>
                            </>
                          )}
                        </button>
                      </Tooltip>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
          {isApprovingTxn && (
            <div
              className="w-full flex  p-[30px] pt-2 gap-1 bg-[#AE9041] opacity-100 h-[80px]"
              style={{
                position: "absolute",
                bottom: "40px",
              }}
            >
              <img
                className="w-[30px] h-[30px]"
                src={whiteLoader}
                alt="whiteLoader"
              />
              <p className=" text-left text-[16px] mt-[2px]  font-normal tracking-[0px] text-white opacity-100">
                Approving transactions
              </p>
            </div>
          )}
          {showPopup && (
            <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom-backdrop bg-opacity-80">
              <DeclinedPopup
                onClose={handleClosePopup}
                accountId={transactionFilterID}
                allAccounts={transactionFilterData}
                updateAllAccounts={updateAllAccounts}
                status={"Transaction"}
                sideDrawer={"multiApproval"}
                isMobile={isMobileResponsive}
              />
            </div>
          )}
          <div
            style={{
              position: "fixed",
              bottom: "0px",
            }}
            className="h-[74px] flex  w-full justify-around  items-center  bg-[#506994] shadow-[0px_-3px_6px_rgba(0,0,0,0.29)] rounded-t-[40px] opacity-100"
          >
            {loading ? (
              <>
                <img src={whiteLoader} alt="Loading" />
              </>
            ) : (
              <>
                <button
                  onClick={() => handleOpenPopup(transactionFilterID)}
                  className=" flex items-center justify-center gap-2 w-[145px] h-[39px] bg-[#FF5860] rounded-[44px] opacity-100"
                >
                  <img
                    className="w-[16px] h-[16px]"
                    src={rejected_white}
                    alt="rejected_white"
                  />
                  <p className="  font-semibold text-[14px]  text-white opacity-100">
                    Reject
                  </p>
                </button>
                <button
                  onClick={() => handleTxnApproval(transactionFilterID)}
                  className="flex items-center gap-2 justify-center w-[145px] h-[39px] bg-[#69B76F] rounded-[43px] opacity-100"
                >
                  <img
                    className="w-[16px] h-[16px]"
                    src={approve_white}
                    alt="approve_white"
                  />
                  <p className="  font-semibold text-[14px]  text-white opacity-100">
                    Approve
                  </p>
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className="beneficiaryMultiApprovalTxnDetails_main"
          style={{
            overflow: showPopup ? "hidden" : "",
            height: showPopup ? "100vh" : "",
          }}
        >
          {" "}
          {loading && (
            <div
              className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#252525] bg-opacity-80
"
            >
              <div className="flex justify-center items-center w-[204px] h-[90px]   opacity-100">
                <Loader />
              </div>
            </div>
          )}
          <div className="beneficiaryMultiApprovalTxnDetails_top">
            <div className="beneficiaryMultiApprovalTxnDetails_top_1">
              {showTxnDetailPop && (
                <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
                  <BeneTxnDetailPagePopup
                    closeBeneTxnDetailModal={closeBeneTxnDetailModal}
                    bene_txn_id={txnId}
                    setTransactionFilterData={setTransactionFilterData}
                    setTransactionFilterID={setTransactionFilterID}
                    type={"bene_txn_detail_modal"}
                    typeMulti={"MultiApproval_Session_Data"}
                  />
                </div>
              )}

              {showPopup && (
                <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom-backdrop bg-opacity-80">
                  <DeclinedPopup
                    onClose={handleClosePopup}
                    accountId={transactionFilterID}
                    allAccounts={transactionFilterData}
                    updateAllAccounts={updateAllAccounts}
                    status={"Transaction"}
                    sideDrawer={"multiApproval"}
                  />
                </div>
              )}
              <Button
                onClick={() => Navigate(-1)}
                className="beneficiaryMultiApprovalTxnDetails_top_1_div1 beneficiaryMultiApprovalTxnDetails_top_1_com"
              >
                <img src={close} />
                <p>Cancel</p>
              </Button>
              <div className="beneficiaryMultiApprovalTxnDetails_top_1_div2">
                <p className="beneficiaryMultiApprovalTxnDetails_top_1_div2_p1">
                  ₹ {<FormatAmount price={total} />}
                </p>
                <p className="beneficiaryMultiApprovalTxnDetails_top_1_div2_p2">
                  Transaction Amount
                </p>
              </div>
              <div className="w-[204px] flex h-[90px] bg-[#506994]">
                {loading && (
                  <div className="flex justify-center items-center w-[204px] h-[90px]   opacity-100">
                    <Loader />
                  </div>
                )}
                {!loading && (
                  <>
                    <Button
                      onClick={() => {
                        if (transactionFilterData.length !== 0) {
                          handleOpenPopup(transactionFilterID);
                        }
                      }}
                      className={`beneficiaryMultiApprovalTxnDetails_top_1_div3 beneficiaryMultiApprovalTxnDetails_top_1_com ${
                        transactionFilterData.length !== 0
                          ? "beneficiaryMultiApprovalTxnDetails_top_1_div3_NotHover"
                          : ""
                      }`}
                      style={{
                        cursor:
                          transactionFilterData.length !== 0
                            ? "pointer"
                            : "default",
                        opacity:
                          transactionFilterData.length !== 0 ? "1" : "0.6",
                      }}
                    >
                      <img src={Decline} alt="Loading..." />
                      <p>Reject</p>
                    </Button>
                    <Button
                      className={`beneficiaryMultiApprovalTxnDetails_top_1_div4 beneficiaryMultiApprovalTxnDetails_top_1_com ${
                        transactionFilterData.length !== 0
                          ? "beneficiaryMultiApprovalTxnDetails_top_1_div4_NotHover"
                          : ""
                      }`}
                      style={{
                        cursor:
                          transactionFilterData.length !== 0
                            ? "pointer"
                            : "default",
                        opacity:
                          transactionFilterData.length !== 0 ? "1" : "0.6",
                      }}
                      onClick={() => {
                        if (transactionFilterData.length !== 0) {
                          handleTxnApproval(transactionFilterID);
                        }
                      }}
                    >
                      <img src={Approved} alt="Loading..." />
                      <p>Approve</p>
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="beneficiaryMultiApprovalTxnDetails_top_2">
              <div>
                <p className="beneficiaryMultiApprovalTxnDetails_top_2_p1">
                  Post Approval Process
                </p>
                <p className="beneficiaryMultiApprovalTxnDetails_top_2_p2">
                  Once approved, transaction distribution will get created and
                  auto settled using selected source account. If there are
                  insufficient funds, the transaction will settle once the
                  source account is refilled, and distribution will be initiated
                  thereafter.
                </p>
              </div>
            </div>
          </div>
          <div className="beneficiaryMultiApprovalTxnDetails_bottom">
            <h4>Selected Transaction ({transactionFilterData?.length})</h4>
            <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_main">
              {transactionFilterData?.map((val, i) => {
                return (
                  <div
                    key={i}
                    className="beneficiaryMultiApprovalTxnDetails_bottom_data"
                  >
                    <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_top min-w-0">
                      <div className="flex flex-row justify-between pr-[10px]">
                        <h4 className=" whitespace-nowrap">
                          ₹ {<FormatAmount price={val?.amount} />}
                        </h4>
                        {val?.confidential_transaction === "Y" ? (
                          <span>
                            <img
                              className="w-[16px] h-[16px]"
                              src={confidential_transactionImg}
                              alt="confidential"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>

                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={val?.beneficiary_account_name
                          .toLowerCase()
                          .split(" ")
                          .map(function (word) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ")}
                      >
                        <h5 className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                          {val?.beneficiary_account_name
                            .toLowerCase()
                            .split(" ")
                            .map(function (word) {
                              return (
                                word.charAt(0).toUpperCase() + word.slice(1)
                              );
                            })
                            .join(" ")}
                        </h5>
                      </Tooltip>
                      <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_top_date">
                        <p>{TimestampConverter(val?.created_on)}</p>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSideDrawerDisabled(false);
                            setDatas(val);
                            openBeneTxnDetailModal();
                            setTxnId(val.txn_id);
                          }}
                          className="beneficiaryMultiApprovalTxnDetails_bottom_data_top_details"
                        >
                          <h6>More details</h6>
                          <img src={RightArrow} />
                        </Button>
                      </div>
                    </div>
                    <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom">
                      <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1">
                        <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_left">
                          <h4>
                            {" "}
                            {val?.owner_account_bank_acc_no
                              .toString()
                              .substring(0, 4)}{" "}
                            ••••{" "}
                            {val?.owner_account_bank_acc_no
                              .toString()
                              .substring(
                                val?.owner_account_bank_acc_no?.length - 4
                              )}
                          </h4>
                          <h5>Source account</h5>
                        </div>
                        <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_img_div">
                          <img
                            src={require("../../../assets/IfscLogo/" +
                              IfscCodeMapper(val?.owner_account_ifsc_code))}
                            alt="bank"
                          />
                        </div>
                      </div>
                      <div className="multiLowerBottomBene">
                        <div className="refreshBottom">
                          <div className="Owner_Balance">
                            <div className="name1 text-xs">
                              <h4
                                style={{
                                  color:
                                    val?.owner_account_balance >= val?.amount
                                      ? "#4DDD37"
                                      : "#FF5860",
                                }}
                              >
                                ₹
                                {
                                  <FormatAmount
                                    price={val?.owner_account_balance}
                                  />
                                }
                              </h4>
                            </div>
                            <div className="balanceTitle">
                              <p>
                                <AnimationUpAndDown
                                  loader={createTransactionBankRefreshLoading}
                                  currentId={val?.owner_account_id}
                                  currentBalance={val?.owner_account_balance}
                                  previousId={refreshAnimation}
                                  previousBalance={PrevBalance}
                                  refreshType={"single"}
                                  classType={"MultiApproval"}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title="Refresh Balance"
                            >
                              <button
                                className={`multiRefreshButton ${
                                  (services &&
                                    services?.includes("VAccountBalance")) ||
                                  services?.includes("Admin")
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed opacity-50"
                                }`}
                                onClick={(e) =>
                                  handleRefresh(
                                    e,
                                    val?.owner_account_id,
                                    val?.txn_id,
                                    val?.owner_account_balance
                                  )
                                }
                              >
                                {createTransactionBankRefreshLoading &&
                                refreshId === val?.txn_id ? (
                                  <BalanceLoader
                                    loader={createTransactionBankRefreshLoading}
                                    type={"createTxn"}
                                  />
                                ) : !createTransactionBankRefreshLoading &&
                                  refreshId === val?.txn_id ? (
                                  <BalanceLoader
                                    loader={createTransactionBankRefreshLoading}
                                    setAccId={setRefreshId}
                                    type={"createTxn"}
                                  />
                                ) : (
                                  <>
                                    {" "}
                                    <img src={WhiteReload} />
                                    <h6 className="text-[10px]">Refresh</h6>
                                  </>
                                )}
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_left">
                          <div className="balanceText">
                            <h5 className="whitespace-nowrap w-[151px] overflow-ellipsis overflow-hidden text-[10px]">
                              Balance -{" "}
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={
                                  val?.owner_account_balance_last_fetched_at_in_words
                                }
                              >
                                {
                                  val?.owner_account_balance_last_fetched_at_in_words
                                }
                              </Tooltip>
                            </h5>
                          </div>
                        </div>
                      </div>
                      {/* <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2"> */}
                      {/* <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_left">
                          <div className="Owner_Balance">
                            <div className="name1">
                              <h4
                                style={{
                                  color:
                                    val?.owner_account_balance >= val.amount
                                      ? "#4DDD37"
                                      : "#FF5860",
                                }}
                              >
                                {" "}
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={val?.owner_account_balance}
                                  />
                                }
                              </h4>
                            </div>
                            <div className="name2 ">
                              <p>
                                <AnimationUpAndDown
                                  loader={createTransactionBankRefreshLoading}
                                  currentId={val?.owner_account_id}
                                  currentBalance={val?.owner_account_balance}
                                  previousId={refreshAnimation}
                                  previousBalance={PrevBalance}
                                  refreshType={"single"}
                                  classType={"MultiApproval"}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title="Refresh Balance"
                            >
                              <button
                                className="multiRefreshButton"
                                onClick={(e) =>
                                  handleRefresh(
                                    e,
                                    val?.owner_account_id,
                                    val?.txn_id,
                                    val?.owner_account_balance
                                  )
                                }
                              >
                                {createTransactionBankRefreshLoading &&
                                refreshId === val?.txn_id ? (
                                  <BalanceLoader
                                    loader={createTransactionBankRefreshLoading}
                                    type={"createTxn"}
                                  />
                                ) : !createTransactionBankRefreshLoading &&
                                  refreshId === val?.txn_id ? (
                                  <BalanceLoader
                                    loader={createTransactionBankRefreshLoading}
                                    setAccId={setRefreshId}
                                    type={"createTxn"}
                                  />
                                ) : (
                                  <>
                                    {" "}
                                    <img src={WhiteReload} />
                                    <h6 className="text-[10px]">Refresh</h6>
                                  </>
                                )}
                              </button>
                            </Tooltip>
                          </div>

                          <h5 className="whitespace-nowrap  w-[140px]  overflow-ellipsis overflow-hidden ">
                            Balance -{" "}
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                val?.owner_account_balance_last_fetched_at_in_words
                              }
                            >
                              {
                                val?.owner_account_balance_last_fetched_at_in_words
                              }
                            </Tooltip>
                          </h5>
                        </div> */}

                      {/* <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title="Refresh Balance"
                        >
                          <button
                            className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_button"
                            onClick={(e) =>
                              handleRefresh(
                                e,
                                val?.owner_account_id,
                                val?.txn_id,
                                val?.owner_account_balance
                              )
                            }
                          >
                            {createTransactionBankRefreshLoading &&
                            refreshId === val?.txn_id ? (
                              <BalanceLoader
                                loader={createTransactionBankRefreshLoading}
                                type={"createTxn"}
                              />
                            ) : !createTransactionBankRefreshLoading &&
                              refreshId === val?.txn_id ? (
                              <BalanceLoader
                                loader={createTransactionBankRefreshLoading}
                                setAccId={setRefreshId}
                                type={"createTxn"}
                              />
                            ) : (
                              <>
                                {" "}
                                <img src={WhiteReload} />
                                <h6>Refresh</h6>
                              </>
                            )}
                          </button>
                        </Tooltip> */}
                      {/* </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BeneficiaryMultiApprovalTxnDetails;
