import React, { useState } from "react";
import { useSelector } from "react-redux";
import arrowIcon from "../../../assets/TransactionDashboardIcons/arrow_next.png";
import checkIfEnable from "../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../services/ServiceIDByLabel";

const MenuItemCard = ({ icon, label }) => {
  const services_list = useSelector((state) => state.refreshToken.services);
  const [showExpandArrow, setShowExpandArrow] = useState(false);

  const isEnabled = checkIfEnable(ServiceIDByLabel, label, services_list);

  return (
    <div
      className={`flex items-center px-4 rounded-lg  h-[60px] text-left text-Font_16 font-sans font-normal text-Blue_6 ${
        isEnabled
          ? "opacity-100 cursor-pointer hover:bg-White_hover"
          : "opacity-50 pointer-events-none"
      }`}
      onMouseEnter={() => setShowExpandArrow(true)}
      onMouseLeave={() => setShowExpandArrow(false)}
    >
      <div className="home-dashboard-icons flex justify-center">
        {icon && (
          <img
            className="w-[40px] h-[40px]"
            src={require("../../../assets/TransactionDashboardIcons/" + icon)}
            alt=""
          />
        )}
      </div>
      <div className="flex justify-start font-normal font-sans ml-[20px] mr-[20px]">
        {label}
      </div>
      <div className="home-dashboard-arrow-icon flex justify-end w-10">
        {showExpandArrow && (
          <img
            className="w-[20px] h-[20px] bg-Blue_6 rounded-md"
            src={arrowIcon}
            alt="arrowIcon"
          />
        )}
      </div>
    </div>
  );
};

export default MenuItemCard;
