import React, { useRef, useEffect } from "react";
import "./index.css";
import ArrowDown from "../../assets/ArrowsIcons/arrow_down_white.svg";
import CheckIcon from "../../assets/CheckmarksIcons/check_mark_white.svg";
import clear_filter from "../../assets/ViewAllTransactions/Clear filter.png";

const SelectAccountBalance = ({
  filterPayload,
  setFilterPayload,
  selectSortAmt,
  setSelectSortAmt,
  owner,
}) => {
  const accountBalanceRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountBalanceRef.current &&
        !accountBalanceRef.current.contains(event.target)
      ) {
        setSelectSortAmt(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="owner_sort_by_acc_balance pb-1"
      style={{
        position: owner ? "" : "relative",
        left: owner ? "" : "70px",
      }}
    >
      {owner ? (
        <>
          <p style={{ color: "#C9C9C9", height: "22px" }}>Account balance</p>
        </>
      ) : (
        <p style={{ color: "#C9C9C9", height: "22px" }}>
          Sort by account balance
        </p>
      )}
      <div
        ref={accountBalanceRef}
        className="bank_high_to_low_select"
        onClick={() => {
          setSelectSortAmt(!selectSortAmt);
        }}
        style={{
          height: selectSortAmt ? "140px" : owner ? "37px" : "39px",
          width: owner ? "216px" : "179px",
          outline: selectSortAmt ? "1px solid #E25869" : "",
          cursor: "pointer",
        }}
      >
        <div className="bank_high_to_low_select_select">
          <div
            className="bank_high_to_low_select_scroll_line"
            style={{
              display: selectSortAmt ? "block" : "none",
              width: owner ? "160px" : "171px",
            }}
          ></div>

          <span
            style={{
              fontSize: selectSortAmt ? "12px" : "14px",
              paddingLeft: selectSortAmt ? "9px" : "15px",
            }}
          >
            {filterPayload.sortAccountNo.length > 0
              ? filterPayload.sortAccountNo
              : "Select order"}
          </span>
          <div className="flex flex-row items-center">
            <img
              src={clear_filter}
              alt="clear"
              className="w-[9px] h-[9px]"
              style={{
                visibility:
                  filterPayload.sortAccountNo.length > 0 ? "visible" : "hidden",
              }}
              onClick={(e) => {
                setFilterPayload((prev) => {
                  return { ...prev, sortAccountNo: "" };
                });
                e.stopPropagation();
              }}
            />
            <div
              className="w-[1px] h-[16px] mx-[8px]"
              style={{
                backgroundColor: "var(--Blue_3)",
                visibility:
                  filterPayload.sortAccountNo.length > 0 ? "visible" : "hidden",
              }}
            ></div>
            <img
              src={ArrowDown}
              alt="down"
              className="w-[11px] h-[11px]"
              style={{
                transform: selectSortAmt ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </div>
        </div>
        <div
          style={{ padding: "9px 5px 0 5px", cursor: "pointer" }}
          className="bank_high_to_low_select_scroll"
        >
          <div
            className="owner_connected_bank_scroll_com hover:bg-[#506994]"
            style={{
              width: owner ? "205px" : "169px ",
              padding: "0px 10px",
              zIndex: "99999 !important",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setFilterPayload({
                ...filterPayload,
                sortAccountNo: "",
              });
              setSelectSortAmt(false);
            }}
          >
            None
          </div>
          <div
            className="bank_high_to_low_select_scroll_com hover:bg-[#506994]"
            style={{
              padding: "0px 10px",
              width: owner ? "205px" : "169px ",
              backgroundColor:
                filterPayload.sortAccountNo === "High to low" ? "#3A5686" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setFilterPayload({
                ...filterPayload,
                sortAccountNo: "High to low",
              });

              setSelectSortAmt(false);
            }}
          >
            High to low
            <div
              style={{
                display:
                  filterPayload.sortAccountNo === "High to low"
                    ? "flex"
                    : "none",
                textAlign: "right",
                justifyContent: "end",
                paddingRight: "1px",
              }}
            >
              <img
                style={{
                  height: "13px",
                  width: "11px",
                }}
                src={CheckIcon}
                alt="check"
              />
            </div>
          </div>
          <div
            className="bank_high_to_low_select_scroll_com hover:bg-[#506994]"
            style={{
              padding: "0px 10px",
              width: owner ? "205px" : "169px",
              backgroundColor:
                filterPayload.sortAccountNo === "Low to high" ? "#3A5686" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setFilterPayload({
                ...filterPayload,
                sortAccountNo: "Low to high",
              });

              setSelectSortAmt(false);
            }}
          >
            Low to high
            <div
              style={{
                display:
                  filterPayload.sortAccountNo === "Low to high"
                    ? "flex"
                    : "none",
                textAlign: "right",
                justifyContent: "end",
                paddingRight: "1px",
              }}
            >
              <img
                style={{
                  height: "13px",
                  width: "11px",
                }}
                src={CheckIcon}
                alt="check"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAccountBalance;
