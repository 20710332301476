import React, { useEffect, useState } from "react";
import DeclinedImage from "../../../../assets/GeneralIcons/Reject page graphic.svg";
import DeclinedRed from "../../../../assets/RejectIcons/reject_icon_red_linear.svg";
import WhitedashBoard from "../../../../assets/GeneralIcons/dashboard.svg";
import "./index.css";
import { Tooltip, tooltipClasses } from "@mui/material";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";
import { Link } from "react-router-dom";

const BeneficiaryMultiApprovalDeclined = () => {
  const [declinedPageData, setdeclinedPageData] = useState([]);
  const [declinedPageID, setdeclinedPageID] = useState([]);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      setdeclinedPageData(storedSelectedApproval?.selectedSendTransactions);
      setdeclinedPageID(storedSelectedApproval.selectedTransactions);
    } else {
      setdeclinedPageData([]);
      setdeclinedPageID([]);
    }
  }, []);
  return (
    <>
      {isMobileResponsive ? (
        <div className="bg-Success_BG" style={{ height: "100vh" }}>
          <div className="flex flex-col justify-center items-center pt-2">
            <img
              className="h-[170px] mt-[19px] mb-[20px]"
              src={DeclinedImage}
              alt="successImage"
            />
            <p className="text-center   text-[14px] font-semibold  text-Primary_white opacity-100">
              Transactions have been rejected.
            </p>
          </div>

          <div
            className=" pt-5"
            style={{
              height: "calc(100vh - 232px)",
            }}
          >
            <div className="w-full flex justify-center gap-[8px] items-center h-[46px] rounded-b-none bg-Bottom_tab">
              <img className="w-[16] h-[16px]" src={DeclinedRed} alt="" />
              <p className=" text-[12px] font-semibold  text-Primary_white">
                Rejected Transactions - {declinedPageID.length}
              </p>
            </div>
            <div
              className=" bg-Primary_white"
              style={{
                overflowY: declinedPageData.length > 4 ? "scroll" : "hidden",

                paddingBottom: "10px",
                height: "calc(100vh - 352px)",
              }}
            >
              <div
                className=" pl-[10px] pr-[12px]"
                style={{
                  overflowY: declinedPageData.length > 4 ? "scroll" : "hidden",

                  paddingBottom: "10px",
                }}
              >
                {declinedPageData?.map((account, i) => {
                  return (
                    <>
                      <div className="flex mt-[10px] justify-between">
                        <div className="flex justify-center items-center gap-[10px]">
                          <img
                            className="w-[20px] h-[20px]"
                            src={DeclinedRed}
                            alt=""
                          />
                          <div>
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={account?.beneficiary_account_name
                                .toLowerCase()
                                .split(" ")
                                .map(function (word) {
                                  return (
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                  );
                                })
                                .join(" ")}
                            >
                              <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-medium text-[14px] leading-[22px] tracking-normal text-[#1D1D1D]">
                                {account.beneficiary_account_name
                                  .toLowerCase()
                                  .split(" ")
                                  .map(function (word) {
                                    return (
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                    );
                                  })
                                  .join(" ")}
                              </p>
                            </Tooltip>
                            <span className="flex items-start  w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                              Beneficiary name
                            </span>
                          </div>
                        </div>
                        <div>
                          <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[14px] leading-[22px] tracking-normal text-[#FF5860] opacity-100">
                            ₹ {<FormatAmount price={account?.amount} />}
                          </p>

                          <span className="flex justify-end items-end font-sans text-[12px] leading-[17px] text-Secondary_grey opacity-100">
                            Amount
                          </span>
                        </div>
                      </div>
                      {i < declinedPageData.length - 1 && (
                        <div
                          style={{
                            marginTop: "10px",
                            border: "0.5px solid #4A664D",
                            height: "0px",
                            opacity: "100",
                          }}
                        ></div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            <div className="h-[105px] rounded-tl-[20px] bg-Bottom_tab fixed bottom-0 w-[100%] rounded-tr-[20px] ">
              <Link to={"/transaction/beneficiary_transaction/dashboard"}>
                <div className="flex flex-col gap-1 justify-center items-center h-full">
                  <img
                    className="w-[33px] h-[33px]"
                    src={WhitedashBoard}
                    alt=""
                  />
                  <p className="w-[63px] h-[32px]  text-center text-[12px] font-semibold  text-Primary_white">
                    Go to dashboard
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-Success_BG">
          <div className="flex justify-center">
            <div className="flex flex-row max-w-screen-Qlg justify-center">
              <div className="flex-1 mt-[66px] pt-[5rem]">
                <div className="flex min-w-screen mx-5 justify-center items-center bg-Success_BG">
                  <div className="p-2 flex flex-col items-center justify-center text-center text-2xl">
                    <img
                      src={DeclinedImage}
                      width={"100%"}
                      height={"100%"}
                      alt=""
                      srcSet=""
                    />
                    <div className="pt-[20px]">
                      <p className="text-xl text-Primary_white">
                        Transactions have been rejected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 mt-[66px] py-[35px]">
                <div className="flex flex-col h-[554px] w-[472px] mx-5 bg-[#FFFFFF] rounded-[25px] items-center justify-between">
                  <div className="h-[80px] w-[472px] bg-Bottom_tab rounded-tl-[18px] rounded-tr-[18px]">
                    <div className="flex justify-center mt-[22px] gap-[1px]">
                      <p className="w-[181px] text-left whitespace-nowrap font-semibold text-[16px] leading-[22px] tracking-[0px] text-Primary_white opacity-100">
                        {" "}
                        Rejected transactions - {declinedPageID.length}
                      </p>
                    </div>
                    <div className="flex justify-center mt-[2px]">
                      <p className="w-[284px]  text-center font-normal text-[12px] leading-[17px] tracking-[0px] text-[#DADADA] opacity-100 ">
                        Transactions have been rejected.
                      </p>
                    </div>
                  </div>
                  <div
                    className="h-[341px] w-[472px] px-5"
                    style={{
                      overflowY:
                        declinedPageData.length > 5 ? "scroll" : "hidden",
                    }}
                  >
                    <div className="w-full h-[421px] mt-[10px]">
                      {declinedPageData?.map((account, i) => {
                        return (
                          <>
                            <div className="flex mt-[10px] justify-between">
                              <div className="flex justify-center items-center gap-[10px]">
                                <img
                                  className="w-[30px] h-[30px]"
                                  src={DeclinedRed}
                                  alt=""
                                />
                                <div>
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={account?.beneficiary_account_name
                                      .toLowerCase()
                                      .split(" ")
                                      .map(function (word) {
                                        return (
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                        );
                                      })
                                      .join(" ")}
                                  >
                                    <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-Primary_black pb-5">
                                      {account.beneficiary_account_name
                                        .toLowerCase()
                                        .split(" ")
                                        .map(function (word) {
                                          return (
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                          );
                                        })
                                        .join(" ")}
                                    </p>
                                  </Tooltip>
                                  <span className="flex items-start w-[110px] h-[25px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                                    Beneficiary name
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p className=" h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#FF5860] opacity-100  min-w-0 whitespace-nowrap">
                                  ₹ {<FormatAmount price={account?.amount} />}
                                </p>
                                <span className="flex justify-end items-end font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                                  Amount
                                </span>
                              </div>
                            </div>
                            {i < declinedPageData.length - 1 && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  border: "1px solid var(--Primary_grey)",
                                  height: "0px",
                                  width: "auto",
                                  opacity: "100",
                                }}
                              ></div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex justify-center items-center h-[136px] w-[472px] bg-Bottom_tab rounded-[20px]">
                    <Link to={"/transaction/beneficiary_transaction/dashboard"}>
                      <div className="w-[62px] flex justify-center items-center flex-col gap-[4px]  h-[76px]">
                        <img
                          className="w-[40px] h-[40px]"
                          src={WhitedashBoard}
                          alt=""
                        />
                        <p className="w-[62px] h-[32px] text-center font-semibold text-[12px] leading-[15px] tracking-[0px] text-Primary_white opacity-100">
                          Go to dashboard
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BeneficiaryMultiApprovalDeclined;
