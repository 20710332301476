import React, { useState, useEffect } from "react";
import "./BeneTxnFilter.css";
import LeftSign from "../../../assets/arrow_left_white.svg";
import { useNavigate } from "react-router-dom";
import SelectCategoryTransaction from "../../../components/_utils/SelectCategoryTransaction/index.jsx";
const BeneTxnFilter = ({
  setFilterOpen,
  AllBeneTxnAccounts,
  label,
  setlabel,
  initCategory,
  sendCategory,
  filterOpen,
  setInitCategory,
  transaction,
  setPage,
  setFilterCat,
  filteredType,
  handleClearCategory,
}) => {
  const navigate = useNavigate();
  return (
    <div className="overflow-hidden">
      <div className="beneacc_approval_heder_container-mob bg-[#3A5686]">
        <div className="txnheadertextv-mob">
          <div
            className="txnheaderarrowvcv-mob"
            onClick={() => setFilterOpen(false)}
          >
            <img
              src={LeftSign}
              style={{
                width: "16px",
                height: "16px",
                marginLeft: "10px",
              }}
              alt=""
            />
          </div>
          <div className="txnheadertextmv-mob">
            <div className="text-[#F9F9F9] text-[16px]">Filter</div>
          </div>
        </div>
      </div>
      <div className="mt-[75px]">
        <p className="ml-[25px] text-[14px] text-[#DADADA]">
          Beneficiary category
        </p>
        <SelectCategoryTransaction
          data={AllBeneTxnAccounts}
          label={label}
          setlabel={setlabel}
          initCategory={initCategory}
          setFilterOpen={setFilterOpen}
          sendCategory={sendCategory}
          filterOpen={filterOpen}
          setInitCategory={setInitCategory}
          transaction={true}
          setPage={setPage}
          setFilterCat={setFilterCat}
          filteredType={"singleApproveType"}
          flow={"singleApproveTxnMob"}
        />
      </div>

      <div className="bottom-0 fixed pb-[50px] pt-[20px] px-[8%] flex justify-between w-full bg-[#3A5686] rounded-t-[20px] mb-[-25px]">
        <div
          className="py-[9px] px-[37px] rounded-[30px]"
          style={{
            border:
              label === "Select an option"
                ? "1px solid #DADADA"
                : "1px solid #FAFAFA",
            opacity: label === "Select an option" ? "0.5" : "1",
          }}
          onClick={() => {
            if (label !== "Select an option") {
              handleClearCategory();
            }
          }}
        >
          <p
            className="text-[14px]"
            style={{
              color: label === "Select an option" ? "#DADADA" : "#F9F9F9",
            }}
          >
            {" "}
            Clear filter
          </p>
        </div>
        <div
          className="py-[9px] px-[37px] rounded-[30px]"
          style={{
            backgroundColor:
              label === "Select an option" ? "#707070" : "#F05A6A",
          }}
          onClick={() => {
            if (label !== "Select an option") {
              sendCategory();
            }
          }}
        >
          <p className="text-[14px] text-[#FFFFFF]"> Apply filter</p>
        </div>
      </div>
    </div>
  );
};

export default BeneTxnFilter;
