import React, { useEffect, useState } from "react";
import "./index.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { BeneTxnDashBoard } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { checkUsertoken } from "../../../redux/features/login/login";
import AddIcon from "../../../assets/AddIcons/add_button_white.svg";
import AlertIcon from "../../../assets/GeneralIcons/alerts_white.svg";
import ApprovalStatusIcon from "../../../assets/GeneralIcons/approval_status.svg";
import ArrowDownBlue from "../../../assets/ArrowsIcons/arrow_down_blue.svg";
import ArrowRightBlue from "../../../assets/ArrowsIcons/arrow_left_blue_box.svg";
import BeneficiaryTransaction from "../../../assets/HomeDashboardIcons/BeneficiaryTransactionWithBlueCircle.svg";
import Calendar from "../../../assets/HomeDashboardIcons/Calender.svg";
import Loader from "../../../assets/Beneficiary Icons/inputloder.gif";
import MoreActionsIcon from "../../../assets/GeneralIcons/add.svg";
import NextIcon from "../../../assets/ArrowsIcons/arrow_right_white_circle.svg";
import NoStatistics from "../../../assets/Graphics/OBJECTS.svg";
import PrevIcon from "../../../assets/ArrowsIcons/arrow_left_white_circle.svg";
import StatisticsIcon from "../../../assets/GeneralIcons/statistics.svg";

const BeneTxnDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [scheduleData, setScheduleData] = useState([]);
  const [isServiceAvl, setIsServiceAvl] = useState(false);
  const [txnCountLoading, setTxnCountLoading] = useState(true);
  const [isConfidentialFilter, setisConfidentialFilter] = useState(
    "Show only confidential"
  );
  const services = useSelector((state) => state.refreshToken.services);
  const { BeneTxnDashBoardData, BeneTxnDashBoardLoading } = useSelector(
    (state) => state?.beneTxnViewAccData
  );
  const requireApprovalTxnCount =
    BeneTxnDashBoardData?.summary?.actionable_transaction_count;

  const pendingTxnCount =
    BeneTxnDashBoardData?.summary?.pending_transaction_count;

  const approvedTxnCount =
    BeneTxnDashBoardData?.summary?.approved_transaction_count;

  useEffect(() => {
    setTimeout(() => {
      setTxnCountLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    async function getData() {
      dispatch(checkUsertoken());
      await dispatch(BeneTxnDashBoard({ page: 1, page_size: 50 }));
    }
    getData();
  }, [dispatch]);

  const handleDatacardClick = (approvalStatus, distributionStatus) => {
    const routeMap = {
      confidential: "/transaction/beneficiary_transaction/view_all",
      approved: "/transaction/beneficiary_transaction/view_all",
      pending: "/transaction/beneficiary_transaction/view_all",
      require:
        "/transaction/beneficiary_transaction/transaction_single_approval",
      rejected: "/transaction/beneficiary_transaction/view_all",
      settled: "/transaction/beneficiary_transaction/view_all",
      cancelled: "/transaction/beneficiary_transaction/view_all",
      failed: "/transaction/beneficiary_transaction/view_all",
      abandoned: "/transaction/beneficiary_transaction/view_all",
      queued: "/transaction/beneficiary_transaction/view_all",
      distribution_initiated: "/transaction/beneficiary_transaction/view_all",
      distribution_complete: "/transaction/beneficiary_transaction/view_all",
      awaiting_funds: "/transaction/beneficiary_transaction/view_all",
      created: "/transaction/beneficiary_transaction/view_all",
    };

    const isDistributionStatus = distributionStatus
      .toLowerCase()
      .includes("distribution");

    const DistributionStatus = isDistributionStatus ? true : false;
    const isDashboradCheck = approvalStatus === "confidential" ? true : false;
    navigate(routeMap[approvalStatus], {
      state: {
        approvalStatus,
        DistributionDashBoard: DistributionStatus,
        isConfidentialFilter,
        isDashboradCheck,
      },
    });
  };

  useEffect(() => {
    if (services?.includes("CBeneficiaryTxn") || services?.includes("Admin")) {
      setIsServiceAvl(false);
    } else if (services?.includes("ABeneficiaryTxn")) {
      setIsServiceAvl(true);
    }
  }, []);

  const slides = Array.from({ length: 0 }, (_, i) => `Slide ${i + 1}`);

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };
  return (
    <div
      style={{ height: "100dvh" }}
      className={`bg-Blue_1 w-full pt-[0.375rem] px-[0.938rem] overflow-y-auto lg:px-0 pb-5`}
    >
      <div className="flex flex-col lg:mt-[7.563rem] mt-[100px] lg:mx-auto pb-[5.313rem] lg:pb-0 max-w-5xl w-full lg:flex-row Qmd:px-5 Qlg:px-0">
        <div className="w-full lg:w-[43.25rem] flex flex-col">
          <div className="w-full h-[5.25rem] fixed top-0 left-0 right-0 bg-Nav_background flex items-center z-[5] pl-5 lg:hidden rounded-bl-[10px] rounded-br-[10px]">
            <div className="flex flex-row items-center">
              <img
                src={BeneficiaryTransaction}
                alt="Xpentra Logo"
                className="w-[2.50rem] mr-2.5"
              />
              <div className="flex flex-col">
                <div className="flex items-center">
                  <p className="text-Blue_6 font-semibold text-base">
                    Beneficiary
                  </p>
                </div>
                <p className="text-Blue_6 text-xs">Transactions</p>
              </div>
            </div>
          </div>

          <div className="w-full h-auto lg:h-[3.875rem] bg-Blue_6 flex flex-col sm:flex-row rounded-[0.938rem] lg:rounded-[0.938rem] items-center z-[4] p-4 sm:p-0">
            <div className="flex flex-col sm:flex-row w-full sm:justify-between items-center gap-2 sm:gap-0">
              {[
                {
                  icon: AddIcon,
                  label: (
                    <>
                      Create beneficiary
                      <br /> transaction
                    </>
                  ),
                  requiredservice: ["CBeneficiaryTxn"],
                  path: "/transaction/beneficiary_transaction/create",
                },
                // {
                //   icon: AddIcon,
                //   label: (
                //     <>
                //       Create bulk
                //       <br /> transaction
                //     </>
                //   ),
                //   requiredservice: ["CBulkTxn"],
                //   path: null,
                // },
                {
                  icon: AlertIcon,
                  label: (
                    <>
                      Refactor
                      <br /> transaction
                    </>
                  ),
                  requiredservice: null,
                  count: "00",
                  path: null,
                },
              ].map(
                ({ icon, label, requiredservice, count, path }, index, arr) => {
                  const isServiceAvailable =
                    Array.isArray(services) &&
                    Array.isArray(requiredservice) &&
                    requiredservice.some((service) =>
                      services.includes(service)
                    );

                  const isRefactorDisabled =
                    (Array.isArray(requiredservice) &&
                      requiredservice.includes("RefactorTxn")) ||
                    !isServiceAvailable ||
                    count === "00";

                  return (
                    <div
                      key={index}
                      className="w-full sm:flex-1 px-0 relative group hover:bg-none"
                    >
                      <div
                        className={`flex items-center sm:justify-between py-2.5 rounded-md lg:mx-5 qlg:pl-1 ${
                          isRefactorDisabled
                            ? "opacity-50 cursor-not-allowed"
                            : isServiceAvailable
                            ? "opacity-100 cursor-pointer lg:hover:bg-Blue_6_hover lg:hover:px-2.5 lg:hover:mx-2.5 lg:hover:py-[0.50rem]"
                            : "opacity-50 cursor-not-allowed"
                        }`}
                        onClick={() => {
                          if (
                            isRefactorDisabled ||
                            (Array?.isArray(requiredservice) &&
                              !isServiceAvailable)
                          ) {
                            navigate("/feature_not_assigned", {
                              state: { unavailableService: requiredservice },
                            });
                          } else if (path) {
                            navigate(path);
                          }
                        }}
                      >
                        <div className="flex items-center gap-2 justify-start">
                          <div className="w-[2.063rem] flex justify-center items-center">
                            <img
                              src={icon}
                              alt="Icon"
                              className="w-4 h-4 lg:w-6 lg:h-6"
                            />
                          </div>
                          <span className="text-xs text-Primary_white font-semibold">
                            {label}
                          </span>
                        </div>
                        {/* {Array?.isArray(requiredservice) &&
                          requiredservice?.includes("RefactorTxn") && ( */}
                        <span className="text-base font-semibold text-Primary_white ml-auto mr-1.5">
                          {count}
                        </span>
                        {/* )} */}
                      </div>

                      {index !== arr?.length - 1 && (
                        <div className="border-b sm:border-r border-custom-appBack w-full sm:w-auto h-[1px] sm:h-[3.125rem] sm:absolute sm:right-0 sm:top-1/2 transform sm:-translate-y-1/2"></div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>

          <div className="w-full Qlg:w-[43.25rem] lg:h-[9.625rem] bg-Blue_8 lg:bg-Blue_7 lg:mt-5 md:mt-5 sm:mt-[1.25rem] mt-[1.25rem] rounded-[0.938rem] relative overflow-hidden">
            <h2 className="h-[2.375rem] bg-Blue_4 flex items-center justify-between pl-5 py-2.5 text-Primary_grey transition-all duration-500">
              <div className="flex items-center text-sm font-semibold">
                <img
                  src={ApprovalStatusIcon}
                  alt="transaction header"
                  className="w-4 h-4 mr-2.5"
                />
                Approval statuses
              </div>

              <div
                className="lg:w-[9.063rem] md:w-[7.813rem] w-[7.063rem] flex items-center justify-between text-Primary_white text-xs bg-Blue_7 lg:hover:bg-Blue_7_hover py-3 px-5 cursor-pointer"
                onClick={() => {
                  const hasVBeneficiaryTxn =
                    Array.isArray(services) &&
                    services.includes("VBeneficiaryTxn");

                  if (hasVBeneficiaryTxn) {
                    navigate("/transaction/beneficiary_transaction/view_all");
                  } else {
                    navigate("/feature_not_assigned", {
                      state: { unavailableService: "VBeneficiaryTxn" },
                    });
                  }
                }}
              >
                View All
                <img
                  src={ArrowRightBlue}
                  alt="View_Schedule"
                  className="w-4 h-4"
                />
              </div>
            </h2>
            <div className="grid lg:grid-cols-3 grid-cols-1 relative gap-3 lg:gap-0 p-2.5 lg:p-0">
              {[
                {
                  title: "Require approval",
                  txnCount: services?.includes("ABeneficiaryTxn")
                    ? requireApprovalTxnCount || "00"
                    : "N/A",
                  txnServiceRequired: "ABeneficiaryTxn",
                  approvalStatus: "require",
                  path: "/transaction/beneficiary_transaction/transaction_single_approval",
                },
                {
                  title: "Pending transactions",
                  txnCount: pendingTxnCount ?? "00",
                  approvalStatus: "pending",
                },
                {
                  title: "Approved transactions",
                  txnCount: approvedTxnCount ?? "00",
                  approvalStatus: "approved",
                },
              ].map((item, index) => {
                const isRequireApproval = item.title === "Require approval";
                const isServiceFound =
                  !isRequireApproval ||
                  services?.includes(item.txnServiceRequired);

                const txnCountDisplay =
                  !isServiceFound && item.txnCount === "00"
                    ? "N/A"
                    : item.txnCount;

                return (
                  <div
                    key={index}
                    className="relative flex flex-col items-center w-full"
                  >
                    <div
                      className={`w-full lg:h-[7.25rem] h-[3.75rem] bg-Blue_6 flex flex-col justify-center items-center text-center p-2.5 lg:px-0 rounded-[0.938rem] lg:rounded-none mt-0 ${
                        isRequireApproval &&
                        (!isServiceFound || txnCountDisplay === "N/A")
                          ? "cursor-not-allowed opacity-50"
                          : "cursor-pointer lg:hover:bg-Blue_6_hover lg:hover:px-5"
                      }`}
                      onClick={() => {
                        if (isRequireApproval) {
                          if (!isServiceFound) {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: item.txnServiceRequired,
                              },
                            });
                          } else if (item.path) {
                            navigate(item.path);
                          } else {
                            handleDatacardClick(item.approvalStatus, "");
                          }
                        } else {
                          handleDatacardClick(item.approvalStatus, "");
                        }
                      }}
                    >
                      <div className="flex flex-col justify-center items-center text-center flex-1">
                        <div className="lg:min-h-[3rem] h-auto flex justify-center items-center">
                          {txnCountLoading || BeneTxnDashBoardLoading ? (
                            <img
                              src={Loader}
                              alt="Loading..."
                              className="w-6 h-6"
                            />
                          ) : (
                            <p
                              className={`text-lg lg:text-2xl lg:leading-[3rem] font-semibold ${
                                item.title === "Approved transactions"
                                  ? "text-Awaiting"
                                  : "text-Pending"
                              }`}
                            >
                              {txnCountDisplay}
                            </p>
                          )}
                        </div>
                        <p className="text-xs lg:text-xs text-Primary_grey font-semibold">
                          {item.title}
                        </p>
                      </div>
                    </div>

                    {index < 2 && (
                      <div className="hidden lg:block absolute top-1/2 transform -translate-y-1/2 h-[4.75rem] border-r-[0.063rem] border-custom-appBack left-[99.9%] z-10"></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full mt-5 bg-Blue_3 h-[38.563rem] lg:h-[22rem] rounded-t-[1.125rem] rounded-b-[0.938rem]">
            <h2 className="h-[2.438rem] bg-Blue_4 flex items-center justify-between px-5 py-2.5 text-sm font-semibold text-Primary_grey transition-all duration-500 rounded-t-[0.938rem]">
              <span className="flex items-center text-sm font-semibold">
                <img
                  src={StatisticsIcon}
                  alt="Statistics Header"
                  className="w-4 h-4 mr-2.5"
                />
                Statistics - Transactions count
              </span>

              {slides.length > 0 && (
                <div className="w-[2.688rem] flex items-center space-x-3">
                  <div
                    onClick={prevSlide}
                    className={`cursor-pointer w-4 h-4 ${
                      currentSlide === 0 ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    style={{
                      pointerEvents: currentSlide === 0 ? "none" : "auto",
                    }}
                  >
                    <img src={PrevIcon} alt="Previous" />
                  </div>
                  <div
                    onClick={nextSlide}
                    className={`cursor-pointer w-4 h-4 ${
                      currentSlide === slides.length - 1
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    style={{
                      pointerEvents:
                        currentSlide === slides.length - 1 ? "none" : "auto",
                    }}
                  >
                    <img src={NextIcon} alt="Next" />
                  </div>
                </div>
              )}
            </h2>

            <div className="relative w-full lg:h-[calc(22rem-2.438rem)] overflow-hidden contents lg:block">
              {slides.length > 0 ? (
                <div
                  className="flex transition-transform duration-500 ease-in-out"
                  style={{
                    transform: `translateX(-${currentSlide * 100}%)`,
                  }}
                >
                  {slides.map((slide, index) => (
                    <div
                      key={index}
                      className="w-full flex-shrink-0 flex items-center justify-center h-[19.563rem]"
                    >
                      {slide}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center lg:justify-start h-full text-center p-5">
                  <div className="flex flex-col justify-center items-center w-full">
                    <img
                      src={NoStatistics}
                      alt="No Slides"
                      className="w-[20.625rem] h-[11.188rem] mb-5"
                    />
                    <p className="text-xs text-Primary_white">
                      No statistics available due to the absence of data. Once
                      data is present,
                      <br />
                      statistics will appear.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full lg:w-[20.75rem] mt-5 lg:mt-0 lg:ml-5">
          <div className="w-full h-[24.375rem] bg-Blue_6 rounded-[0.938rem] flex flex-col">
            <h2 className="w-full h-[2.438rem] flex items-center bg-Blue_4 rounded-t-[0.938rem] px-5 py-2.5 text-sm font-semibold text-Primary_grey">
              <img src={Calendar} className="w-4 h-4 mr-2.5" alt="Calendar" />
              Scheduled transactions for this week
            </h2>

            <div className="flex-1 overflow-auto flex items-center justify-center">
              {scheduleData.length > 0 ? (
                <div className="flex flex-col px-5 my-2.5 w-full">
                  {scheduleData.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-row justify-between py-2 border-b border-Blue_3 last:border-none"
                    >
                      <div>
                        <div className="text-xs font-semibold text-Primary_white">
                          {item.title}
                        </div>
                        <div className="text-[10px] text-Primary_grey">
                          {item.subtitle}
                        </div>
                      </div>

                      <div>
                        <div className="text-xs font-semibold text-Primary_white">
                          {item.date}
                        </div>
                        <div className="text-[10px] text-Primary_grey">
                          {item.dateLabel}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center text-xs text-Primary_white">
                  There are no transactions scheduled for <br /> this week.
                </div>
              )}
            </div>

            <div className="h-[2.5rem] text-Primary_grey text-xs flex w-full cursor-pointer mt-auto">
              <div
                className={`w-1/2 bg-Blue_7 flex items-center justify-center px-5 py-2 border-r-[1px] text-center border-Blue_3 ${
                  (services || []).includes("CScheduledTxn")
                    ? "hover:bg-Blue_7_hover cursor-pointer"
                    : "cursor-not-allowed opacity-50"
                }`}
                onClick={() => {
                  if ((services || []).includes("CScheduledTxn")) {
                    toast.info("Feature coming soon!", toast_position);
                  } else {
                    navigate("/feature_not_assigned", {
                      state: { unavailableService: "CScheduledTxn" },
                    });
                  }
                }}
              >
                <span className="">Create Schedule</span>
              </div>

              <div
                className={`w-1/2 bg-Blue_7 flex items-center justify-center text-center px-5 py-2 ${
                  (services || []).includes("AScheduledTxn")
                    ? "hover:bg-Blue_7_hover cursor-pointer"
                    : "cursor-not-allowed opacity-50"
                }`}
                onClick={() => {
                  if ((services || []).includes("AScheduledTxn")) {
                    toast.info("Feature coming soon!", toast_position);
                  } else {
                    navigate("/feature_not_assigned", {
                      state: { unavailableService: "AScheduledTxn" },
                    });
                  }
                }}
              >
                <span className="">Approve Schedule</span>
              </div>
            </div>
            <div
              className={`h-[2.5rem] bg-Blue_7 text-Primary_grey lg:text-xs text-sm px-5 py-2 rounded-bl-[0.938rem] rounded-br-[0.938rem] flex items-center justify-center text-center cursor-pointer border-t-[1px] border-Blue_3 ${
                (services || []).includes("VScheduledTxn")
                  ? "hover:bg-Blue_7_hover cursor-pointer"
                  : "cursor-not-allowed opacity-50"
              }`}
              onClick={() => {
                if ((services || []).includes("VScheduledTxn")) {
                  toast.info("Feature coming soon!", toast_position);
                } else {
                  navigate("/feature_not_assigned", {
                    state: { unavailableService: "VScheduledTxn" },
                  });
                }
              }}
            >
              View Schedule
            </div>
          </div>

          {/* <div className="w-full lg:h-[12.375rem] h-[11.813rem] lg:bg-Blue_6 bg-Blue_7 mt-5 rounded-[0.938rem] relative overflow-hidden">
            <h2
              className={`h-[2.438rem] bg-Blue_4 flex items-center px-5 py-2.5 text-sm font-semibold text-Primary_grey transition-all duration-500 `}
            >
              <img
                src={MoreActionsIcon}
                alt="transcation header"
                className="w-4 h-4 mr-2.5"
              />
              More actions
            </h2>

            <div
              className={`absolute bottom-0 right-0 w-full lg:h-[10rem] h-[9.5rem] lg:bg-Blue_6 bg-Blue_7 text-Primary_white text-xs transition-transform duration-500 flex flex-col px-2.5 py-[0.938rem]`}
            >
              <div className="flex items-center justify-between h-1/3 border-b border-Blue_3 px-2">
                <span>MIS report</span>
                <img
                  src={ArrowRightBlue}
                  alt="View_Schedule"
                  className="w-4 h-4"
                />
              </div>
              <div className="flex items-center justify-between h-1/3 border-b border-Blue_3 px-2">
                <span>Payment advice</span>
                <img
                  src={ArrowRightBlue}
                  alt="View_Schedule"
                  className="w-4 h-4"
                />
              </div>
              <div className="flex items-center justify-between h-1/3 px-2">
                <span>Debit voucher</span>
                <img
                  src={ArrowRightBlue}
                  alt="View_Schedule"
                  className="w-4 h-4"
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BeneTxnDashboard;
