import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import cancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import search from "../../../assets/FilterSearchIcons/search_white.svg";
import enter_ifsc from "../../../assets/Graphics/Enter in search bar, to fetch.svg";
import dataLoading from "../../../assets/LoaderIcons/Loader.gif";
import notFound from "../../../assets/Graphics/No transaction found.svg";
import { checkUsertoken } from "../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import {
  beneVerifyThisGST,
  getSpecificGST,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useLocation, useNavigate } from "react-router-dom";
import SettingGSTDetails from "./GSTPage";
import { resetSelectedGst } from "../../../redux/features/verifyPAN/verifyPAN";
import Validation from "../../../utils/validation";
import ArrowLeft from "../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import Loader from "../../../components/Loader/Index";

const GSTVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef("");
  const [gstNo, setGstNo] = useState("");
  const [gstLoading, setGstLoading] = useState(false);
  const [gstNotFound, setGstNotFound] = useState(false);
  const [showGstDetails, SetShowGstDetails] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const { state } = useLocation();

  const { isGSTLoading } = useSelector((store) => store.beneAllVerification);

  const handleGST = (e) => {
    const GstValue = e.target.value.toUpperCase();
    const updateData = GstValue.replace(/[^A-Z0-9]/g, "");
    if (updateData.length <= 15) {
      setGstNo(updateData);
    }
    if (updateData.length == 0) {
      setGstLoading(false);
      setGstNotFound(false);
      SetShowGstDetails(false);
    }
    const cursorPosition = e.target.selectionStart;
    setTimeout(() => {
      e.target.focus();
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const { selectedGstData } = useSelector((state) => state.verify);

  useEffect(() => {
    if (services && !services?.includes("VGSTIN")) {
      navigate("/feature_not_assigned");
    }
    (async () => {
      if (state !== null) {
        setGstNo(`${state}`);
        // can't directly call handleGstFetch() here, state update won't be reflected until render completes. uhhhh
        await handleArbitraryGstFetch(state);
      }
    })();
    if (selectedGstData) {
      SetShowGstDetails(true);
      setGstNo(selectedGstData);
    }
    return () => {
      dispatch(resetSelectedGst());
    };
  }, []);

  const handleGstFetch = async () => {
    setGstLoading(true);

    if (!Validation.validateGstWithRegex(gstNo)) {
      toast.error("Invalid GSTIN!", toast_position);
      setGstLoading(false);
      setGstNotFound(true);
    } else {
      dispatch(checkUsertoken());

      let response;

      response = await dispatch(getSpecificGST({ search_string: gstNo }));
      if (response?.payload?.gstin_details === undefined) {
        response = await dispatch(beneVerifyThisGST({ search_string: gstNo }));
      }

      if (response?.payload?.status === true) {
        SetShowGstDetails(true);
        setGstLoading(false);
        setGstNotFound(false);
      } else if (response?.payload?.status === false) {
        setGstNotFound(true);
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };

  const handleArbitraryGstFetch = async (gstNo) => {
    setGstLoading(true);
    if (!Validation.validateGstWithRegex(gstNo)) {
      toast.error("Invalid GSTIN!", toast_position);
      setGstLoading(false);
      setGstNotFound(true);
    } else {
      dispatch(checkUsertoken());
      let response;

      response = await dispatch(getSpecificGST({ search_string: gstNo }));

      if (response?.payload?.gstin_details === undefined) {
        response = await dispatch(beneVerifyThisGST({ search_string: gstNo }));
      }

      if (response?.payload?.status === true) {
        SetShowGstDetails(true);
        setGstLoading(false);
        setGstNotFound(false);
      } else if (response?.payload?.status === false) {
        setGstNotFound(true);
        setGstLoading(false);
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleGstFetch();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [gstNo]);

  const shouldSearchBarBeHidden = selectedGstData !== null || state !== null;

  return (
    <div className="flex flex-col pb-5">
      <div
        className="Qmd:hidden fixed w-full top-0 bg-custom-lightBlue h-[56px]  flex items-center cursor-pointer"
        style={{ zIndex: 999 }}
        onClick={() => navigate(-1)}
      >
        <img src={ArrowLeft} alt="Close" className="w-4 h-4 m-5" />
        <h1 className="text-base flex-1 text-Primary_white">
          GST Verification
        </h1>
      </div>

      {!shouldSearchBarBeHidden ? (
        <>
          <div className="w-full Qmd:h-28  bg-custom-card opacity-100 flex flex-col justify-center items-center z-50 Qmd:mt-[70px]  Qmd:pt-12 Qmd:pb-2.5 mt-[75px] ">
            <div className="w-full max-w-[64rem]  searchInputContainerDiv">
              <div className="xs:w-full   Qmd:py-0 flex justify-center items-center ">
                <div className="w-[100%] items-center justify-center  bg-custom-appBackLight rounded-none Qmd:rounded-lg pl-[22px] Qmd:pl-5 text-left text-Primary_white text-sm leading-[1.1875rem] font-sans outline-none Qmd:max-w-[876px] placeholder-Primary_grey flex relative h-12   Qmd:h-[36px]">
                  <img
                    alt="search"
                    src={search}
                    onClick={() => inputRef.current.focus()}
                    className="w-[16px] h-[16px] flex items-center  justify-center cursor-pointer"
                  />
                  <input
                    ref={inputRef}
                    value={gstNo}
                    onChange={handleGST}
                    placeholder="Enter GST number"
                    style={{
                      color:
                        gstNo !== ""
                          ? "var(--Primary_white)"
                          : "var(--Primary_grey)",
                    }}
                    className="w-[100%] h-12  flex items-center justify-center  Qmd:h-[36px] bg-custom-appBackLight rounded-none  Qmd:rounded-lg pl-[10px]  Qmd:pl-3 text-left text-sm leading-[1.1875rem] font-sans outline-none Qmd:max-w-[876px] placeholder-Primary_grey"
                  />
                  {gstNo.length === 0 ? (
                    <></>
                  ) : (
                    <img
                      alt="cancel"
                      src={cancel}
                      onClick={() => {
                        setGstNo("");
                        setGstLoading(false);
                        setGstNotFound(false);
                        SetShowGstDetails(false);
                      }}
                      className="w-5 h-5 absolute right-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    />
                  )}
                </div>
              </div>

              <div className="flex justify-center mt-0   Qmd:mt-0   Qmd:py-0 rounded-none">
                <Button
                  sx={{ minWidth: "128px", borderRadius: "10px" }}
                  className="btnfetchele w-full"
                  disabled={gstNo.length !== 15}
                  onClick={handleGstFetch}
                  style={{
                    color:
                      gstNo.length === 15
                        ? "var(--Blue_3)"
                        : "var(--Primary_white)",
                    background:
                      gstNo.length === 15
                        ? "var(--Primary_white)"
                        : "var(--Secondary_grey)",
                  }}
                >
                  Fetch
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {isGSTLoading ? (
        <>
          <div className="gst-loading-loader mt-48">
            <Loader />
          </div>
        </>
      ) : gstNotFound ? (
        <>
          <div className="gst-not-found">
            <img src={notFound} alt="notfound" />
            <p className="text-Font_1 text-center font-sans font-normal leading-[21.79px]">
              No data found.
            </p>
            <button
              className="gst-clear-params-btn"
              onClick={() => {
                setGstNo("");
                setGstLoading(false);
                setGstNotFound(false);
                SetShowGstDetails(false);
              }}
            >
              Clear parameters
            </button>
          </div>
        </>
      ) : showGstDetails ? (
        <>
          <SettingGSTDetails
            gstNo={gstNo}
            setGstNotFound={setGstNotFound}
            hideSearch={shouldSearchBarBeHidden}
          />
        </>
      ) : (
        <>
          <div
            className="gst-default-img mx-auto"
            style={{
              // width: "25.25rem",
              height: "18.875rem",
              marginTop: "8rem",
            }}
          >
            <img
              src={enter_ifsc}
              alt="bgDefault"
              style={{
                width: "18.75rem",
                height: "12.625rem",
                marginBottom: "1.25rem",
              }}
            />
            <p className="text-Font_16 text-Primary_white font-normal text-center">
              Enter GST number in the search bar, to fetch the verified GST
              details.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default GSTVerification;
