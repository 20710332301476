import React, { useEffect, useRef, useState } from "react";
import BeneTxnViewall from "../../containers/BeneficiaryTransactions/BeneTxnViewall/index";
import InternalTransactionViewAll from "../../containers/InternalTransactionMain/InternalTransactionViewAll";
import closeIcon from "../../assets/CloseIcons/close.svg";
import ArrowDown from "../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import CheckIcon from "../../assets/CheckmarksIcons/check_mark_white.svg";
import "./index.css";

const TransactionHistory = ({
  bene_account_id,
  bene_account_name,
  bene_account_confidential,
  internal_account_confidential,
  owner_account_id,
  owner_account_bank_acc_no,
  txnHistorytype,
  onClose,
  Owner_account_id,
  Owner_account_number,
  OwnerDetail,
  BeneAccId,
  internalAccount,
  popuptype,
  popup,
}) => {
  const [type, setType] = useState(popuptype);
  const [balanceType, setBalanceType] = useState("credit");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  return (
    <>
      <div className={`transactionHistory_pop_div fixed overflow-hidden ${!isMobile? "rounded-[20px]" : ""} z-[99999] bg-[#1D3A6D]`}>
        <div className="h-full w-full">
          <div
            style={{
              display: OwnerDetail && !internalAccount ? "flex" : "none",
            }}
            className={`transactionHistoryPop_topDiv popup_responsive ${"flex flex-row justify-between items-center p-5"}`}
          >
            <div className="flex justify-between w-full">
              <div className="flex flex-col space-y-1">
                <p className="font-[16px] leading-[21px] text-[#dadada] font-sans font-semibold">
                  {type === "Beneficiary transaction"
                    ? "Beneficiary Transaction History :"
                    : "Internal Transaction History :"}
                </p>
                <p className="text-xs text-[#F9F9F9] font-sans font-normal">
                  All transactions related to the owner account are listed
                  below.
                </p>
              </div>
              {type === "Internal transaction" && (
                <div className="flex items-center w-[158px] my-auto px-[5px] gap-x-[5px] mr-[10px] h-[30px] rounded-[10px] bg-[#314D7E] relative cursor-pointer">
                  <div
                    style={{
                      top: "5px",
                      left: balanceType === "credit" ? "5px" : "80px",
                      transition: "0.2s all ease-in-out",
                    }}
                    className="absolute w-[74px] h-[20px] rounded-[5px] bg-[#f9f9f9]"
                  ></div>

                  <div
                    onClick={() => {
                      setBalanceType("credit");
                      setTimeout(() => {
                        setType("");
                      }, 110);
                      setTimeout(() => {
                        setType("Internal transaction");
                      }, 200);
                    }}
                    style={{
                      fontWeight: balanceType == "credit" ? "600" : "400",
                      color: balanceType == "credit" ? "#1D1D1D" : "#f9f9f9",
                    }}
                    className="relative w-[74px] h-[20px] rounded-[5px] text-[12px] flex justify-center items-center z-[1] itxn"
                  >
                    Credit
                    <div className="itxn_card_hover_overlay"></div>
                  </div>
                  <div
                    onClick={() => {
                      setBalanceType("debit");
                      setTimeout(() => {
                        setType("");
                      }, 110);
                      setTimeout(() => {
                        setType("Internal transaction");
                      }, 200);
                    }}
                    style={{
                      fontWeight: balanceType == "debit" ? "600" : "400",
                      color: balanceType == "debit" ? "#1D1D1D" : "#f9f9f9",
                    }}
                    className=" relative w-[74px] h-[20px] rounded-[5px] text-[12px] flex justify-center items-center z-[1] itxn"
                  >
                    Debit
                    <div className="itxn_card_hover_overlay"></div>
                  </div>
                </div>
              )}
            </div>
            <div
              className="flex justify-center items-center cursor-pointer w-[30px] h-[30px] rounded-[5px] bg-[#314D7E] hover:bg-[#506994]"
              onClick={() => onClose()}
            >
              <img src={closeIcon} className="w-4 h-4" />
            </div>
          </div>

          {txnHistorytype === "internalTxnHistory" ||
          internalAccount ||
          type === "Internal transaction" ? (
            <InternalTransactionViewAll
              owner_account_id={
                OwnerDetail ? Owner_account_id : owner_account_id
              }
              owner_account_bank_acc_no={
                OwnerDetail ? Owner_account_number : owner_account_bank_acc_no
              }
              txnHistorytype={
                OwnerDetail ? "ownerDetailTxnHistory" : txnHistorytype
              }
              internal_account_confidential={internal_account_confidential}
              onClose={onClose}
              OwnerDetail={OwnerDetail}
              balanceType={balanceType}
              setBalanceType={setBalanceType}
              setType={setType}
              BeneAccId={BeneAccId}
              internalAccount={internalAccount}
              popup={popup}
            />
          ) : (
            <BeneTxnViewall
              bene_account_name={bene_account_name}
              bene_account_id={bene_account_id}
              bene_account_confidential={bene_account_confidential}
              onClose={onClose}
              typepop={"transactionHistorypopup"}
              popup={popup}
              Owner_account_id={Owner_account_id}
              Owner_account_number={Owner_account_number}
              OwnerDetail={OwnerDetail}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
