import React, { useState, useEffect } from "react";
import "./index.css";
import TotpStepper from "../../../components/Totp/TotpStepper";
import { useCustomHook } from "../../InternalTransactionMain/InternalTransactionCreate/useCustomHook";
import TotpBackupCodes from "../../../components/Totp/TotpBackupCodes";
import TotpDownloadApp from "../../../components/Totp/TotpDownloadApp";
import TotpScanner from "../../../components/Totp/TotpScanner";
import TotpVerify from "../../../components/Totp/TotpVerify";
import { useDispatch, useSelector } from "react-redux";
import Enabling from "../../../assets/GeneralIcons/enabling_totp.svg";
import Disabling from "../../../assets/GeneralIcons/disabling_totp.svg";
import DisabledPop from "../../../components/Totp/DisabledPop";
import { checkUsertoken } from "../../../redux/features/login/login";
import {
  disabledEnabledError,
  initializeTotp,
  removeInitialError,
  resetDisabled,
} from "../../../redux/features/totp/totp";

const TotpCreate = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [isMounted, setIsMounted] = useState(true);
  const [openDisabledPop, setOpenDisabledPop] = useState(false);

  const [hashKey, setHashKey] = useState({
    email: "",
    key: "",
  });
  const [removeClassName, setRemoveClassName] = useState(true);
  const [isAppDownload, setIsAppDownload] = useState(false);
  const [isQrScanned, setIsQrScanned] = useState(false);
  const [direction, setDirection] = useState("next");
  const [nextButtonEnabled, BenEnableNextButton] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [totp, setTotp] = useState("");
  const [backupCodes, setBackupCodes] = useState([]);
  const userId = JSON.parse(sessionStorage.getItem("response"))?.employees?.id;
  const [open, setOpen] = useState(false);
  const [currentDisplay, setCurrentDisplay] = useState("");
  const [tooltipText, setTooltipText] = useState("Click to copy");

  const steps = [
    "Download app",
    "Scan QR code or enter passkey",
    "Verify Authentication Code",
    "Download backup codes",
  ];

  const { enableTotpLoading, enableTotpData, enableTotpError } = useSelector(
    (state) => state.totp
  );

  const { disableTotpLoading, disableTotpData, disableTotpError } = useSelector(
    (state) => state.totp
  );

  useEffect(() => {
    dispatch(disabledEnabledError());
  }, []);

  const {
    initializeTotpLoading,
    initializeTotpData,
    initializeTotpError,
    initializeTotpErrorMsg,
  } = useSelector((state) => state.totp);

  const { shouldRender, animationClass } = useCustomHook(
    isMounted,
    270,
    direction
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (enableTotpData?.status === true) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enableTotpData?.status]);
  const toggleMounting = () => {
    setIsMounted(false);
    setTimeout(() => {
      setIsMounted(true);
    }, 270);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setDirection("next");
    toggleMounting();
    setRemoveClassName(false);
    setTimeout(() => {
      setRemoveClassName(true);
    }, 1000);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      setCurrentDisplay("enabled");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setDirection("previous");
      toggleMounting();
      setRemoveClassName(false);
      setTimeout(() => {
        setRemoveClassName(true);
      }, 1000);
    }
  };
  const renderTotp = () => {
    switch (activeStep) {
      case 0:
        return (
          <TotpDownloadApp
            isAppDownload={isAppDownload}
            setIsAppDownload={setIsAppDownload}
          />
        );
      case 1:
        return (
          <TotpScanner
            hashKey={hashKey}
            isQrScanned={isQrScanned}
            setIsQrScanned={setIsQrScanned}
            open={open}
            setOpen={setOpen}
            tooltipText={tooltipText}
            setTooltipText={setTooltipText}
          />
        );
      case 2:
        return (
          <TotpVerify
            isFocus={isFocus}
            setIsFocus={setIsFocus}
            totp={totp}
            setTotp={setTotp}
            userId={userId}
            setBackupCodes={setBackupCodes}
            enableTotpLoading={enableTotpLoading}
            enableTotpData={enableTotpData}
            enableTotpError={enableTotpError}
          />
        );
      case 3:
        return (
          <TotpBackupCodes
            backupCodes={backupCodes}
            open={open}
            setOpen={setOpen}
            tooltipText={tooltipText}
            setTooltipText={setTooltipText}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (
      activeStep === 0 &&
      isAppDownload &&
      initializeTotpData?.status === true &&
      !initializeTotpLoading
    ) {
      BenEnableNextButton(true);
    } else if (
      activeStep === 1 &&
      isQrScanned &&
      initializeTotpData?.status === true &&
      !initializeTotpLoading
    ) {
      BenEnableNextButton(true);
    } else if (
      activeStep === 2 &&
      enableTotpData?.status === true &&
      !enableTotpLoading
    ) {
      BenEnableNextButton(true);
    } else {
      BenEnableNextButton(false);
    }
  }, [
    activeStep,
    isAppDownload,
    isQrScanned,
    enableTotpData,
    enableTotpLoading,
    initializeTotpData,
    initializeTotpLoading,
  ]);

  useEffect(() => {
    if (hashKey?.key?.length === 0) {
      dispatch(checkUsertoken());
      dispatch(initializeTotp({ id: userId }));
    }
    return () => {
      dispatch(removeInitialError());
      dispatch(resetDisabled());
    };
  }, []);

  useEffect(() => {
    if (
      !disableTotpLoading &&
      disableTotpData?.status &&
      disableTotpError !== true
    ) {
      dispatch(checkUsertoken());
      dispatch(initializeTotp({ id: userId }));
    }
  }, [disableTotpData]);

  useEffect(() => {
    if (!initializeTotpLoading && initializeTotpData?.status === true) {
      setCurrentDisplay("enabled");
      setHashKey({
        ...hashKey,
        key: initializeTotpData?.data?.totp_secret,
        email: initializeTotpData?.data?.email,
      });
    } else if (
      initializeTotpError &&
      initializeTotpErrorMsg === "Two Factor Authentication is already enabled"
    ) {
      setCurrentDisplay("disabled");
    }
  }, [
    initializeTotpData,
    initializeTotpError,
    disableTotpData,
    disableTotpError,
  ]);
  return (
    <div className="totp_Container_txn">
      {openDisabledPop && (
        <DisabledPop
          setOpenDisabledPop={setOpenDisabledPop}
          userId={userId}
          setCurrentDisplay={setCurrentDisplay}
          disableTotpLoading={disableTotpLoading}
        />
      )}
      <div
        className="totp_stepper_Div"
        style={{
          height:
            currentDisplay === "enabled" || currentDisplay === "disabled"
              ? "11.25rem"
              : "10.25rem",
        }}
      >
        <div style={{ marginLeft: "-3.7rem" }}>
          {currentDisplay === "enabled" || currentDisplay === "disabled" ? (
            <div
              className="enabled_disabled_top"
              style={{ padding: "20px 0px" }}
            >
              <h4>TOTP</h4>
              <p>
                Protect your account by enabling two-factor authentication (2FA)
                using TOTP (Time-based One-time Password). This adds an extra
                layer of security to your login process.
              </p>
            </div>
          ) : currentDisplay === "steps" ? (
            <TotpStepper
              activeStep={activeStep}
              handleBack={handleBack}
              steps={steps}
              handleNext={handleNext}
              nextButtonEnabled={nextButtonEnabled}
              direction={direction}
              setCurrentDisplay={setCurrentDisplay}
              backupCodes={backupCodes}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className="carousel-container-totp"
        style={{
          paddingTop:
            (currentDisplay === "enabled" && currentDisplay === "disabled") ||
            currentDisplay === "steps"
              ? "186px"
              : "200px",
        }}
      >
        {currentDisplay === "enabled" ? (
          <div className="totp_enabled_disabled_main">
            <div className="totp_enabled_disabled_div">
              <h4 className="totp_enabled_disabled_div_1">Enable TOTP</h4>
              <p className="totp_enabled_disabled_div_2">
                To enable TOTP, complete the setup process.
              </p>
              <button
                className="totp_enabled_disabled_div_3"
                onClick={() => {
                  setCurrentDisplay("steps");
                }}
              >
                Set TOTP
              </button>
              <p className="totp_enabled_disabled_div_4">
                Note - You will need to download an Authentication application
                on your mobile, tablet or desktop device. It will generate a
                code that you will need to enter when you log in.
              </p>
            </div>
            <div className="totp_Enabling_img">
              <img src={Enabling} alt="Enabling" />
            </div>
          </div>
        ) : currentDisplay === "disabled" ? (
          <div className="totp_enabled_disabled_main">
            <div className="totp_disabled_div">
              <h4>TOTP Enabled</h4>
              <p>
                The TOTP has been enabled for this account. To generate new
                backup codes or re-register your devices, first disable TOTP and
                repeat the setup process.
              </p>
              <button
                className="totp_disabled_div_butt"
                onClick={() => {
                  setOpenDisabledPop(true);
                  dispatch(disabledEnabledError());
                  setIsAppDownload(false);
                  setActiveStep(0);
                  setIsQrScanned(false);
                  setIsFocus(false);
                  setTotp("");
                  setHashKey({
                    email: "",
                    key: "",
                  });
                }}
              >
                Disable TOTP
              </button>
            </div>
            <div className="totp_Enabling_img">
              <img src={Disabling} alt="Enabling" />
            </div>
          </div>
        ) : currentDisplay === "steps" ? (
          shouldRender && (
            <div
              className={`${
                activeStep === 0 && direction === "next"
                  ? ""
                  : removeClassName === true
                  ? ""
                  : animationClass
              }`}
            >
              {renderTotp()}
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TotpCreate;
