import { useEffect, useRef, useState } from "react";
import "./index.css";
import MenuItemCard from "../MenuItemCard";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import checkIfEnable from "../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../services/ServiceIDByLabel";
import { setCurrentMenu } from "../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { Box, Typography, Grid } from "@mui/material";

const NavbarMenuPopover = ({
  setOpenPopover,
  setArrowUp,
  data,
  open,
  type,
}) => {
  const dispatch = useDispatch();
  const services_list = useSelector((state) => state.refreshToken.services);
  const elementRef = useRef(null);
  const [showDisabledText, setShowDisabledText] = useState(false);

  useEffect(() => {
    if (open) {
      const timeout = setTimeout(() => setShowDisabledText(true), 300);
      return () => clearTimeout(timeout);
    } else {
      setShowDisabledText(false);
    }
  }, [open]);

  const handleTogglePopover = () => {
    setOpenPopover(false);
    setArrowUp(false);
  };

  let sectionTitles = data.map((section, index) => (
    <div
      key={index}
      className="section-title"
      style={{
        marginRight: section?.title === "Corporate accounts" ? "30px" : "0px",
      }}
    >
      {section.title}
    </div>
  ));
  if (type === "accounts") {
    sectionTitles.push(
      <div
        style={{
          visibility: "hidden",
          fontFamily: "monospace",
        }}
        className="section-title"
        key="42"
      >
        {"😊".padStart(15, "😊")}
      </div>
    );
  }

  return (
    <div ref={elementRef} className={`popover-main ${open ? "open" : "close"}`}>
      <div className="popover-content">
        {type === "transactions" && (
          <div className="w-[80%]">
            <div className="section-header flex flex-row justify-between mr-[6.2rem]">
              {sectionTitles}
            </div>

            <div className="flex flex-row justify-between">
              {data.map((section) =>
                section.menuItems.map((menuItem, idx) => (
                  <div key={idx} className="menu-item-container-transaction">
                    <Link
                      to={`/${menuItem.route}`}
                      className="menu-item-link"
                      style={{
                        pointerEvents: checkIfEnable(
                          ServiceIDByLabel,
                          menuItem.label,
                          services_list
                        )
                          ? "auto"
                          : "none",
                      }}
                      onClick={() => {
                        dispatch(setCurrentMenu(menuItem.menu));
                        handleTogglePopover();
                      }}
                    >
                      <MenuItemCard
                        icon={menuItem.icon}
                        label={menuItem.label}
                      />
                    </Link>
                  </div>
                ))
              )}
            </div>

            {/* ✅ Admin Transactions Section */}
            {data[data.length - 1]?.title === "Admin Transactions" && (
              <Box sx={{ padding: "2% 2% 2% 0px", width: "24rem" }}>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "left",
                    font: "normal normal 600 16px Open Sans, sans-serif",
                    letterSpacing: "0px",
                    color: "#042E77",
                    textTransform: "uppercase",
                    opacity: 1,
                  }}
                >
                  Admin Transactions
                </Typography>
                <Grid container columns={12} spacing={2}>
                  {data[data.length - 1].menuItems.map((transaction, index) => (
                    <Grid key={index} item xs={4}>
                      <Link
                        to={`/${transaction.route}`}
                        style={{
                          textDecoration: "none",
                          pointerEvents: checkIfEnable(
                            ServiceIDByLabel,
                            transaction.label,
                            services_list
                          )
                            ? "auto"
                            : "none",
                        }}
                        onClick={() => {
                          dispatch(setCurrentMenu(transaction.menu));
                          handleTogglePopover();
                        }}
                      >
                        <MenuItemCard
                          icon={transaction.icon}
                          label={transaction.label}
                        />
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </div>
        )}

        {type === "accounts" && (
          <div className="section-wrapper" style={{ marginRight: "30px" }}>
            <div className="section-header flex flex-row justify-between ml-[5px]">
              {sectionTitles}
            </div>
            <div className="cards-wrapper">
              {data.map((section) =>
                section.menuItems.map((menuItem, idx) => (
                  <div key={idx} className="menu-item-container flex">
                    <Link
                      to={`/${menuItem.route}`}
                      className="menu-item-link"
                      style={{
                        pointerEvents: checkIfEnable(
                          ServiceIDByLabel,
                          menuItem.label,
                          services_list
                        )
                          ? "auto"
                          : "none",
                      }}
                      onClick={() => {
                        dispatch(setCurrentMenu(menuItem.menu));
                        handleTogglePopover();
                      }}
                    >
                      <MenuItemCard
                        icon={menuItem.icon}
                        label={menuItem.label}
                      />
                    </Link>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
        <div className="popover-footer">
          <Typography
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
              fontWeight: "var(--Font_normal)",
              fontSize: "var(--Font_10)",
              fontFamily: "Open Sans",
              color: "var( --Secondary_grey)",
              letterSpacing: "0px",
            }}
          >
            *If disabled - the feature is not available for your ID.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NavbarMenuPopover;
